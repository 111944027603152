/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent, Suspense, useEffect, useState } from "react";
import {
  usePreloadedQuery,
  usePaginationFragment,
  PreloadedQuery,
  RelayEnvironmentProvider,
} from "react-relay";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { Id } from "../../../data/models/common";
import BusinessService, { GlobalBusiness } from "../Services/BusinessService";
import { useAppContext } from "../../IDM/external/Context/AppContext";
import {
  getRegionalStackEnvironment,
  idmInternalEnvironment,
} from "../../../environment";
import {
  BusinessTablePageQuery,
  BusinessTableFragment,
  BusinessListItem,
} from "./BusinessQueries";
import { BusinessQueries_InternalQuery } from "./__generated__/BusinessQueries_InternalQuery.graphql";
import { BusinessTableQuery } from "./__generated__/BusinessTableQuery.graphql";
import { BusinessQueries_ListFragment$key } from "./__generated__/BusinessQueries_ListFragment.graphql";
import LazyLoader from "../../common/LazyLoader";
import Loader from "../../common/Loader";
import BusinessRow from "./BusinessRow";

type Props = {
  queryReference: PreloadedQuery<BusinessQueries_InternalQuery>;
};

const BusinessTableBase: FunctionComponent<Props> = ({ queryReference }) => {
  const { stack } = useAppContext();
  const { t } = useTranslation("businesses");
  const [globalBusinesses, setGlobalBusinesses] = useState<
    Map<Id, GlobalBusiness>
  >(new Map<Id, GlobalBusiness>());

  const query = usePreloadedQuery(BusinessTablePageQuery, queryReference);

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    BusinessTableQuery,
    BusinessQueries_ListFragment$key
  >(BusinessTableFragment, query);

  useEffect(() => {
    async function load() {
      const currentStackEnvironment = stack
        ? getRegionalStackEnvironment(stack.domainName)
        : null;

      if (stack) {
        const maps = await BusinessService.getGlobalBusinesses(
          currentStackEnvironment,
        );

        setGlobalBusinesses(maps);
      }
    }

    load();
  }, [stack]);

  // data mapping
  const businesses: BusinessListItem[] = (data.businesses.edges || [])
    .reduce((acc: BusinessListItem[], edge) => {
      if (edge?.node) {
        acc.push(edge.node);
      }
      return acc;
    }, [])
    .sort((businessA, businessB) =>
      businessA.businessName < businessB.businessName ? -1 : 1,
    );

  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("table.headers.name")}</th>
            <th>{t("table.headers.id")}</th>
            <th>{t("table.headers.corporate")}</th>
            <th>{t("table.headers.globalBusiness")}</th>
            <th>{t("table.headers.description")}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {businesses.map((item, index) => {
            return (
              <RelayEnvironmentProvider
                key={item.id}
                environment={idmInternalEnvironment}
              >
                <Suspense
                  fallback={
                    <tr>
                      <td colSpan={6}>
                        <Loader />
                      </td>
                    </tr>
                  }
                >
                  <BusinessRow
                    globalBusinesses={globalBusinesses}
                    business={item}
                  />
                </Suspense>
              </RelayEnvironmentProvider>
            );
          })}
        </tbody>
      </Table>
      <LazyLoader
        isLoading={() => isLoadingNext}
        hasMore={() => hasNext}
        loadMore={loadNext}
      />
    </>
  );
};

export default BusinessTableBase;
