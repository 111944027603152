import { graphql, useLazyLoadQuery, useMutation } from "react-relay";
import {
  ScheduleBreakTypeUpdateInput,
  ScheduleBreakType,
} from "../../../data/generated/stack_internal_schema";
import { BreakTypeQueries_GetSingleScheduleBreakType_Query } from "./__generated__/BreakTypeQueries_GetSingleScheduleBreakType_Query.graphql";
import { BreakTypeQueries_GetSingleBusinessBreakType_Query } from "./__generated__/BreakTypeQueries_GetSingleBusinessBreakType_Query.graphql";
import { FormUpdateMethod } from "./BreakTypeUtility";

export const GetBusinessBreakTypesQuery = graphql`
  query BreakTypeQueries_GetBusinessBreakTypes_Query(
    $businessId: ID!
    $search: String
    $includeDeleted: Boolean
  ) {
    breakTypes(
      businessId: $businessId
      search: $search
      includeDeleted: $includeDeleted
    ) {
      nodes {
        availableRange {
          rangeStartTime
          rangeEndTime
        }
        breakScreenOrdering
        breakTypeCode
        canOverrideClockedPaidBreak
        code
        createdAt
        defaultDuration
        deleted
        description
        employmentMetadata
        employmentTypes
        employmentTypeCodes
        id
        legacyBreakType
        name
        paidThreshold
        schedulable
        updatedAt
        internalAccess
      }
    }
  }
`;

export const GetScheduleBreakTypesQuery = graphql`
  query BreakTypeQueries_GetScheduleBreakTypes_Query(
    $businessId: ID!
    $scheduleId: ID!
    $search: String
  ) {
    scheduleBreakTypes(
      businessId: $businessId
      scheduleId: $scheduleId
      search: $search
    ) {
      nodes {
        availableRange {
          rangeStartTime
          rangeEndTime
        }
        breakScreenOrdering
        breakTypeCode
        breakTypeId
        canOverrideClockedPaidBreak
        employmentTypeCodes
        code
        createdAt
        defaultDuration
        deleted
        description
        employmentMetadata
        employmentTypes
        hidden
        id
        legacyBreakType
        name
        paidThreshold
        schedulable
        scheduleId
        scheduleOverride
        updatedAt
        internalAccess
      }
    }
  }
`;

export const GetSingleBusinessBreakTypeQuery = graphql`
  query BreakTypeQueries_GetSingleBusinessBreakType_Query(
    $businessId: ID!
    $id: ID!
    $skip: Boolean!
  ) {
    breakTypes(businessId: $businessId, id: $id, includeDeleted: true)
      @skip(if: $skip) {
      nodes {
        id
        deleted
        ### Replaceable content start
        name
        breakTypeCode
        legacyBreakType
        breakScreenOrdering
        description
        code
        internalAccess
        defaultDuration
        availableRange {
          rangeStartTime
          rangeEndTime
        }
        schedulable
        paidThreshold
        unpaidAfter
        paidAfter
        canOverrideClockedPaidBreak
        employmentTypeCodes
        employmentMetadata
        ### Replaceable content finish
      }
    }
  }
`;

export const GetSingleScheduleBreakTypeQuery = graphql`
  query BreakTypeQueries_GetSingleScheduleBreakType_Query(
    $businessId: ID!
    $scheduleId: ID!
    $id: ID!
    $skip: Boolean!
  ) {
    scheduleBreakTypes(
      businessId: $businessId
      scheduleId: $scheduleId
      id: $id
    ) @skip(if: $skip) {
      nodes {
        hidden
        id
        deleted
        schedulePaidThreshold
        scheduleCanOverrideClockedPaidBreak
        scheduleUnpaidAfter
        schedulePaidAfter
        ### Replaceable content start
        name
        breakTypeCode
        legacyBreakType
        breakScreenOrdering
        description
        code
        internalAccess
        defaultDuration
        availableRange {
          rangeStartTime
          rangeEndTime
        }
        schedulable
        paidThreshold
        unpaidAfter
        paidAfter
        canOverrideClockedPaidBreak
        employmentTypeCodes
        employmentMetadata
        ### Replaceable content finish
      }
    }
  }
`;

export const UpdateBusinessBreakTypeMutation = graphql`
  mutation BreakTypeQueries_UpdateBreakType_Mutation(
    $businessId: ID!
    $input: BreakTypeUpdateInput!
    $id: ID!
  ) {
    updateBreakType(businessId: $businessId, id: $id, input: $input) {
      ### Replaceable content start
      name
      breakTypeCode
      legacyBreakType
      breakScreenOrdering
      description
      code
      internalAccess
      defaultDuration
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      schedulable
      paidThreshold
      unpaidAfter
      paidAfter
      canOverrideClockedPaidBreak
      employmentTypeCodes
      employmentMetadata
      ### Replaceable content finish
    }
  }
`;

export const CreateBusinessBreakTypeMutation = graphql`
  mutation BreakTypeQueries_CreateBreakType_Mutation(
    $businessId: ID!
    $input: BreakTypeCreateInput!
  ) {
    createBreakType(businessId: $businessId, input: $input) {
      ### Replaceable content start
      name
      breakTypeCode
      legacyBreakType
      breakScreenOrdering
      description
      code
      internalAccess
      defaultDuration
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      schedulable
      paidThreshold
      unpaidAfter
      paidAfter
      canOverrideClockedPaidBreak
      employmentTypeCodes
      employmentMetadata
      ### Replaceable content finish
    }
  }
`;

export const CreateScheduleBreakTypeMutation = graphql`
  mutation BreakTypeQueries_CreateScheduleBreakType_Mutation(
    $businessId: ID!
    $input: ScheduleBreakTypeCreateInput!
  ) {
    createScheduleBreakType(businessId: $businessId, input: $input) {
      ### Replaceable content start
      name
      breakTypeCode
      legacyBreakType
      breakScreenOrdering
      description
      code
      internalAccess
      defaultDuration
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      schedulable
      paidThreshold
      unpaidAfter
      paidAfter
      canOverrideClockedPaidBreak
      employmentTypeCodes
      employmentMetadata
      ### Replaceable content finish
    }
  }
`;

export const UpdateScheduleBreakTypeMutation = graphql`
  mutation BreakTypeQueries_UpdateScheduleBreakType_Mutation(
    $businessId: ID!
    $input: ScheduleBreakTypeUpdateInput!
    $id: ID!
  ) {
    updateScheduleBreakType(businessId: $businessId, id: $id, input: $input) {
      ### Replaceable content start
      name
      breakTypeCode
      legacyBreakType
      breakScreenOrdering
      description
      code
      internalAccess
      defaultDuration
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      schedulable
      paidThreshold
      unpaidAfter
      paidAfter
      canOverrideClockedPaidBreak
      employmentTypeCodes
      employmentMetadata
      ### Replaceable content finish
    }
  }
`;

export const DeactivateBreakTypeMutation = graphql`
  mutation BreakTypeQueries_DeactivateBreakType_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    deactivateBreakType(businessId: $businessId, id: $id)
  }
`;

export const ReactivateBreakTypeMutation = graphql`
  mutation BreakTypeQueries_ReactivateBreakType_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    reactivateBreakType(businessId: $businessId, id: $id) {
      id
    }
  }
`;

export const RemoveScheduleBreakTypeOverrideMutation = graphql`
  mutation BreakTypeQueries_RemoveScheduleBreakTypeOverride_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    deleteScheduleBreakType(businessId: $businessId, id: $id)
  }
`;

/**
 * Fetches the business break type and schedule break type by id
 */
export function useBusinessAndLinkedScheduleBreakType(variables: {
  businessId: string;
  scheduleId?: string;
  scheduleBreakTypeId?: string | null;
  businessBreakTypeId?: string | null;
}) {
  const scheduleBreakType = useScheduleBreakType(variables);
  const businessBreakType = useBreakType(variables);

  const data = (scheduleBreakType ??
    businessBreakType) as unknown as ScheduleBreakType;

  return [
    data,
    {
      businessBreakType,
      scheduleBreakType: scheduleBreakType ?? null,
    },
  ] as const;
}

export function useBreakType({
  businessId,
  businessBreakTypeId,
}: {
  businessId: string;
  businessBreakTypeId?: string | null;
}) {
  const { breakTypes } =
    useLazyLoadQuery<BreakTypeQueries_GetSingleBusinessBreakType_Query>(
      GetSingleBusinessBreakTypeQuery,
      {
        businessId,
        id: businessBreakTypeId ?? "",
        // skip if we're creating a new break type
        skip: businessBreakTypeId == null,
      },
      {
        fetchPolicy: "network-only",
      },
    );

  return breakTypes?.nodes?.[0];
}

export function useScheduleBreakType(variables: {
  scheduleBreakTypeId?: string | null;
  businessId: string;
  scheduleId?: string;
}) {
  const { scheduleId, businessId, scheduleBreakTypeId } = variables;
  const { scheduleBreakTypes } =
    useLazyLoadQuery<BreakTypeQueries_GetSingleScheduleBreakType_Query>(
      GetSingleScheduleBreakTypeQuery,
      {
        businessId,
        id: scheduleBreakTypeId ?? "",
        scheduleId: scheduleId ?? "",
        // skip if we're creating a new break type
        skip: scheduleId == null || scheduleBreakTypeId == null,
      },
      {
        fetchPolicy: "network-only",
      },
    );

  return scheduleBreakTypes?.nodes?.[0];
}

export function useBreakTypeMutations(formUpdateMethod: FormUpdateMethod) {
  const [createBreakTypeMutation] = useMutation(
    CreateBusinessBreakTypeMutation,
  );
  const [updateBreakTypeMutation] = useMutation(
    UpdateBusinessBreakTypeMutation,
  );
  const [createScheduleBreakTypeOverride] = useMutation(
    CreateScheduleBreakTypeMutation,
  );
  const [updateScheduleBreakType] = useMutation(
    UpdateScheduleBreakTypeMutation,
  );

  const [deactivateBreakType] = useMutation(DeactivateBreakTypeMutation);
  const [activateBreakType] = useMutation(ReactivateBreakTypeMutation);
  const [deleteScheduleBreakType] = useMutation(
    RemoveScheduleBreakTypeOverrideMutation,
  );

  const activationMutations = {
    deactivateBreakType,
    activateBreakType,
    deleteScheduleBreakType,
  };

  switch (formUpdateMethod) {
    case FormUpdateMethod.OverridingBreakType:
      return [createScheduleBreakTypeOverride, activationMutations] as const;
    case FormUpdateMethod.UpdateScheduleBreakType:
      return [updateScheduleBreakType, activationMutations] as const;
    case FormUpdateMethod.CreateBreakType:
      return [createBreakTypeMutation, activationMutations] as const;
    case FormUpdateMethod.UpdateBreakType:
    default:
      return [updateBreakTypeMutation, activationMutations] as const;
  }
}

/**
 * Hook for the break type form data at business/schedule level. Will fetch both the business and schedule break type
 * and transform it into a single data model used for the CRUD form
 */
export function useBreakTypeFormData(
  businessId: string,
  scheduleId: string | undefined,
  businessBreakTypeId: string | undefined | null,
  scheduleBreakTypeId: string | undefined | null,
  isScheduleContext: boolean,
) {
  // Both business/schedule break types will need to be fetched at the schedule context
  // If a business break type has not been overridden, then no scheduleBreakTypes for that id
  // will be returned

  const isCreate = businessBreakTypeId == null;
  const [data, { scheduleBreakType, businessBreakType }] =
    useBusinessAndLinkedScheduleBreakType({
      businessId,
      businessBreakTypeId,
      scheduleBreakTypeId,
      scheduleId,
    });

  // Depending on the state of the break type, certain mutations need to be used, so track these variations using an enum
  const breakTypeUpdateMethod = (() => {
    if (isScheduleContext) {
      if (!scheduleBreakType) {
        // Create a a schedule level break type for the the first time
        return FormUpdateMethod.OverridingBreakType;
      }

      // Update an already existing schedule level break type
      return FormUpdateMethod.UpdateScheduleBreakType;
    }

    // Create a business level break type
    if (isCreate) {
      return FormUpdateMethod.CreateBreakType;
    }

    // Update a business level break type
    return FormUpdateMethod.UpdateBreakType;
  })();

  const initialBreakTypeData = (
    data
      ? { ...data }
      : {
          schedulable: true,
          internalAccess: true,
          canOverrideClockedPaidBreak: false,
          ...(isScheduleContext && { hidden: false }),
        }
  ) as ScheduleBreakType;

  // When in the schedule context, schedulePaidThreshold will be null if it hasn't been overridden/changed for the
  // schedule break type
  if (isScheduleContext) {
    initialBreakTypeData.paidThreshold =
      scheduleBreakType?.schedulePaidThreshold ?? null;

    (
      initialBreakTypeData as ScheduleBreakTypeUpdateInput
    ).canOverrideClockedPaidBreak =
      scheduleBreakType?.scheduleCanOverrideClockedPaidBreak ?? null;

    initialBreakTypeData.paidAfter =
      scheduleBreakType?.schedulePaidAfter ?? null;
    initialBreakTypeData.unpaidAfter =
      scheduleBreakType?.scheduleUnpaidAfter ?? null;
  }

  return [
    initialBreakTypeData,
    breakTypeUpdateMethod,
    { scheduleBreakType, businessBreakType },
  ] as const;
}
