/**
 * @generated SignedSource<<0f0dc68acc2e92b4f5900ea209012ce2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MetadataTypeDataTypeEnum = "boolean" | "date" | "number" | "object" | "string" | "timestamp" | "%future added value";
export type MetadataTypeObjectClassEnum = "Employment" | "%future added value";
export type MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery$variables = {
  businessId: string;
  id: string;
  skip: boolean;
};
export type MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery$data = {
  readonly metadataTypes?: {
    readonly nodes: ReadonlyArray<{
      readonly dataType: MetadataTypeDataTypeEnum;
      readonly description: string | null;
      readonly displayName: string | null;
      readonly encrypted: boolean;
      readonly external: boolean;
      readonly id: string;
      readonly internalAccess: boolean;
      readonly isTimeboxed: boolean;
      readonly maximum: number | null;
      readonly minimum: number | null;
      readonly name: string;
      readonly obfuscatedNumber: number | null;
      readonly objectClass: MetadataTypeObjectClassEnum;
      readonly required: boolean;
      readonly validValues: any | null;
    }>;
  };
};
export type MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery = {
  response: MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery$data;
  variables: MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "businessId",
            "variableName": "businessId"
          },
          {
            "items": [
              {
                "kind": "Variable",
                "name": "ids.0",
                "variableName": "id"
              }
            ],
            "kind": "ListValue",
            "name": "ids"
          }
        ],
        "concreteType": "MetadataTypeConnection",
        "kind": "LinkedField",
        "name": "metadataTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MetadataType",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectClass",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "required",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minimum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maximum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validValues",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTimeboxed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "external",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalAccess",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "encrypted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "obfuscatedNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b418d769788cdc38c42a7bafbca91d96",
    "id": null,
    "metadata": {},
    "name": "MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery",
    "operationKind": "query",
    "text": "query MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery(\n  $businessId: ID!\n  $id: ID!\n  $skip: Boolean!\n) {\n  metadataTypes(businessId: $businessId, ids: [$id]) @skip(if: $skip) {\n    nodes {\n      id\n      name\n      displayName\n      objectClass\n      required\n      description\n      dataType\n      minimum\n      maximum\n      validValues\n      isTimeboxed\n      external\n      internalAccess\n      encrypted\n      obfuscatedNumber\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "22cc945d0d29fc0b5e3fe38d36fa5119";

export default node;
