import React, { useCallback, useState } from "react";
import { graphql, QueryRenderer } from "react-relay";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import Searchable from "../../common/Searchable";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import AOSTemplateTable from "./AOSTemplateTable";
import Loader from "../../common/Loader";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import { useAppRouter } from "../../../hooks/useAppRouter";

const AOSTemplatesQuery = graphql`
  query AOSTemplates_InternalQuery(
    $businessId: ID!
    $search: String
    $pageSize: Int!
    $after: String
  ) {
    ...AOSTemplateTable_viewer
      @arguments(
        businessId: $businessId
        search: $search
        pageSize: $pageSize
        after: $after
      )
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

function AOSTemplates() {
  const { t } = useTranslation("aos");
  const {
    params: { business_id: businessId, stack_id: stackId },
  } = useAppRouter<MatchParams>();
  const [searchValue, setSearchValue] = useState("");
  const { environment } = useBusinessContext();

  const onSearchChange = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("aosTemplate.aosTemplates")}</span>
      </HeaderPortal>
      <Row className="mt-2 mb-2">
        <Col>
          <Searchable
            className="aos-templates"
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            placeholder={t("aosTemplate.search")}
          />
        </Col>
        <Col md="auto">
          <LinkContainer
            to={`/stack/${stackId}/business/${businessId}/aos_template/create`}
          >
            <Button>{t("aosTemplate.newTemplate")}</Button>
          </LinkContainer>
        </Col>
      </Row>

      <Card body>
        <QueryRenderer
          environment={environment}
          query={AOSTemplatesQuery}
          variables={{
            businessId,
            search: searchValue || "",
            pageSize: 50,
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>Error!</div>;
            }
            if (!props) {
              return <Loader />;
            }
            return (
              <AOSTemplateTable
                stackId={stackId}
                businessId={businessId}
                searchValue={searchValue}
                viewer={props as any}
              />
            );
          }}
        />
      </Card>
    </>
  );
}

export default AOSTemplates;
