import React, { Component } from "react";
import JSONEditor, { JSONEditorMode } from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import styled from "styled-components";
import { debounce } from "lodash";

const modes = {
  tree: "tree",
  view: "view",
  form: "form",
  code: "code",
  text: "text",
};

const StyledDiv = styled("div")`
  height: 360px;
  > * {
    z-index: 0;
  }
`;

// Keep this as class component
export default class JsonEditor extends Component<Props> {
  static defaultProps = {
    mode: modes.tree,
    history: false,
    search: true,
    navigationBar: true,
    statusBar: true,
  };

  static modes = modes;

  private htmlElementRef: HTMLDivElement | null;

  private jsonEditor: JSONEditor | null;

  private err?: string;

  private debouncedOnChange = debounce((json) => {
    // Debounce so that the browser performance is reasonable and the auto formatting is less obtrusive
    this.props?.onChange?.(json);
  }, 3000);

  constructor(props: Props) {
    super(props);

    this.htmlElementRef = null;
    this.jsonEditor = null;

    this.setRef = this.setRef.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { allowedModes, ...rest } = this.props;
    this.createEditor({
      ...rest,
      modes: allowedModes,
    });
  }

  shouldComponentUpdate(nextProps: Props) {
    const { htmlElementProps, mode, disabled, value } = nextProps;
    return (
      htmlElementProps !== this.props.htmlElementProps ||
      mode !== this.props.mode ||
      disabled !== this.props.disabled ||
      value !== this.props.value
    );
  }

  componentDidUpdate(previousProps: Props) {
    const { name, value, disabled } = this.props;
    let { mode } = this.props;

    if (name !== this.jsonEditor?.getName()) {
      if (this.jsonEditor?.setName) {
        this.jsonEditor.setName(name);
      }
    }

    // value changed
    if (value !== previousProps.value) {
      const cursorPosition = this.jsonEditor?.getTextSelection();
      if (typeof value === "object") {
        this.jsonEditor?.update?.(value);
      } else {
        this.jsonEditor?.updateText?.(value as string);
      }

      // By doing this, the JSON editor can still nicely auto format the JSON structure, but keeps your cursor in the same
      // position, which makes the experience a little less jarring
      if (cursorPosition) {
        this.jsonEditor?.setTextSelection(
          cursorPosition.start,
          cursorPosition.end,
        );
      }
    }

    if (disabled) {
      mode = modes.view;
    }

    if (mode !== this.jsonEditor?.getMode()) {
      if (this.jsonEditor?.setMode) {
        this.jsonEditor.setMode(mode as JSONEditorMode);
      }
    }
  }

  componentWillUnmount() {
    if (this.jsonEditor) {
      this.jsonEditor.destroy();
      this.jsonEditor = null;
    }
  }

  private handleChange() {
    const { onChange, disabled } = this.props;
    if (disabled) {
      return;
    }

    if (onChange) {
      try {
        const text = this.jsonEditor?.getText();
        if (text === "") {
          this.debouncedOnChange(null);
        }

        const currentJson = this.jsonEditor?.get();
        if (this.props.value !== currentJson) {
          this.debouncedOnChange(currentJson);
        }
      } catch (err: unknown) {
        const text = this.jsonEditor?.getText();
        if (text) {
          this.debouncedOnChange(text);
        }
        this.err = err as string;
      }
    }
  }

  private setRef(element: HTMLDivElement) {
    this.htmlElementRef = element;
    if (this.props.innerRef) {
      this.props.innerRef(element);
    }
  }

  private createEditor(options: any) {
    const { value, disabled, ...rest } = options;
    if (this.jsonEditor) {
      this.jsonEditor.destroy();
    }

    let mode = {};
    if (disabled) {
      mode = { mode: modes.code };
    }

    if (this.htmlElementRef) {
      this.jsonEditor = new JSONEditor(this.htmlElementRef, {
        ...rest,
        ...mode,
        // overwrite onChange
        onChange: this.handleChange,
        onEditable: () => !disabled,
      });

      this.jsonEditor.set(value || null);
    }
  }

  collapseAll() {
    if (this.jsonEditor) {
      this.jsonEditor.collapseAll();
    }
  }

  expandAll() {
    if (this.jsonEditor) {
      this.jsonEditor.expandAll();
    }
  }

  focus() {
    if (this.jsonEditor) {
      this.jsonEditor.focus();
    }
  }

  set(value: any) {
    if (this.jsonEditor) {
      this.jsonEditor.set(value);
    }
  }

  render() {
    const { htmlElementProps } = this.props;

    return React.createElement(StyledDiv, {
      ...htmlElementProps,
      ref: this.setRef,
    });
  }
}

type Props = typeof JsonEditor.defaultProps & {
  value: {} | [];
  mode?: string;
  name: string;
  schema?: Record<string, any>;
  schemaRefs?: Record<string, any>;

  onChange?: (json: {} | [] | null) => void;
  onError?: Function;
  onModeChange?: Function;
  onEditable?: Function;

  theme?: string;
  history?: boolean;
  navigationBar?: boolean;
  statusBar?: boolean;
  search?: boolean;
  allowedModes?: string[];
  disabled?: boolean;

  //  custom props
  htmlElementProps?: Record<string, any>;
  innerRef?: Function;
};
