import React, { useState } from "react";
import { Environment, useMutation, graphql } from "react-relay";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Trans, useTranslation } from "react-i18next";
import { Profile_skillLevel$data } from "../__generated__/Profile_skillLevel.graphql";
import { SetAsDefaultModal_UpdateSkillLevelMutation } from "./__generated__/SetAsDefaultModal_UpdateSkillLevelMutation.graphql";
import ServerError from "../../../../utils/server-error";

type SkillLevel = Omit<
  Profile_skillLevel$data,
  " $refType" | "$fragmentType" | " $fragmentType"
>;

type Props = {
  businessId: string;
  id: string;
  skillLevel: SkillLevel;
  onClose: Function;
  onSuccess: Function;
  environment: Environment;
};

function SetAsDefaultModal({
  businessId,
  id,
  skillLevel,
  onClose,
  onSuccess,
  environment,
}: Props) {
  const { t } = useTranslation("skill-levels");
  const [error, setError] = useState<string>();
  const [setAsDefaultCommit] =
    useMutation<SetAsDefaultModal_UpdateSkillLevelMutation>(graphql`
      mutation SetAsDefaultModal_UpdateSkillLevelMutation(
        $businessId: ID!
        $id: ID!
        $input: SkillLevelUpdateInput!
      ) {
        updateSkillLevel(businessId: $businessId, id: $id, input: $input) {
          id
          ...Profile_skillLevel
        }
      }
    `);

  if (!id) {
    return null;
  }

  const cancelModal = () => {
    setError(undefined);
    onClose();
  };

  const onOkButtonClicked = () => {
    if (!environment) {
      return;
    }

    setAsDefaultCommit({
      variables: {
        businessId,
        id,
        input: {
          isDefault: !skillLevel.isDefault,
        },
      },
      onCompleted: () => {
        cancelModal();
        onSuccess();
      },
      onError: (e: Error) => {
        const { source } = (e as any) || {};
        if (!source) {
          return;
        }
        const serverError = new ServerError(source);
        setError(serverError.getErrorDetails());
      },
    });
  };

  const renderError = () => {
    return error ? (
      <Form.Control.Feedback type="invalid" className="d-block">
        {error}
      </Form.Control.Feedback>
    ) : null;
  };

  return (
    <Modal show onHide={cancelModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("setAsDefaultConfirmation.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Trans
            t={t}
            i18nKey="setAsDefaultConfirmation.body"
            values={{
              name: skillLevel.name,
              level: skillLevel.level,
            }}
            components={{ bold: <strong /> }}
          />
          {renderError()}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelModal}>
          {t("setAsDefaultConfirmation.cancel")}
        </Button>
        <Button variant="primary" onClick={onOkButtonClicked}>
          {t("setAsDefaultConfirmation.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SetAsDefaultModal;
