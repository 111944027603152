import React, { Suspense } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { Card, Button } from "react-bootstrap";
import Loader from "../../../../common/Loader";
import FeedbackReasonsTable from "../FeedbackReasonsTable";
import Checkbox from "../../../../common/Form/Checkbox";
import { EmploymentFeedbackTypeEnum } from "../../../../../data/generated/stack_internal_schema";
import {
  FeedbackReasonsModalAction,
  useFeedbackReasonsCreateEditModal,
} from "../FeedbackReasonsModal";
import {
  EmploymentFeedbackReasonsQuery,
  useFeedbackDataQuery,
  useFeedbackReasonsData,
} from "../FeedbackReasonsQueries";

type Props = {
  queryReference: EmploymentFeedbackReasonsQuery;
  filters: any;
};

const defaultFilters = {
  searchValue: "",
  showDeactivated: false,
};

export function useDisciplinaryActionQuery() {
  return useFeedbackDataQuery(
    EmploymentFeedbackTypeEnum.DisciplinaryAction,
    defaultFilters,
  );
}

export default function DisciplinaryAction({ queryReference, filters }: Props) {
  const { t } = useTranslation("feedback-reasons");

  const { showDeactivated, refreshQuery } = filters;

  const feedbackReasons = useFeedbackReasonsData(queryReference);

  const onCreate = useFeedbackReasonsCreateEditModal(
    FeedbackReasonsModalAction.Create,
    EmploymentFeedbackTypeEnum.DisciplinaryAction,
    refreshQuery,
    t,
  );

  return (
    <Card className="mt-4" body>
      <Suspense fallback={<Loader />}>
        <Row className="align-baseline">
          {/* <Col>
              <Searchable
                className="disciplinary-actions"
                searchValue={search}
                onSearchChange={setSearchValue}
                placeholder={t("searchPlaceholder")}
              />
            </Col> */}
          <Col md={4}>
            <Row>
              <Checkbox
                fieldKey="deactivated-checkbox"
                className="ml-3 align-self-center"
                value={showDeactivated.value}
                label={t("showDeactivated")}
                onChange={showDeactivated.setValue}
              />
            </Row>
          </Col>
          <Col />
          <Col md="auto">
            <Button variant="primary btn-block" onClick={onCreate()}>
              {t("newReasonButton")}
            </Button>
          </Col>
        </Row>
        {feedbackReasons !== null && (
          <FeedbackReasonsTable
            feedbackType={EmploymentFeedbackTypeEnum.DisciplinaryAction}
            feedbackReasons={feedbackReasons}
            refreshQuery={refreshQuery}
          />
        )}
      </Suspense>
    </Card>
  );
}
