import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { graphql, QueryRenderer } from "react-relay";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import { EmploymentSchedules_InternalQuery } from "./__generated__/EmploymentSchedules_InternalQuery.graphql";
import { Id } from "../../../data/models/common";
import { toRelative } from "../../../utils/utility";
import {
  ScheduleService,
  ScheduleByIdsType,
} from "../Services/ScheduleService";
import Loader from "../../common/Loader";
import { useAppRouter } from "../../../hooks/useAppRouter";

const StyledSpan = styled.span`
  color: ${(props) => props.theme.bluePrimary};
  &.inherited {
    color: ${(props) => props.theme.grey100};
  }
`;

const EmploymentsQuery = graphql`
  query EmploymentSchedules_InternalQuery(
    $businessId: ID!
    $employmentId: ID!
  ) {
    employments(businessId: $businessId, ids: [$employmentId]) {
      edges {
        node {
          id
          employmentSchedules {
            scheduleId
            defined
            inherited
            groupAdmin
            inheritedGroupAdmin
            scheduleManager
            inheritedScheduleManager
            scheduleManagerWithPay
            inheritedScheduleManagerWithPay
            shiftManager
            inheritedShiftManager
            schedulable
            inheritedSchedulable
            updatedAt
          }
        }
      }
    }
  }
`;

interface MatchParams {
  business_id: Id;
  stack_id: Id;
  employment_id: Id;
}

export default function EmploymentSchedules() {
  const { t } = useTranslation("employment");
  const businessContext = useBusinessContext();
  const {
    params: {
      business_id: businessId,
      stack_id: stackId,
      employment_id: employmentId,
    },
  } = useAppRouter<MatchParams>();

  const [schedules, setSchedules] = useState<Map<Id, ScheduleByIdsType>>(
    new Map<Id, ScheduleByIdsType>(),
  );

  const fetchSchedules = useCallback(async () => {
    if (businessContext.business) {
      const result = await ScheduleService.getSchedules(
        businessContext.environment,
        businessContext.business.id,
      );

      setSchedules(result);
    }
  }, [setSchedules, businessContext]);

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  return (
    <Card body>
      <QueryRenderer<EmploymentSchedules_InternalQuery>
        environment={businessContext.environment}
        query={EmploymentsQuery}
        variables={{
          businessId,
          employmentId,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>Error!</div>;
          }
          if (!props || !schedules.size) {
            return <Loader />;
          }

          const node = props.employments?.edges?.[0]?.node ?? null;
          if (!node || !node.employmentSchedules) {
            return null;
          }

          const employmentSchedules = node.employmentSchedules.map(
            (employmentSchedule) => {
              const schedule = schedules.get(employmentSchedule.scheduleId);
              return (
                <tr key={node.id}>
                  <td>
                    <Link
                      to={`/stack/${stackId}/business/${businessId}/schedule/${employmentSchedule.scheduleId}`}
                    >
                      {schedule?.scheduleName ?? employmentSchedule.scheduleId}
                    </Link>
                  </td>
                  <td>
                    <PermissionIcon
                      base={employmentSchedule.groupAdmin}
                      inherited={employmentSchedule.inheritedGroupAdmin}
                    />
                  </td>
                  <td>
                    <PermissionIcon
                      base={employmentSchedule.scheduleManager}
                      inherited={employmentSchedule.inheritedScheduleManager}
                    />
                  </td>
                  <td>
                    <PermissionIcon
                      base={employmentSchedule.scheduleManagerWithPay}
                      inherited={
                        employmentSchedule.inheritedScheduleManagerWithPay
                      }
                    />
                  </td>
                  <td>
                    <PermissionIcon
                      base={employmentSchedule.shiftManager}
                      inherited={employmentSchedule.inheritedShiftManager}
                    />
                  </td>
                  <td>
                    <PermissionIcon
                      base={employmentSchedule.schedulable}
                      inherited={employmentSchedule.inheritedSchedulable}
                    />
                  </td>
                  <td>
                    {toRelative(employmentSchedule.updatedAt as string, {
                      defaultValue: "-",
                    })}
                  </td>
                </tr>
              );
            },
          );

          return (
            <Table hover size="sm">
              <thead>
                <tr>
                  <th>{t("employmentSchedulesTable.headers.name")}</th>
                  <th>{t("employmentSchedulesTable.headers.groupAdmin")}</th>
                  <th>
                    {t("employmentSchedulesTable.headers.scheduleManager")}
                  </th>
                  <th>
                    {t(
                      "employmentSchedulesTable.headers.scheduleManagerWithPay",
                    )}
                  </th>
                  <th>{t("employmentSchedulesTable.headers.shiftManager")}</th>
                  <th>{t("employmentSchedulesTable.headers.schedulable")}</th>
                  <th>{t("employmentSchedulesTable.headers.lastUpdate")}</th>
                </tr>
              </thead>
              <tbody>{employmentSchedules}</tbody>
            </Table>
          );
        }}
      />
    </Card>
  );
}

type PermissionIconProps = {
  base: boolean;
  inherited: boolean;
};

const PermissionIcon: FunctionComponent<PermissionIconProps> = (
  p: PermissionIconProps,
) => {
  const { base, inherited } = p;

  if (!base && !inherited) {
    return null;
  }

  return (
    <StyledSpan className={inherited ? "inherited" : ""}>
      <FontAwesomeIcon icon={faCheck} />
    </StyledSpan>
  );
};
