import { fetchQuery, graphql } from "react-relay";
import { Id, IStack } from "../../../../data/models/common";
import { idmInternalEnvironment } from "../../../../environment";
import { StackService_StackById_Query } from "./__generated__/StackService_StackById_Query.graphql";
import { StackService_Stacks_Query } from "./__generated__/StackService_Stacks_Query.graphql";

const allStacks = graphql`
  query StackService_Stacks_Query {
    stacks {
      edges {
        node {
          id
          domainName
          deleted
          stackCode
        }
      }
    }
  }
`;

const StackByIdQuery = graphql`
  query StackService_StackById_Query($stackId: ID!) {
    stacks(ids: [$stackId]) {
      edges {
        node {
          id
          domainName
          stackCode
        }
      }
    }
  }
`;

export default class StackService {
  /**
   * Fetch stacks with the specified ids
   * @param ids an array of user ids
   * @returns a map of stacks keyed by user id
   */
  public static async getAllStacks(): Promise<Map<Id, IStack> | undefined> {
    const result = await fetchQuery<StackService_Stacks_Query>(
      idmInternalEnvironment,
      allStacks,
      {},
    ).toPromise();

    if (!result || !result.stacks || !result.stacks.edges) {
      return undefined;
    }

    const stacksById = new Map();
    result.stacks.edges.forEach((i) => {
      if (i && i.node) {
        stacksById.set(i.node.id, i.node);
      }
    });

    return stacksById;
  }

  /**
   * Fetch stacks with the specified ids
   * @param ids an array of user ids
   * @returns a map of stacks keyed by user id
   */
  public static async getStackById(stackId: Id) {
    const result = await fetchQuery<StackService_StackById_Query>(
      idmInternalEnvironment,
      StackByIdQuery,
      {
        stackId,
      },
    ).toPromise();

    return result?.stacks?.edges?.[0]?.node ?? null;
  }
}
