import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { graphql, QueryRenderer, Environment } from "react-relay";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import {
  BusinessContextProvider,
  BusinessInContext,
} from "../../contexts/BusinessContext";
import BusinessLayout from "./Business/BusinessLayout";
import ScheduleLayout from "./Schedule/ScheduleLayout";
import AOSTemplateLayout from "./AOSTemplates/AOSTemplateLayout";
// import ScheduleTree from "./Schedule/ScheduleTree";
import EmploymentLayout from "./Employment/EmploymentLayout";
import SkillLevelLayout from "./SkillLevels/SkillLevelLayout";
import { useAppContext } from "../IDM/external/Context/AppContext";

import { StackContextLayout_Query } from "./__generated__/StackContextLayout_Query.graphql";
import { getRegionalStackEnvironment } from "../../environment";
import Loader from "../common/Loader";
import { useAppRouter } from "../../hooks/useAppRouter";

const query = graphql`
  query StackContextLayout_Query($businessId: ID!) {
    businesses(ids: [$businessId]) {
      nodes {
        id
        businessName
        aggregateToStartDay
        aosGroups {
          group
          name
        }
        aosConfigSchema
        dataLakeId
        dynamicFieldsLayout {
          views {
            name
            groups
          }
          groups {
            name
            label
            fields {
              metadataTypeName
              width
            }
          }
        }
        analyticsEnabled
        contractsUseEmploymentType
        defaultScheduleDayStartTime
        shiftCanOverlapLeaveRequest
        shiftCanOverlapUnavailability
        disciplinaryActionEnabled
        commendationEnabled
        jobTitlesEnabled
      }
    }
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
  employment_id?: string;
}

const NonWrappingRow = styled(Row)`
  flex-wrap: nowrap;
`;
export default function StackContextLayout() {
  const { setStackId, myStacksById } = useAppContext();
  const [environment, setEnvironment] = useState<Environment | undefined>(
    undefined,
  );

  const {
    params: { business_id: businessId, stack_id: stackId },
    match,
  } = useAppRouter<MatchParams>();

  useEffect(() => {
    const stackResult = myStacksById?.get(stackId || "");
    setStackId(stackId);
    setEnvironment(getRegionalStackEnvironment(stackResult?.domainName || ""));
  }, [stackId, setStackId, setEnvironment, myStacksById]);

  if (!environment) {
    return <div>Environment not found.</div>;
  }

  if (!businessId) {
    return <BusinessLayoutForStack environment={environment} match={match} />;
  }

  return (
    <QueryRenderer<StackContextLayout_Query>
      environment={environment}
      query={query}
      variables={{
        businessId,
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>Error!</div>;
        }
        if (!props) {
          return <Loader />;
        }

        if (!props.businesses?.nodes?.length || !props.businesses?.nodes[0]) {
          // return <div>Cannot find business with id {businessId}</div>;
          return <Redirect to="/stacks" />;
        }

        const business = props.businesses?.nodes[0];
        return (
          <BusinessLayoutForStack
            environment={environment}
            business={business}
            match={match}
          />
        );
      }}
    />
  );
}

function BusinessLayoutForStack({
  business,
  environment,
  match,
}: {
  business?: BusinessInContext;
  environment: Environment;
  match: any;
}) {
  return (
    <BusinessContextProvider business={business} environment={environment}>
      <NonWrappingRow noGutters>
        <Col>
          <Container fluid>
            <React.Suspense fallback={<Loader />}>
              <Route
                path={[
                  `${match.path}/profile`,
                  "/stack/:stack_id/business/create",
                ]}
                component={BusinessLayout}
              />
              <Route
                path={`${match.path}/employee/:employment_id`}
                component={EmploymentLayout}
              />
              <Route
                path={`${match.path}/schedule/:schedule_id`}
                component={ScheduleLayout}
              />
              <Route
                path={[
                  `${match.path}/aos_template/edit/:aos_template_id`,
                  `${match.path}/aos_template/create/:schedule_id?`,
                  `${match.path}/aos_template/clone/:clone_from_id?`,
                ]}
                component={AOSTemplateLayout}
              />
              <Route
                path={[
                  `${match.path}/skill_level/edit/:skill_level_id`,
                  `${match.path}/skill_level/create`,
                ]}
                component={SkillLevelLayout}
              />
            </React.Suspense>
          </Container>
        </Col>
      </NonWrappingRow>
    </BusinessContextProvider>
  );
}
