import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";
import { PreloadedQuery, useMutation, usePreloadedQuery } from "react-relay";
import {
  FormikContext,
  FormikContextType,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { toast } from "react-toastify";
import Alert from "react-bootstrap/Alert";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../../common/Portal/HeaderPortal";
import FormLayout from "../../../../common/Form/FormLayout";
import FormLayoutFooter from "../../../../common/Form/FormLayoutFooter";
import {
  CreateBusinessMetadataTypeMutation,
  DeleteBusinessMetadataTypeMutation,
  GetSingleBusinessMetadataTypeQuery,
  UpdateBusinessMetadataTypeMutation,
} from "../MetadataTypesQueries";
import DynamicInputGroup from "../../../../common/Form/DynamicInputGroup";
import {
  getFieldsByNames,
  getSettingsByGroup,
} from "../../../../common/Form/formUtilities";
import properties from "../../../../../data/csv-settings/metadata-type-settings.json";
import {
  IProperty,
  JSONIntListType,
  JSONStringListType,
  metadataDataTypeOptions,
  metadataParentObjectOptions,
} from "../../../../common/Form/models";
import DynamicSelect from "../../../../common/Form/DynamicSelect";
import Field from "../../../../common/Form/Field";
import { useModal } from "../../../../../contexts/ModalContext";
import { RemoveMetadataTypeModal } from "./RemoveMetadataTypeModal";
import { RequiredFieldConfirmationModal } from "./RequiredFieldConfirmationModal";

import { MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery } from "../__generated__/MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery.graphql";
import {
  DynamicFieldsLayoutGroup,
  MetadataType,
  MetadataTypeDataTypeEnum,
} from "../../../../../data/generated/stack_internal_schema";
import { MetadataUtility } from "../../../Employment/EmploymentMetadata/MetadataUtility";
import { useDynamicFieldsLayout } from "../../MetadataLayout/MetadataLayoutQueries";
import { ConfirmationModal } from "../../../../common/ConfirmationModal";
import { useAppRouter } from "../../../../../hooks/useAppRouter";
import RadioButtonGroup from "../../../../common/Form/RadioButtonGroup";

type Props = {
  goBackUrl: string;
  isCreate: boolean;
  metadataTypeId?: string;
  businessId: string;
  queryReference: PreloadedQuery<MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery>;
};

export default function MetadataTypesProfileForm({
  goBackUrl,
  isCreate,
  queryReference,
  businessId,
  metadataTypeId,
}: Props) {
  const { showModal, hideModal } = useModal();
  const router = useAppRouter();
  const { t } = useTranslation("metadata-types");

  const [
    dynamicBusinessLayout,
    { addMetadataTypeToGroups, checkMetadataTypeInGroup },
  ] = useDynamicFieldsLayout();

  const metadataLayoutUrl = router.getGoBackUrl(
    "/metadata_types/",
    "/metadata_layout",
  );

  const validationRules = yup.object({
    name: yup.string().trim().required().label(t("form.labels.fieldName")),
    displayName: yup
      .string()
      .trim()
      .nullable()
      .required()
      .label(t("form.labels.displayName")),
    objectClass: yup.string().required().label(t("form.labels.parentObject")),
    dataType: yup.string().required().label(t("form.labels.dataType")),
    obfuscatedNumber: yup
      .number()
      .nullable()
      .max(999)
      .min(0)
      .label(t("form.labels.obfuscatedNumber")),
    validValues: yup
      .array()
      .nullable()
      .when("maximum", (max: number | null, schema: any) => {
        return max != null ? yup.array(yup.string()) : schema;
      })
      .when(["minimum", "maximum", "dataType"], (...options: any) => {
        const [min, max, dataType, schema] = options;

        let yupField:
          | yup.StringSchema<string | undefined>
          | yup.NumberSchema<number | undefined>;
        switch (dataType) {
          case MetadataTypeDataTypeEnum.String: {
            yupField = yup
              .string()
              .required()
              .label(t("form.labels.validValuesValue"));

            if (min != null) {
              yupField = yupField.min(min);
            }
            if (max != null) {
              yupField = yupField.max(max);
            }
            return yup
              .array(yupField)
              .transform((value, originalValue) => {
                if (!value || value.length === 0) {
                  return null;
                }
                return originalValue;
              })
              .nullable();
          }
          case MetadataTypeDataTypeEnum.Number: {
            yupField = yup
              .number()
              .required()
              .label(t("form.labels.validValuesValue"));

            if (min != null) {
              yupField = yupField.min(min);
            }
            if (max != null) {
              yupField = yupField.max(max);
            }
            return yup
              .array(yupField)
              .transform((value, originalValue) => {
                if (!value || value.length === 0) {
                  return null;
                }
                return originalValue;
              })
              .nullable();
          }
          default:
            return schema?.nullable();
        }
      })
      .transform((value, originalValue) => {
        if (!value || value.length === 0) {
          return null;
        }
        return originalValue;
      })
      .label(t("form.labels.validValues")),
  });

  const [metadataType, createOrUpdateMutation, deleteMutation] =
    useMetadataType(queryReference);

  const showRequiredFieldWarning = (i: MetadataType) =>
    !checkMetadataTypeInGroup(i) &&
    MetadataUtility.metadataTypeShouldBeVisible(i);

  const componentRules = {
    objectClass: {
      component: DynamicSelect,
      componentProps: {
        options: metadataParentObjectOptions,
        defaultValue: null,
      },
    },
    dataType: {
      component: DynamicSelect,
      onValueChanged: (
        value: MetadataTypeDataTypeEnum,
        formikContext: FormikContextType<MetadataType>,
      ) => {
        const allowsMinMaxEntry = [
          MetadataTypeDataTypeEnum.Number,
          MetadataTypeDataTypeEnum.String,
        ].includes(value);
        if (!allowsMinMaxEntry) {
          formikContext.setFieldValue("maximum", null);
          formikContext.setFieldValue("minimum", null);
          formikContext.setFieldValue("obfuscatedNumber", null);
        }
        formikContext.setFieldValue("validValues", null);
      },
      componentProps: {
        options: metadataDataTypeOptions,
        defaultValue: null,
      },
    },
    maximum: {
      disabled: (values: MetadataType) =>
        values?.dataType &&
        ![
          MetadataTypeDataTypeEnum.Number,
          MetadataTypeDataTypeEnum.String,
        ].includes(values?.dataType),
    },
    minimum: {
      disabled: (values: MetadataType) =>
        values?.dataType &&
        ![
          MetadataTypeDataTypeEnum.Number,
          MetadataTypeDataTypeEnum.String,
        ].includes(values?.dataType),
    },
    validValues: {
      hideLabel: true,
      md: 6,
      xs: 12,
      disabled: (values: MetadataType) =>
        !values?.dataType ||
        ![
          MetadataTypeDataTypeEnum.Number,
          MetadataTypeDataTypeEnum.String,
        ].includes(values?.dataType),
      component: (props: any) => <ValidValuesList {...props} />,
      componentProps: {
        hideError: true,
      },
    },
    obfuscatedNumber: {
      component: RadioButtonGroup,
      // LK-8992: Only applicable if number/string field. Only relevant if external is set to true, but this won't be blocked.
      disabled: (values: MetadataType) =>
        !MetadataUtility.isStringOrNumber(values) || !values.external,
      defaultValue: "",
      componentProps: {
        options: [
          {
            radioValue: "none",
            label: t("form.obfuscationInput.none"),
            value: null,
          },
          {
            radioValue: "all",
            label: t("form.obfuscationInput.all"),
            value: 0,
          },
          {
            radioValue: "custom",
            customPrefixLabel: t("form.obfuscationInput.customPrefix"),
            customPostfixLabel: t("form.obfuscationInput.customPostfix"),
            custom: true,
            // value: do not add, this is a dynamic value
          },
        ],
      },
    },
    required: {
      description: (
        <Trans
          i18nKey="metadata-types:form.descriptions.required"
          components={[<Link to={metadataLayoutUrl} />]}
        />
      ),
    },
  };

  const onSave = (
    changes: Partial<MetadataType>,
    errorHandler: (error: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: MetadataType,
    helpers?: FormikHelpers<MetadataType>,
  ) => {
    const saveCompleted = () => {
      toast(t(isCreate ? "form.toast.created" : "form.toast.updated"));
      router.replace(goBackUrl);
      hideModal();
    };

    const save = (onCompleted = saveCompleted) => {
      createOrUpdateMutation({
        variables: {
          businessId,
          ...(!isCreate && { id: metadataTypeId }),
          input: validationRules.cast(changes),
        },
        onCompleted,
        onError(error: Error) {
          errorHandler(error);
        },
      });
    };

    if (
      !isCreate &&
      changes.required &&
      values &&
      showRequiredFieldWarning(values)
    ) {
      // if changing to required and not internal access and not visible in Webui
      // show confirmation dialog before save
      showModal(
        <RequiredFieldConfirmationModal
          onClose={() => {
            helpers?.setSubmitting(false);
            hideModal();
          }}
          metadataType={metadataType}
          onOk={(groups: DynamicFieldsLayoutGroup[]) => {
            save(() => {
              addMetadataTypeToGroups(
                metadataType.name,
                groups,
                () => {
                  toast(t("form.toast.addedToGroup"));
                },
                () => {
                  toast(t("form.toast.errorAddingToGroup"));
                },
              );
              saveCompleted();
            });
          }}
          groups={dynamicBusinessLayout.groups}
          views={dynamicBusinessLayout.views}
        />,
      );
      return;
    }

    save();
  };

  const onDeleteClick = () => {
    const onSuccess = () => {
      hideModal();
      toast(t("form.toast.removed"));
      router.replace(goBackUrl);
    };

    if (metadataType) {
      if (checkMetadataTypeInGroup(metadataType)) {
        showModal(
          <ConfirmationModal
            onClose={hideModal}
            okClicked={hideModal}
            hideCancel
            title={t("unableToDeleteModal.title")}
          >
            <Trans
              i18nKey="metadata-types:unableToDeleteModal.body"
              values={{ name: metadataType.name }}
              components={{ bold: <strong /> }}
            />
          </ConfirmationModal>,
        );
      } else {
        showModal(
          <RemoveMetadataTypeModal
            onClose={hideModal}
            metadataType={metadataType}
            deleteMutation={deleteMutation}
            businessId={businessId}
            onSuccess={onSuccess}
          />,
        );
      }
    }
  };

  const getBreadcrumbDisplayName = (type?: MetadataType) => {
    if (type && !isCreate) {
      return MetadataUtility.getDisplayName(type);
    }
    return t("form.create.title");
  };

  return (
    <>
      <HeaderPortal>
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <Link to={goBackUrl}>
              <span>{t("nav.metadataTypes")}</span>
            </Link>,
            <span>{getBreadcrumbDisplayName(metadataType)}</span>,
          ]}
        />
      </HeaderPortal>
      <FormLayout<MetadataType>
        isCreate={isCreate}
        onSave={onSave}
        propertyList={[]}
        base={metadataType}
        componentRules={componentRules}
        validationRules={validationRules}
      >
        <FormikContext.Consumer>
          {({ values }) => {
            return (
              <>
                {showRequiredFieldWarning(values) && (
                  <Alert variant="warning">
                    {t("metadataLayout.warnings.requiredFieldsProfile", {
                      fields: values.displayName,
                    })}
                  </Alert>
                )}
                <DynamicInputGroup
                  hideGroupName
                  fields={getSettingsByGroup(
                    getFieldsByNames(properties as unknown as IProperty[], [
                      "name",
                      "displayName",
                      "objectClass",
                      "required",
                      "description",
                      "dataType",
                      "minimum",
                      "maximum",
                      "validValues",
                    ]),
                  )}
                />

                <hr className="mt-4 mb-4" />

                <DynamicInputGroup
                  hideGroupName
                  fields={getSettingsByGroup(
                    getFieldsByNames(properties as unknown as IProperty[], [
                      "external",
                      "isTimeboxed",
                      "internalAccess",
                      "encrypted",
                      "obfuscatedNumber",
                    ]),
                  )}
                />

                <FormLayoutFooter
                  isCreate={isCreate}
                  onDelete={onDeleteClick}
                />
              </>
            );
          }}
        </FormikContext.Consumer>
      </FormLayout>
    </>
  );
}

function useMetadataType(queryReference: any) {
  const { metadataTypes } =
    usePreloadedQuery<MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery>(
      GetSingleBusinessMetadataTypeQuery,
      queryReference,
    );

  const data = metadataTypes?.nodes[0] ?? null;

  const [deleteMutation] = useMutation(DeleteBusinessMetadataTypeMutation);

  const [updateMutation] = useMutation(
    data == null
      ? CreateBusinessMetadataTypeMutation
      : UpdateBusinessMetadataTypeMutation,
  );

  return [
    (data ?? {
      internalAccess: true,
      obfuscatedNumber: null,
    }) as MetadataType,
    updateMutation,
    deleteMutation,
  ] as const;
}

function ValidValuesList(props: any) {
  const formikContext = useFormikContext() as FormikContextType<MetadataType>;
  const dataType = formikContext?.values?.dataType;

  // To ensure that the list allows only strings or numbers, we need to watch the data type
  return (
    <Field
      xs={12}
      lg={12}
      md={12}
      {...props}
      schemaFieldType={
        dataType === MetadataTypeDataTypeEnum.String
          ? JSONStringListType
          : JSONIntListType
      }
    />
  );
}
