import React, { FunctionComponent, useEffect, useState } from "react";
import { graphql, createFragmentContainer, Environment } from "react-relay";

import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { RouteComponentProps } from "react-router-dom";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import { useAppContext } from "../../external/Context/AppContext";
import { Profile_stack$data } from "./__generated__/Profile_stack.graphql";

import HeaderPortal from "../../../common/Portal/HeaderPortal";

import FormLayout from "../../../common/Form/FormLayout";
import Field from "../../../common/Form/Field";
import FormLayoutFooter from "../../../common/Form/FormLayoutFooter";
import { StringFieldType } from "../../../common/Form/models";

import UpdateStackMutation from "../mutations/UpdateStackMutation";
import RegisterStackMutation from "../mutations/RegisterStackMutation";
import BusinessService, {
  GlobalBusinessStack,
} from "../../../Stack/Services/BusinessService";
import { getRegionalStackEnvironment } from "../../../../environment";
import SetGlobalBusinessStackModal from "./SetGlobalBusinessStackModal";
import { useModal } from "../../../../contexts/ModalContext";
import { supportedAPIVersion } from "../../../../utils/utility";

const minimumGlobalStackSupportVersion = "1.32.0";

type Props = RouteComponentProps & {
  stack: Profile_stack$data | null;
  environment: Environment;
  onDeleted?: (i: any) => void;
};

const validationRules = yup.object({
  domainName: yup.string().required(),
  stackCode: yup.string().required(),
});

type FormData = Profile_stack$data;

const Profile: FunctionComponent<Props> = (props: Props) => {
  const { loadMyStacks, myStacksById } = useAppContext();
  const { showModal, hideModal } = useModal();
  const { t } = useTranslation("stacks");
  const { history, stack, environment } = props;
  const hasStackAccess =
    (stack && myStacksById && myStacksById.has(stack.id)) || false;

  const [canEditGlobalStack, setCanEditGlobalStack] =
    useState<boolean>(hasStackAccess);

  const [globalBusinessStack, setGlobalBusinessStack] =
    useState<GlobalBusinessStack | null>(null);

  useEffect(() => {
    async function load() {
      const currentStackEnvironment = getRegionalStackEnvironment(
        stack?.domainName,
      );

      if (stack) {
        const globalStackSupported = await supportedAPIVersion(
          stack.domainName,
          minimumGlobalStackSupportVersion,
        );

        if (globalStackSupported) {
          const currentGlobalBusinessStack =
            currentStackEnvironment && hasStackAccess
              ? await BusinessService.getGlobalBusinessStack(
                  currentStackEnvironment,
                )
              : null;

          setGlobalBusinessStack(currentGlobalBusinessStack ?? null);
        } else {
          setCanEditGlobalStack(false);
        }
      }
    }

    load();
  }, [stack, hasStackAccess]);

  const onSaved = () => {
    toast(t("form.savedSuccessfully"));
    loadMyStacks();
    history.push("/stacks");
  };

  const onGlobalBusinessAssigned = (value: GlobalBusinessStack | null) => {
    setGlobalBusinessStack(value);
  };

  const onClickChangeGlobalBusinessStack = () => {
    if (!stack) {
      return;
    }
    showModal(
      <SetGlobalBusinessStackModal
        stack={stack}
        globalBusinessStack={globalBusinessStack}
        onSaved={onGlobalBusinessAssigned}
        hideModal={hideModal}
      />,
    );
  };

  const handleSave = (
    changes: Partial<FormData>,
    onError: (err: Error) => void,
    event: React.MouseEvent<HTMLButtonElement> | undefined,
    values: FormData | undefined,
  ) => {
    if (!values) {
      return;
    }

    if (values.id) {
      UpdateStackMutation(
        environment,
        values.id,
        values.domainName,
        values.stackCode,
        values.deleted,
        onSaved,
        onError,
      );
    } else {
      RegisterStackMutation(
        environment,
        values.domainName,
        values.stackCode,
        onSaved,
        onError,
      );
    }
  };

  return (
    <div className="panel">
      <FormLayout<FormData>
        base={stack || ({} as FormData)}
        onSave={handleSave}
        propertyList={[]}
        validationRules={validationRules}
      >
        <fieldset>
          <Row>
            <Field
              label={t("form.domainName")}
              md={4}
              lg={4}
              fieldKey="domainName"
              disabled
              schemaFieldType={StringFieldType}
            />
            <Field
              label={t("form.stackCode")}
              md={4}
              lg={4}
              fieldKey="stackCode"
              disabled
              schemaFieldType={StringFieldType}
            />
          </Row>
          {canEditGlobalStack && (
            <Row>
              <Col md={4} lg={4}>
                <Form.Group controlId="global-business-stack">
                  <Form.Label>
                    {t("form.globalBusinessStackDomainName")}
                    <Button
                      variant="link"
                      className="ml-2"
                      onClick={onClickChangeGlobalBusinessStack}
                    >
                      {t("form.change")}
                    </Button>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="global-business-stack"
                    value={globalBusinessStack?.stackDomainName ?? ""}
                    placeholder={t("form.globalBusinesStackNotSet")}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
        </fieldset>
        <FormLayoutFooter />
      </FormLayout>

      <HeaderPortal as="span">
        {stack?.id ? stack?.domainName : t("layout.newStack")}
      </HeaderPortal>
    </div>
  );
};

export default createFragmentContainer(
  Profile,
  // Each key specified in this object will correspond to a prop available to the component
  {
    stack: graphql`
      # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
      fragment Profile_stack on Stack {
        id
        domainName
        stackCode
        deleted
      }
    `,
  },
);
