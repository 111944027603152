import React, { CSSProperties, useEffect, useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { useFormikContext } from "formik";
import { CSS } from "@dnd-kit/utilities";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import { OverlayTrigger } from "react-bootstrap";
import VerticalSortableContext, {
  getSortableIdByIndex,
  handleSortableAdd,
  handleSortableDelete,
  SortableDragHandle,
  SortableTrashIcon,
} from "../../../../../../contexts/VerticalSortableContext";
import { useMetadataTypesForLayouts } from "../../MetadataLayoutQueries";
import Field from "../../../../../common/Form/Field";
import DynamicSelect from "../../../../../common/Form/DynamicSelect";
import {
  metadataLayoutWidthOptions,
  StringFieldType,
} from "../../../../../common/Form/models";
import {
  DynamicFieldsLayoutGroup,
  DynamicFieldsLayoutGroupField,
} from "../../../../../../data/generated/stack_internal_schema";

const TableWrapper = styled.div`
  // Some of these styles can probably be made in a more common place eventually
  tbody {
    border: 0.03em solid ${(props) => props.theme.grey300};
  }

  thead {
    border: 0.03em solid ${(props) => props.theme.grey300};
    > tr > th {
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;
    }
  }

  tr {
    &.row-error {
      td {
        padding-bottom: 35px;
      }
    }
    td {
      display: table-cell;
      vertical-align: middle;
      height: 55px;

      .form-group,
      > div {
        margin: 0;
        padding: 0;
        height: 35px;
      }
      &.metadata-name-field > div {
        padding: 0;
        min-width: 220px;
      }
      &.drag-cell {
        padding-right: 15px;
        padding-left: 15px;
      }
      &.delete-cell {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
    &.add-metadata-row {
      border-top: 0.03em solid ${(props) => props.theme.grey300};
    }
  }
`;

const newRowItem = {
  metadataTypeName: "",
  width: 4,
};

export default function SortableMetadataTable() {
  const { t } = useTranslation("metadata-types");

  const metadataTypes = useMetadataTypeOptions();
  const formikContext = useFormikContext<DynamicFieldsLayoutGroup>();
  const helpers = formikContext.getFieldHelpers("fields");

  const fieldsData = (formikContext.getFieldProps("fields").value ?? [
    newRowItem,
  ]) as DynamicFieldsLayoutGroupField[];

  const errors = formikContext.getFieldMeta("fields")?.error ?? [];

  const canAdd =
    fieldsData[fieldsData.length - 1]?.metadataTypeName !== "" &&
    fieldsData?.length !== metadataTypes.length;

  const canDelete = fieldsData.length > 1;

  const handleDelete = (sortableId: string) =>
    handleSortableDelete(helpers, fieldsData, sortableId);

  const handleAdd = () => handleSortableAdd(helpers, fieldsData, newRowItem);

  const metadataTypesSelected = fieldsData.map((x) => x.metadataTypeName);

  const selectableMetadataTypes = metadataTypes.map((option) => {
    return {
      ...option,
      selectable: !metadataTypesSelected.includes(option.value),
      metadataType: {
        label: option.metadataType.displayName,
        dataType: option.metadataType.dataType,
      },
    };
  });

  return (
    <TableWrapper>
      <Table borderless size="sm">
        <thead>
          <tr>
            <th aria-label="reorder" />
            <th>
              {t("metadataLayout.groups.editModal.table.headers.metadata")}
            </th>
            <th>{t("metadataLayout.groups.editModal.table.headers.label")}</th>
            <th>
              {t("metadataLayout.groups.editModal.table.headers.inputType")}
            </th>
            <th>{t("metadataLayout.groups.editModal.table.headers.width")}</th>
            <th aria-label="remove" />
          </tr>
        </thead>
        <tbody>
          <VerticalSortableContext data={fieldsData} onSort={helpers.setValue}>
            {fieldsData.map((field, index) => (
              <SortableRow
                index={index}
                key={`${field.metadataTypeName}`}
                sortableId={getSortableIdByIndex(index)}
                field={field}
                handleDelete={handleDelete}
                metadataOptions={selectableMetadataTypes}
                canDelete={canDelete}
                error={errors[index]}
              />
            ))}
            <tr className="add-metadata-row">
              <td />
              <td>
                <Button disabled={!canAdd} onClick={handleAdd} variant="light">
                  {t(
                    "metadataLayout.groups.editModal.table.actions.addMetadata",
                  )}
                </Button>
              </td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </VerticalSortableContext>
        </tbody>
      </Table>
    </TableWrapper>
  );
}

function SortableRow({
  field,
  handleDelete,
  sortableId,
  index,
  metadataOptions,
  canDelete,
  error,
}: {
  sortableId: string;
  field: DynamicFieldsLayoutGroupField;
  handleDelete: (sortableId: string) => void;
  index: number;
  metadataOptions: {
    label: string;
    value: string;
    selectable: boolean;
    metadataType: any;
  }[];
  canDelete: boolean;
  error: string | undefined | null;
}) {
  const { t } = useTranslation("metadata-types");

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: sortableId,
    });

  const selectedOption = metadataOptions.find(
    (option) => field.metadataTypeName === option.value,
  );

  const selectableOptions = metadataOptions.filter(
    // Hide unselectable options, but still show if it's this row is for that type
    (option) =>
      !(!option.selectable && option.value !== field.metadataTypeName),
  );

  const disabled =
    field.metadataTypeName === "" || field.metadataTypeName == null;

  return (
    <tr
      ref={setNodeRef}
      style={
        {
          transform: CSS.Transform.toString(transform),
          transition,
        } as CSSProperties
      }
      className={error ? "row-error" : ""}
    >
      <td className="drag-cell">
        <SortableDragHandle
          disabled={disabled}
          {...attributes}
          {...listeners}
        />
      </td>
      <td className="metadata-name-field">
        <Field
          xs={12}
          md={12}
          lg={12}
          fieldKey={`fields[${index}].metadataTypeName`}
          component={DynamicSelect}
          componentProps={{
            selectProps: { minWidth: 220, maxWidth: 220 },
            options: selectableOptions,
            placeholder: t(
              "metadataLayout.groups.editModal.fields.metadataName.placeholder",
            ),
            autoFocus: true,
          }}
          schemaFieldType={StringFieldType}
        />
      </td>
      <td>
        <MetadataLabelField
          value={selectedOption?.metadataType.label ?? ""}
          placeholder={
            !selectedOption?.metadataType
              ? t("metadataLayout.groups.editModal.fields.label.placeholder")
              : ""
          }
        />
      </td>
      <td>
        <Form.Control
          type="text"
          value={selectedOption?.metadataType.dataType ?? ""}
          placeholder={t(
            "metadataLayout.groups.editModal.fields.inputType.placeholder",
          )}
          disabled
        />
      </td>
      <td>
        <Field
          fieldKey={`fields[${index}].width`}
          component={DynamicSelect}
          componentProps={{
            selectProps: { minWidth: 80, maxWidth: 80 },
            options: metadataLayoutWidthOptions,
          }}
          schemaFieldType={StringFieldType}
        />
      </td>
      <td className="delete-cell">
        <SortableTrashIcon
          disabled={!canDelete}
          onClick={() => handleDelete(sortableId)}
        />
      </td>
    </tr>
  );
}

function useMetadataTypeOptions() {
  const [metadataTypes] = useMetadataTypesForLayouts();
  return metadataTypes
    .filter((metadataType) => !metadataType.internalAccess)
    .map((metadataType) => ({
      label: metadataType.name,
      value: metadataType.name,
      metadataType,
    }));
}

const StyledLabelField = styled(Form.Control)`
  pointer-events: none;
  cursor: pointer;
  text-overflow: ellipsis;
`;

function MetadataLabelField({
  value,
  placeholder,
}: {
  value: string;
  placeholder: string;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (inputRef?.current != null) {
      setShowTooltip(
        inputRef.current.scrollWidth > inputRef.current.clientWidth,
      );
    }
  }, [inputRef]);

  return showTooltip ? (
    <OverlayTrigger
      defaultShow={false}
      placement="top"
      overlay={
        <Tooltip show={showTooltip} id="tooltip">
          {value}
        </Tooltip>
      }
      trigger={undefined}
      delay={undefined}
      flip={undefined}
      onHide={undefined}
      onToggle={undefined}
      popperConfig={undefined}
      show={undefined}
      target={undefined}
    >
      <div style={{ display: "inline-block" }}>
        <StyledLabelField
          ref={inputRef}
          type="text"
          value={value}
          placeholder={placeholder}
          disabled
        />
      </div>
    </OverlayTrigger>
  ) : (
    <StyledLabelField
      ref={inputRef}
      type="text"
      value={value}
      placeholder={placeholder}
      disabled
    />
  );
}
