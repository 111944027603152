import React, { FunctionComponent, useEffect, useState } from "react";
import { createFragmentContainer, Environment, graphql } from "react-relay";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import omit from "lodash/omit";
import { Profile_business$data } from "./__generated__/Profile_business.graphql";
import UpdateBusinessMutation from "../mutations/UpdateBusinessMutation";
import CreateBusinessMutation from "../mutations/CreateBusinessMutation";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import properties from "../../../data/csv-settings/business-settings.json";
import FormLayout from "../../common/Form/FormLayout";
import FormLayoutFooter from "../../common/Form/FormLayoutFooter";
import {
  ComponentRule,
  currencyTypeOptions,
  daysOfWeekOptions,
  IProperty,
  payFrequencyOptions,
  PayFrequncies,
  punchSlipModeOptions,
  restScreenThemeOptions,
  swapDropFilteringOptions,
  swapSortingOptions,
} from "../../common/Form/models";
import { EmploymentRateEditPastEnum } from "../../../data/generated/stack_internal_schema";
import PayDates from "../../common/Form/PayDates";
import DynamicSelect from "../../common/Form/DynamicSelect";
import TopLevelForecastMeasurements from "../../common/Form/TopLevelForecastMeasurements";
import DynamicInputGroup from "../../common/Form/DynamicInputGroup";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../common/Form/formUtilities";
import List from "../../common/Form/List";
import FileUpload from "../../common/Form/FileUpload";
import { Id } from "../../../data/models/common";
import BusinessService, { GlobalBusiness } from "../Services/BusinessService";
import Checkbox from "../../common/Form/Checkbox";
import { useTimeOffShiftComponentRules } from "../../../hooks/useTimeOffShiftFieldComponentRules";
import Switch from "../../common/Form/Switch";
import TimeclockWorkflow from "./TimeclockWorkflow";
import DurationInput from "../../common/Form/DurationInput";
import JsonEditor from "../../common/Form/JsonEditor";

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  business: Profile_business$data | null;
  environment?: Environment;
};

const Profile: FunctionComponent<Props> = ({
  business,
  history,
  match: {
    params: { business_id: businessId },
  },
}) => {
  const isCreate = businessId == null;
  const {
    environment,
    appContext: { stack },
  } = useBusinessContext();
  const { t } = useTranslation();
  const [globalBusinesses, setGlobalBusinesses] = useState<
    Map<Id, GlobalBusiness>
  >(new Map<Id, GlobalBusiness>());

  const emplTypeTimeOffComplianceEnabledComponentRules =
    useTimeOffShiftComponentRules();

  const fields = useBusinessFormFields();

  useEffect(() => {
    async function load() {
      if (!environment) {
        return;
      }
      const maps = await BusinessService.getGlobalBusinesses(environment);
      setGlobalBusinesses(maps);
    }

    load();
  }, [environment]);

  if (!environment) {
    return null;
  }

  const inviteComponentRules = {
    md: 6,
    lg: 6,
    componentProps: {
      xs: 12,
      md: 12,
      lg: 12,
    },
  };

  // Custom component rules
  const componentRules: Record<string, ComponentRule> = {
    inviteHeader: {
      ...inviteComponentRules,
    },
    inviteMessage: {
      ...inviteComponentRules,
    },
    inviteFooter: {
      ...inviteComponentRules,
    },

    /**
     * https://wiki.plusc.io/pages/viewpage.action?spaceKey=PROD&title=Timeclock+workflow+storage+on+backend
     * Note this is a custom setting that is not a part of Profile_business
     */
    timeClockWorkflow: {
      xs: 12,
      lg: 12,
      md: 12,
      hideDescription: true,
      hideError: true,
      component: TimeclockWorkflow,
      componentProps: {
        isNewBusiness: isCreate,
      },
    },
    analyticsServerUrl: {
      disabled: (values: Profile_business$data) => !values.analyticsEnabled,
    },
    dataLakeId: {
      disabled: (values: Profile_business$data) => !values.analyticsEnabled,
    },
    dataLakeGlobalId: {
      disabled: (values: Profile_business$data) => !values.analyticsEnabled,
    },
    defaultScheduleSnapshotStartDate: {
      disabled: (values: Profile_business$data) => !values.analyticsEnabled,
    },
    defaultScheduleSnapshotDuration: {
      disabled: (values: Profile_business$data) => !values.analyticsEnabled,
    },
    analyticsForecastHiddenSettings: {
      disabled: (values: Profile_business$data) => !values.analyticsEnabled,
    },
    showSystemRolesAndPermissions: {
      lg: 6,
      md: 6,
      xs: 6,
    },
    businessTerms: {
      lg: 6,
      md: 6,
      xs: 6,
    },
    forecastRequiresApproval: {
      disabled: (values: Profile_business$data) => !values.analyticsEnabled,
      lg: 12,
      md: 12,
      xs: 12,
    },
    canAdjustForecast: {
      disabled: (values: Profile_business$data) => !values.analyticsEnabled,
      lg: 12,
      md: 12,
      xs: 12,
    },
    canOverrideForecast: {
      disabled: (values: Profile_business$data) => !values.analyticsEnabled,
      lg: 12,
      md: 12,
      xs: 12,
    },
    payDates: {
      // render payDate using custom component
      component: PayDates,
      componentProps: {},
      disabled: (values: Profile_business$data) => {
        return values.payFrequency !== PayFrequncies.BI_MONTHLY;
      },
    },
    globalBusinessId: {
      component: DynamicSelect,
      componentProps: {
        options: Array.from(globalBusinesses.values()).map((i) => {
          return { value: i.id, label: i.name };
        }),
        defaultValue: null,
        isClearable: true,
      },
    },
    payFrequency: {
      component: DynamicSelect,
      componentProps: {
        options: payFrequencyOptions,
        defaultValue: null,
      },
    },
    swapDropFiltering: {
      component: DynamicSelect,
      componentProps: {
        options: swapDropFilteringOptions,
        defaultValue: null,
      },
    },
    swapSorting: {
      component: DynamicSelect,
      componentProps: {
        options: swapSortingOptions,
        defaultValue: null,
      },
    },
    rateType: {
      component: JsonEditor,
      componentProps: {
        defaultValue: [],
        mode: "code",
        xs: 12,
        md: 12,
        lg: 12,
      },
      xs: 8,
      md: 8,
      lg: 8,
    },
    payPeriodSelfApprovalEnabled: {
      disabled: (values: Profile_business$data) =>
        !values.payPeriodReviewEnabled,
    },
    shiftDropThreshold: {
      disabled: (values: Profile_business$data) => !values.shiftDropEnabled,
    },
    shiftSwapNeedsApproval: {
      disabled: (values: Profile_business$data) => !values.shiftSwapEnabled,
    },
    restScreenTheme: {
      component: DynamicSelect,
      componentProps: {
        options: restScreenThemeOptions,
        defaultValue: null,
      },
    },
    punchSlipMode: {
      component: DynamicSelect,
      componentProps: {
        options: punchSlipModeOptions,
        defaultValue: null,
      },
    },
    jobTitlesEnabled: {
      onValueChanged: (value: boolean, formikContext) => {
        if (!value) {
          formikContext.setFieldValue("employmentRateEditEnabled", false);
          formikContext.setFieldValue("deletePrimaryJobTitlesEnabled", false);
          formikContext.setFieldValue(
            "employmentRateEditPast",
            EmploymentRateEditPastEnum.Disabled,
          );
          formikContext.setFieldValue(
            "approvalRequests.employmentRateChange.autoApprovalEnabled",
            // set default value based on value of employmentRateEditEnabled
            false,
          );
        }
      },
    },
    sharedStoreShiftsEnabled: {
      disabled: (values: Profile_business$data) =>
        !values.crossScheduleComplianceChecksEnabled,
    },
    crossScheduleComplianceChecksEnabled: {
      onValueChanged: (value: boolean, formikContext) => {
        if (!value) {
          formikContext.setFieldValue("sharedStoreShiftsEnabled", false);
        }
      },
    },
    employmentRateEditEnabled: {
      disabled: (values: Profile_business$data) => !values.jobTitlesEnabled,
      onValueChanged: (value: boolean, formikContext) => {
        formikContext.setFieldValue("deletePrimaryJobTitlesEnabled", false);
        formikContext.setFieldValue(
          "approvalRequests.employmentRateChange.autoApprovalEnabled",
          false,
        );
        formikContext.setFieldValue(
          "employmentRateEditPast",
          // set default value based on value of employmentRateEditEnabled
          value
            ? EmploymentRateEditPastEnum.Enabled
            : EmploymentRateEditPastEnum.Disabled,
        );
      },
      lg: 12,
      md: 12,
      xs: 12,
    },
    employmentRateEditPast: {
      disabled: (values: Profile_business$data) =>
        !values.employmentRateEditEnabled,
      component: Checkbox,
      hideLabel: true,
      componentProps: {
        toBoolean: (v: EmploymentRateEditPastEnum) =>
          v === EmploymentRateEditPastEnum.Enabled,
        fromBoolean: (v: boolean) =>
          v
            ? EmploymentRateEditPastEnum.Enabled
            : EmploymentRateEditPastEnum.Disabled,
      },
    },
    approvalRequests: {
      hideLabel: true,
      hideDescription: true,
      lg: 3,
      md: 6,
      xs: 12,
    },
    "approvalRequests.employmentRateChange": {
      hideLabel: true,
      hideDescription: true,
      lg: 12,
      md: 12,
      xs: 12,
    },
    "approvalRequests.employmentRateChange.autoApprovalEnabled": {
      disabled: (values: Profile_business$data) =>
        !values.employmentRateEditEnabled,
      hideDescription: false,
      lg: 12,
      md: 12,
      xs: 12,
    },
    deletePrimaryJobTitlesEnabled: {
      disabled: (values: Profile_business$data) =>
        !values.employmentRateEditEnabled,
    },
    firstDayOfWeek: {
      component: DynamicSelect,
      componentProps: {
        options: daysOfWeekOptions,
        defaultValue: null,
      },
    },
    topLevelForecastMeasurements: {
      lg: 8,
      md: 12,
      xs: 12,
      component: TopLevelForecastMeasurements,
      componentProps: {},
      disabled: (values: Profile_business$data) => !values.analyticsEnabled,
    },
    idleTimeoutLength: {
      disabled: (values: Profile_business$data) => !values.idleTimeoutEnabled,
    },
    darFields: {
      disabled: (values: Profile_business$data) => !values.darEnabled,
      lg: 12,
      md: 12,
      xs: 12,
      component: List,
      componentProps: {
        defaultValue: {
          name: "",
          fieldCode: "",
          valueType: "",
          description: "",
        },
      },
    },
    roundingStrategy: {
      emptyValue: null,
    },
    darDataOnlyPastSlicesEnabled: {
      disabled: (values: Profile_business$data) => !values.darEnabled,
    },
    darAnalyticsSyncThreshold: {
      disabled: (values: Profile_business$data) => !values.darEnabled,
    },
    timeClockAppBusinessImageUri: {
      lg: 12,
      md: 6,
      xs: 12,
      component: FileUpload,
      componentProps: {
        getValueKey: "timeClockAppBusinessImageId",
        setValueKey: "timeClockAppBusinessImageUri",
        getImagePreviewUrl: (
          values: Profile_business$data,
          imageId: Id | null,
        ) => {
          const { id } = values;
          return imageId
            ? `api/businesses/${id}/time_clock_app_business_images/${imageId}`
            : null;
        },
        dropzoneProps: {
          multiple: false,
          accept: {
            "image/*": [".jpeg", ".png"],
          },
          maxSize: 500000,
        },
      },
    },
    defaultPayrollCutoffTime: {
      disabled: (values: Profile_business$data) => !values.aggregateToStartDay,
    },
    liveTimeClockViewEnabled: {
      disabled: (values: Profile_business$data) => !values.managerAppEnabled,
    },

    swapMobileDisclaimerEnabled: {
      lg: 12,
      md: 12,
      xs: 12,
    },
    swapMobileDisclaimer: {
      disabled: (values: Profile_business$data) =>
        !values.swapMobileDisclaimerEnabled,
    },
    dropMobileDisclaimerEnabled: {
      lg: 12,
      md: 12,
      xs: 12,
    },
    dropMobileDisclaimer: {
      disabled: (values: Profile_business$data) =>
        !values.dropMobileDisclaimerEnabled,
    },
    enableShiftDifferentials: {
      xs: 3,
      md: 3,
      lg: 3,
      component: Switch,
      hideLabel: true,
      componentProps: {
        defaultValue: false,
        boldLabels: true,
        onLabel: t("people-settings:form.payroll.differential_rates_enable"),
        offLabel: t("people-settings:form.payroll.differential_rates_disable"),
      },
    },
    meetingsEnabled: {
      component: Switch,
      label: undefined,
      hideLabel: true,
      lg: 3,
      componentProps: {
        boldLabels: true,
        onLabel: t("translation:switch.enable"),
        offLabel: t("translation:switch.disable"),
      },
    },
    meetingMaxPerYear: {
      disabled: (values: Profile_business$data) => !values.meetingsEnabled,
    },
    meetingMinimumDuration: {
      component: DurationInput,
      disabled: (values: Profile_business$data) => !values.meetingsEnabled,
      componentProps: {
        from: "seconds",
        to: "minutes",
        postfix: "mins",
      },
    },
    meetingTitles: {
      disabled: true,
    },
    biometricExpirationExemptJtcCodes: {
      componentProps: {
        mode: "code",
      },
    },
    enableMiscPay: {
      xs: 3,
      md: 3,
      lg: 3,
      component: Switch,
      hideLabel: true,
      componentProps: {
        boldLabels: true,
        onLabel: t("people-settings:form.payroll.misc_pay_enable"),
        offLabel: t("people-settings:form.payroll.misc_pay_disable"),
      },
    },
    payPeriodReviewEnabled: {
      xs: 3,
      md: 3,
      lg: 3,
      component: Switch,
      label: undefined,
      componentProps: {
        boldLabels: true,
        onLabel: t("people-settings:form.payroll.pay_period_review_enable"),
        offLabel: t("people-settings:form.payroll.pay_period_review_disable"),
      },
    },
    currencyType: {
      component: DynamicSelect,
      componentProps: {
        options: currencyTypeOptions,
        defaultValue: business?.currencyType,
      },
    },
    punchAdjustmentConsentEnabled: {
      xs: 12,
      md: 12,
      lg: 12,
      component: Switch,
      label: undefined,
      componentProps: {
        boldLabels: true,
        onLabel: t("timekeeping:form.punch_adjustment_consent_enable"),
        offLabel: t("timekeeping:form.punch_adjustment_consent_disable"),
      },
    },
    punchEditForceChangesLegalDisclaimerEnabled: {
      lg: 12,
      md: 12,
      xs: 12,
    },
    punchEditLegalDisclaimer: {
      lg: 12,
      md: 12,
      xs: 12,
      disabled: (values: Profile_business$data) =>
        !values.punchEditForceChangesLegalDisclaimerEnabled,
    },
    ...emplTypeTimeOffComplianceEnabledComponentRules,
  };

  const onBusinessUpdated = () => {
    toast(t("form.notifications.saved_successfully"));
  };

  const onBusinessCreated = () => {
    toast(t("form.notifications.created_successfully"));
    history.push(`/stack/${stack?.id}/businesses/`);
  };

  const handleSave = (
    changes: Partial<Profile_business$data>,
    onError: (err: Error) => void,
  ) => {
    changes = omit(changes, "timeClockAppBusinessImageId", "timeClockWorkflow");

    if (businessId) {
      UpdateBusinessMutation(
        environment,
        changes,
        businessId,
        onBusinessUpdated,
        onError,
      );
    } else {
      // create business
      CreateBusinessMutation(environment, changes, onBusinessCreated, onError);
    }
  };

  // Validation rules
  const validationRules = yup.object({
    shiftDropThreshold: yup.number().min(0),
    employeeMultiRateEnabled: yup.boolean().when("shiftMultiRateEnabled", {
      is: true,
      then: yup
        .boolean()
        .required()
        .oneOf([true], (value) => {
          return t(
            "translation:form.other_field_requires_this_field_to_be_one_of",
            {
              otherField: "shiftMultiRateEnabled",
              thisField: "employeeMultiRateEnabled",
              values: value.values,
            },
          );
        }),
    }),
    timeClockAppPinConfig: yup.object({
      min: yup
        .number()
        .min(6)
        .max(yup.ref("max"))
        .label(t("property.timeClockAppPinConfig.min")),
      max: yup
        .number()
        .min(6)
        .max(8)
        .label(t("property.timeClockAppPinConfig.max")),
    }),
    meetingMinimumDuration: yup
      .number()
      .integer()
      .min(0)
      .max(86400, "Min meeting length must be less than or equal to 1440"),
    meetingMaxPerYear: yup
      .number()
      .integer()
      .min(0)
      .max(9999)
      .label(t("Max meetings per year")),
    darFields: yup
      .array()
      .of(
        yup.object({
          name: yup.string().required().label(t("property.name.value")),
          fieldCode: yup
            .string()
            .required()
            .label(t("property.fieldCode.value")),
          valueType: yup
            .string()
            .required()
            .label(t("property.valueType.value")),
        }),
      )
      .nullable(),
    darAnalyticsSyncThreshold: yup
      .number()
      .min(0)
      .label(t("property.darAnalyticsSyncThreshold")),
    swapMobileDisclaimer: yup
      .string()
      .nullable()
      .when("swapMobileDisclaimerEnabled", {
        is: true,
        then: yup
          .string()
          .required()
          .label(t("property.swapMobileDisclaimer.value")),
      }),
    dropMobileDisclaimer: yup
      .string()
      .nullable()
      .when("dropMobileDisclaimerEnabled", {
        is: true,
        then: yup
          .string()
          .required()
          .label(t("property.dropMobileDisclaimer.value")),
      }),
    businessTerms: yup.mixed().isValidJson(t("property.businessTerms.value")),
    biometricsExpirationDays: yup
      .number()
      .min(0)
      .label(t("property.biometricsExpirationDays.value")),
    biometricExpirationExemptJtcCodes: yup
      .mixed()
      .isSingleLevelJson(
        t("property.biometricExpirationExemptJtcCodes.value"),
        "string",
      ),
    webuiViewOptions: yup
      .mixed()
      .isValidJson(t("property.webuiViewOptions.value")),
    timeClockAppWorkflow: yup
      .mixed()
      .isValidJson(t("property.timeClockAppWorkflow.value")),
    reports: yup.mixed().isValidJson(t("property.reports.value")),
    mailerLocales: yup.mixed().isValidJson(t("property.mailerLocales.value")),
    rateType: yup
      .mixed()
      .isValidJson(t("property.rateType.value"))
      .test(
        "invalid_json_structure",
        t("property.rateType.invalid_structure"),
        (rates: Record<string, unknown>[] | null) => {
          return rates == null || Array.isArray(rates);
        },
      ),
    roleJobTitles: yup
      .mixed()
      .isSingleLevelJson(t("property.roleJobTitles.value"), "string"),
    warningCodeMap: yup
      .mixed()
      .isSingleLevelJson(t("property.warningCodeMap.value"), "string", false),
  });

  return (
    <div className="panel">
      <FormLayout<Profile_business$data>
        base={business ?? ({} as Profile_business$data)}
        onSave={handleSave}
        propertyList={properties as unknown as IProperty[]}
        validationRules={validationRules}
        componentRules={componentRules}
        isCreate={isCreate}
      >
        <DynamicInputGroup fields={fields} />
        <FormLayoutFooter />
      </FormLayout>
    </div>
  );
};

export function useBusinessFormFields() {
  const customObjectFields = getFieldsByInputObjectName(
    properties as unknown as IProperty[],
    "BusinessInput",
  );

  // LK-15785: Code debt item - we need to create a pattern for this in future
  // This just adds a dummy json field so that we can render the TimeClockWorkflow component in the correct position
  const indexToAddAt =
    customObjectFields.findIndex((x) => x.key === "timeClockAppPinConfig") + 1;
  customObjectFields.splice(indexToAddAt, 0, {
    key: "timeClockWorkflow",
    name: "timeClockWorkflow",
    inputObjectName: "",
    group: "Time Clock",
    label: "",
    component: "",
    // Ignore this - doesn't matter
    type: {
      kind: "SCALAR",
      name: "Boolean",
      ofType: null,
    },
    description: "",
  } as any);

  return getSettingsByGroup(customObjectFields);
}

export default createFragmentContainer(
  Profile,
  // Each key specified in this object will correspond to a prop available to the component
  {
    business: graphql`
      # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
      fragment Profile_business on Business {
        id
        timeClockAppBusinessImageId
        ### Replaceable content start
        businessName
        globalBusinessId
        shortDescription
        registrationNumber
        globalImageId
        schoolCalendarsEnabled
        chatEnabled
        inviteHeader
        inviteMessage
        inviteFooter
        accentColor1
        coBrandingEnabled
        employmentCodePreprocessor
        firstDayOfWeek
        defaultScheduleDayStartTime
        defaultScheduleDayEndTime
        storeHoursEnabled
        showEarnings
        showSystemRolesAndPermissions
        businessTerms
        webuiViewOptions
        highlightOverstaffing
        militaryTime
        durationFormat
        dateFormat
        showAvatarIcon
        showNickname
        nicknameReplacesOnlyFirstName
        firstNameCharCount
        lastNameCharCount
        firstNameSuffix
        lastNameSuffix
        idleTimeoutEnabled
        idleTimeoutLength
        rostered
        maxShiftDuration
        autoAcceptRosteredShifts
        markAsAbsentEnabled
        enablePastShiftOperation
        timeOffShiftsEnabled
        timeOffShiftNotificationsEnabled
        meetingsEnabled
        meetingMaxPerYear
        meetingMinimumDuration
        meetingTitles
        shiftCanOverlapLeaveRequest
        shiftCanOverlapUnavailability
        shiftMultiRateEnabled
        shiftCostBreakdownEnabled
        unassignedShiftDefaultRateEnabled
        crossScheduleComplianceChecksEnabled
        scheduleWarningsFilterEnabled
        sharedStoreShiftsEnabled
        shiftAutoAcceptNotificationEnabled
        breakTypeManagementEnabled
        swapDropFiltering
        swapSorting
        shiftSwapEnabled
        shiftSwapNeedsApproval
        swapMobileDisclaimerEnabled
        swapMobileDisclaimer
        shiftDropEnabled
        shiftDropThreshold
        dropMobileDisclaimerEnabled
        dropMobileDisclaimer
        clockTrackingEnabled
        clockInThreshold
        clockOutThreshold
        timekeepingReadonly
        timekeepingRolesVisible
        earlyShiftStartThreshold
        restScreenTheme
        punchSlipMode
        voluntaryLateClockOutEnabled
        timeClockAppFetchScheduleJobTitles
        timeClockAppSyncIntervalInSeconds
        approvalNeededForRoleRateChange
        timeClockRoleRateChangeAuthThreshold
        allowManagerToApproveOwnPunch
        notificationsOnTimeClockAppEnabled
        punchTrackingEnabled
        timeClockAppPinConfig {
          min
          max
        }
        biometricsExpirationDays
        biometricExpirationExemptJtcCodes
        timeClockAppWorkflowConfig
        timekeepingPrepopulateNewEntry
        timekeepingDefaultToWeekView
        punchAdjustmentConsentEnabled
        punchEditForceChangesLegalDisclaimerEnabled
        punchEditLegalDisclaimer
        punchEditManagerCommentsEnabled
        punchEditEmployeeCommentsEnabled
        enableShiftDifferentials
        enableMiscPay
        payPeriodReviewEnabled
        payPeriodSelfApprovalEnabled
        payPeriodDailyReportSplitNames
        payFrequency
        payCycleStartDate
        payDates
        aggregateToStartDay
        defaultPayrollCutoffTime
        earningsEstimator
        payDisclaimer
        roleJobTitles
        warningCodeMap
        rateType
        employeeMultiRateEnabled
        jobTitlesEnabled
        employmentRateEditEnabled
        approvalRequests {
          employmentRateChange {
            autoApprovalEnabled
          }
        }
        employmentRateEditPast
        deletePrimaryJobTitlesEnabled
        analyticsEnabled
        analyticsServerUrl
        defaultScheduleSnapshotStartDate
        defaultScheduleSnapshotDuration
        dataLakeGlobalId
        dataLakeId
        quarterlyAggregationEnabled
        topLevelForecastMeasurements {
          name
          label
          isPrimary
          isCurrency
        }
        forecastRequiresApproval
        canAdjustForecast
        canOverrideForecast
        populateScheduleReplanningEnabled
        targetsScreenEnabled
        reportSettings
        reportDisclaimerEnabled
        reportDisclaimerText
        showEarningsToUser
        showShiftRolesToUser
        showColleaguesToUser
        showBreaksToUser
        anonymousShiftSwapToUser
        anonymousShiftDropToUser
        markAsAbsentEnabledToUser
        managerAppEnabled
        liveTimeClockViewEnabled
        darEnabled
        darFields {
          name
          fieldCode
          valueType
          description
        }
        darDataOnlyPastSlicesEnabled
        darAnalyticsSyncThreshold
        currencyType
        localeSuffix
        mailerLocales
        regularBusinessInviteEnabled
        managerInviteUsersEnabled
        managerAssignUsersToSchedulesEnabled
        timeoutThreshold
        roundingStrategy
        inviteUserConfirmationText
        terminateUserConfirmationText
        assignEmployeeConfirmationText
        unassignEmployeeConfirmationText
        monthlyCostLoadingEnabled
        graphqlQueryBatchingEnabled
        ### Replaceable content finish
      }
    `,
  },
);
