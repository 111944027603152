import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLazyLoadQuery } from "react-relay";
import { formatDate } from "../../../../utils/utility";
import { GetAllBusinessMetadataTypesQuery } from "./MetadataTypesQueries";
import { MetadataTypesQueries_MetaDataTypesQuery } from "./__generated__/MetadataTypesQueries_MetaDataTypesQuery.graphql";
import { MetadataType } from "../../../../data/generated/stack_internal_schema";
import { useDynamicFieldsLayout } from "../MetadataLayout/MetadataLayoutQueries";
import { MetadataUtility } from "../../Employment/EmploymentMetadata/MetadataUtility";
import { useAppRouter } from "../../../../hooks/useAppRouter";

const StyledThead = styled.thead`
  tr:first-child {
    th {
      &:not(.display-header) {
        border-bottom: none;
      }
    }
  }
`;

const StyledTbody = styled.tbody`
  tr {
    cursor: pointer;
  }
  .webui-visible-error {
    color: ${(props) => props.theme.error};
  }
`;

type Props = {
  search: string;
  businessId: string;
};

export default function MetadataTypesTable({ search, businessId }: Props) {
  const router = useAppRouter();
  const { t } = useTranslation("metadata-types");

  const metadataTypes = useMetadataTypesTableData(search, businessId);
  const { checkMetadataTypeInGroup } = useDynamicFieldsLayout()[1];

  const getObfuscationDescription = (metadataType: MetadataType) => {
    if (!metadataType || metadataType?.obfuscatedNumber == null) {
      return "-";
    }
    if (metadataType.obfuscatedNumber === 0) {
      return t("table.labels.all");
    }
    return metadataType.obfuscatedNumber;
  };

  const getVisibleInWebUi = (metadataType: MetadataType) => {
    // LK-9908: NON internal fields should only be Visible in Webui
    const isVisible =
      !metadataType.internalAccess && checkMetadataTypeInGroup(metadataType);
    return (
      <p
        className={
          !isVisible &&
          MetadataUtility.metadataTypeShouldBeVisible(metadataType)
            ? "webui-visible-error"
            : ""
        }
      >
        {t(isVisible ? "translation:generic.yes" : "translation:generic.no")}
      </p>
    );
  };

  const onTableRowClicked = (metadataTypeId: string) => {
    router.push(`${router.pathname}/edit/${metadataTypeId}`);
  };

  return (
    <Table responsive hover size="sm">
      <StyledThead>
        <tr>
          <th>{t("table.headers.name")}</th>
          <th>{t("table.headers.displayName")}</th>
          <th>{t("table.headers.parentObject")}</th>
          <th>{t("table.headers.dataType")}</th>
          <th>{t("table.headers.timeboxed")}</th>
          <th className="display-header" colSpan={2}>
            {t("table.headers.display.display")}
          </th>
          <th>{t("table.headers.encrypted")}</th>
          <th>{t("table.headers.obfuscated")}</th>
          <th>{t("table.headers.visibleWebUi")}</th>
          <th>{t("table.headers.lastUpdated")}</th>
        </tr>
        <tr>
          <th colSpan={5}>&nbsp;</th>
          <th>{t("table.headers.display.readonly")}</th>
          <th>{t("table.headers.display.internal")}</th>
          <th colSpan={4}>&nbsp;</th>
        </tr>
      </StyledThead>
      <StyledTbody>
        {metadataTypes.map((metadataType: MetadataType) => (
          <tr
            onClick={() => onTableRowClicked(metadataType.id)}
            key={metadataType.id}
          >
            <td>{metadataType.name}</td>
            <td>{metadataType.displayName ?? "-"}</td>
            <td>{metadataType.objectClass}</td>
            <td>{metadataType.dataType}</td>
            <td>
              <CheckIcon checked={metadataType.isTimeboxed} />
            </td>
            <td>
              <CheckIcon checked={metadataType.external} />
            </td>
            <td>
              <CheckIcon checked={metadataType.internalAccess} />
            </td>
            <td>
              <CheckIcon checked={metadataType.encrypted} />
            </td>
            <td>{getObfuscationDescription(metadataType)}</td>
            <td>{getVisibleInWebUi(metadataType)}</td>
            <td>
              {formatDate(metadataType.updatedAt, {
                toFormat: "dd MMM yyyy",
                showRelative: true,
              })}
            </td>
          </tr>
        ))}
      </StyledTbody>
    </Table>
  );
}

function useMetadataTypesTableData(search: string, businessId: string) {
  const data = useLazyLoadQuery<MetadataTypesQueries_MetaDataTypesQuery>(
    GetAllBusinessMetadataTypesQuery,
    {
      businessId,
      search,
    },
    {
      fetchPolicy: "network-only",
    },
  );

  return data.metadataTypes.nodes as MetadataType[];
}

function CheckIcon({ checked }: { checked: boolean }) {
  const theme = useTheme();
  return checked ? (
    <FontAwesomeIcon color={theme.bluePrimary} icon={faCheck} />
  ) : (
    <FontAwesomeIcon color={theme.grey300} icon={faTimes} />
  );
}
