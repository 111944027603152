import React from "react";
import { Environment, usePreloadedQuery } from "react-relay";
import * as yup from "yup";
import { FormikContext, FormikContextType } from "formik";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ComponentRule, IProperty } from "../../../../common/Form/models";
import FormLayout from "../../../../common/Form/FormLayout";
import properties from "../../../../../data/csv-settings/schedule-aos-advanced-settings.json";
import UpdateScheduleAOSAdvancedMutation from "../../../mutations/UpdateScheduleAOSAdvancedMutation";
import {
  ServerValidationConfig,
  parseSchemaProperties,
} from "../../../../common/Form/formUtilities";
import DurationInput from "../../../../common/Form/DurationInput";
import AOSFooter from "../AOSFooter";
import AdvancedFields from "./AdvancedFields";
import { AdvancedProfileQuery } from "../AosQueries";
import { AosQueries_AdvancedProfile_Query } from "../__generated__/AosQueries_AdvancedProfile_Query.graphql";
import { useBusinessContext } from "../../../../../contexts/BusinessContext";
import { AosQueries_AdvancedProfile_Fragment$data } from "../__generated__/AosQueries_AdvancedProfile_Fragment.graphql";

type AdvancedAOSSettings = AosQueries_AdvancedProfile_Fragment$data;

interface MatchParams {
  business_id: string;
  stack_id: string;
  schedule_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  environment: Environment;
  queryReference: any;
};

export default function Advanced(props: Props) {
  const { t } = useTranslation(["translation", "aos"]);
  const { business } = useBusinessContext();

  const { schedules } = usePreloadedQuery<AosQueries_AdvancedProfile_Query>(
    AdvancedProfileQuery,
    props.queryReference,
  );
  const schedule = schedules.edges[0]?.node ?? null;

  const { validationRules, defaults } = useAosConfigSchema(
    schedule?.aosConfigSchema as ServerValidationConfig,
  );

  // Add a new event handler that fires off the mutation
  const handleSave = (
    changes: Partial<AdvancedAOSSettings>,
    onError: (err: Error) => void,
  ) => {
    if (!schedule) {
      return;
    }

    UpdateScheduleAOSAdvancedMutation(
      props.environment,
      changes,
      schedule.id,
      props.match.params.business_id,
      () => {
        toast(t("form.notifications.saved_successfully"));
      },
      onError,
    );
  };

  // Custom component rules
  const componentRules: Record<string, ComponentRule> = {
    "aosConfig.aosTimeout": {
      component: DurationInput,
      componentProps: {
        from: "seconds",
        to: "minute",
        postfix: t("translation:form.minute_plural"),
      },
    },
    "aosConfig.replanningTimeout": {
      component: DurationInput,
      componentProps: {
        from: "seconds",
        to: "minute",
        postfix: t("translation:form.minute_plural"),
      },
    },
  };

  if (!business || schedule == null) {
    return null;
  }

  return (
    <FormLayout<AdvancedAOSSettings>
      base={schedule as AdvancedAOSSettings}
      onSave={handleSave}
      propertyList={properties as unknown as IProperty[]}
      validationRules={validationRules}
      componentRules={componentRules}
    >
      <FormikContext.Consumer>
        {(formikContext: FormikContextType<AdvancedAOSSettings>) => {
          const { values } = formikContext;

          return (
            <>
              <AdvancedFields
                values={values}
                properties={properties as unknown as IProperty[]}
                disabled={
                  !values.populateScheduleEnabled ||
                  !schedule.analyticsEnabled ||
                  !business.analyticsEnabled
                }
              />
              <AOSFooter
                onReset={() => {
                  const { aosConfig } = values;
                  const newAosConfig = { ...aosConfig, ...defaults };
                  formikContext.setValues({
                    ...values,
                    ...{
                      aosConfig: newAosConfig,
                    },
                  } as AdvancedAOSSettings);
                }}
              />
            </>
          );
        }}
      </FormikContext.Consumer>
    </FormLayout>
  );
}

function useAosConfigSchema(aosConfigSchema: ServerValidationConfig) {
  const { t } = useTranslation();

  // base rules
  const aosConfigRules = {};

  const { properties: aosConfigSchemaProperties } = aosConfigSchema;

  const pageProperties = new Set<string>(
    (properties as unknown as IProperty[]).map((p) => p.name),
  );

  const { defaults, schemaObject } = parseSchemaProperties(
    t,
    aosConfigSchemaProperties as Record<string, ServerValidationConfig>,
    pageProperties,
  );

  const validationRules = yup.object({
    aosConfig: yup.object({ ...schemaObject, ...aosConfigRules }),
  });

  return {
    validationRules,
    defaults,
  };
}
