import React, {
  FunctionComponent,
  PropsWithChildren,
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import has from "lodash/has";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import intersection from "lodash/intersection";
import union from "lodash/union";
import without from "lodash/without";
import pick from "lodash/pick";
import startCase from "lodash/startCase";
import toString from "lodash/toString";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { withTranslation, WithTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import styled from "styled-components";
import IndeterminateCheckbox, {
  IndeterminateCheckboxState,
} from "../../../common/IndeterminateCheckbox";
import { useBusinessContext } from "../../../../contexts/BusinessContext";

import { Id } from "../../../../data/models/common";

import allAOSSettings from "../../../../data/csv-settings/aos-config-template-create-settings.json";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../../common/Form/formUtilities";
import {
  GroupName,
  IProperty,
  TodoSelectValueType,
  SubGroupName,
} from "../../../common/Form/models";
import AccordionToggle from "../../../common/AccordionToggle";
import { convertDuration } from "../../../../utils/utility";
import AOSTemplateService, {
  AOSConfigTemplate,
} from "../../Services/AOSTemplateService";
import Loader from "../../../common/Loader";

type AOSConfig = AOSConfigTemplate["aosConfig"];

const StyledMasterCheckboxContainer = styled.div`
  margin-left: 29px;
`;

const StyledGroupCheckboxContainer = styled.div`
  margin-left: 19px;
`;

type SettingsPreview = {
  label?: string;
  key: string;
  canShowPreview: boolean;
  currentValue: any;
  newValue: any;
};

type WithAosConfig = {
  aosConfig: Partial<AOSConfig>;
};

type OkCancelModalProps = WithTranslation & {
  onOk: (templateAosConfigToOverwrite: WithAosConfig) => void;
  businessId?: Id;
  currentSettings: WithAosConfig;
  hideModal: () => void;
};

interface TemplateState {
  selectedTemplate?: AOSConfigTemplate;
  selectedSettings: string[];
  allModifiedSettings: string[];
  templatePreview?: Map<GroupName, SettingsPreview[]>;
  error?: string;
}

enum TemplateActionTypeEnum {
  Reset,
  SetState,
  SetSelectedSettings,
  SetError,
}

interface TemplateAction {
  type: TemplateActionTypeEnum;
  newState?: TemplateState;
  selectedSettings?: string[];
  error?: string;
}

const getIndeterminateCheckboxState = (items: any[], allItems: any[]) => {
  if (items.length === 0) {
    return IndeterminateCheckboxState.UNCHECKED;
  }
  if (items.length < allItems.length) {
    return IndeterminateCheckboxState.INDETERMINATE;
  }

  return IndeterminateCheckboxState.CHECKED;
};

const ApplyTemplateModal: FunctionComponent<
  PropsWithChildren<OkCancelModalProps>
> = ({ onOk, businessId, currentSettings, t, hideModal }) => {
  const { environment } = useBusinessContext();

  const [settingsDefinitions, setSettingsDefinitions] = useState<
    Map<GroupName, Map<SubGroupName, IProperty[]>>
  >(new Map<GroupName, Map<SubGroupName, IProperty[]>>());

  const [defaultTemplate, setDefaultTemplate] = useState<
    AOSConfigTemplate | undefined
  >();

  const [loaded, setLoaded] = useState<boolean>(false);

  const initialState: TemplateState = {
    selectedTemplate: defaultTemplate,
    selectedSettings: [],
    allModifiedSettings: [],
  };

  const reducer = (state: TemplateState, action: TemplateAction) => {
    switch (action.type) {
      case TemplateActionTypeEnum.Reset:
        return initialState;
      case TemplateActionTypeEnum.SetState:
        return action?.newState || initialState;
      case TemplateActionTypeEnum.SetSelectedSettings: {
        return {
          ...state,
          selectedSettings: action.selectedSettings,
          error: undefined,
        } as TemplateState;
      }
      case TemplateActionTypeEnum.SetError: {
        return {
          ...state,
          error: action.error,
        } as TemplateState;
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer<
    Reducer<TemplateState, TemplateAction>,
    TemplateState
  >(reducer, initialState, () => initialState);

  const {
    templatePreview,
    selectedTemplate,
    selectedSettings,
    allModifiedSettings,
    error,
  } = state;

  const getTemplates = async (search: string) => {
    if (!environment || !businessId) {
      return [];
    }

    const templates = await AOSTemplateService.getTemplates(
      environment,
      businessId,
      {
        search,
        isDefault: false,
      },
    );

    if (!defaultTemplate) {
      return templates;
    }

    if (search) {
      const matchIndex = (defaultTemplate.templateName || "")
        .toLocaleUpperCase()
        .indexOf(search.toLocaleUpperCase());
      if (matchIndex < 0) {
        return templates;
      }
    }

    // return templates with default template as first option
    return [defaultTemplate, ...templates];
  };

  const setSelectedTemplate = useCallback(
    (newTemplate?: AOSConfigTemplate) => {
      if (!newTemplate) {
        dispatch({ type: TemplateActionTypeEnum.Reset });
        return;
      }

      const preview = new Map<GroupName, SettingsPreview[]>();
      const modifiedSettingNames: string[] = [];
      Array.from(settingsDefinitions.keys()).forEach((group) => {
        const settingPreviewItems = Array.from(
          settingsDefinitions.get(group)?.keys() ?? [],
        ).reduce((r: SettingsPreview[], subGroup) => {
          const settings = settingsDefinitions.get(group)?.get(subGroup) ?? [];
          settings.forEach((setting) => {
            const { key } = setting;
            if (!has(currentSettings, key)) {
              return;
            }

            const currentValue = get(currentSettings, key);
            const newValue = get(newTemplate, key);

            // deep comparison
            if (isEqual(currentValue, newValue)) {
              return;
            }

            const formatValue = (
              value: string | number | null | undefined,
            ): string => {
              switch (setting.component) {
                case "SecondsToMinutesDuration": {
                  return value != null
                    ? `${convertDuration(value, "seconds", "minutes")} mins`
                    : "";
                }
                case "MinuteToHourDuration": {
                  return value != null
                    ? `${convertDuration(value, "minutes", "hours")} hrs`
                    : "";
                }
                case "MinuteDuration": {
                  return value != null ? `${value} mins` : "";
                }
                default:
                  return toString(value);
              }
            };

            r.push({
              label: setting.label || startCase(setting.name),
              key: setting.key,
              canShowPreview:
                // only show preview if primitive type
                setting.type.name !== "JSON" && setting.type.name != null,
              currentValue: formatValue(currentValue),
              newValue: formatValue(newValue),
            });

            modifiedSettingNames.push(setting.key);
          });

          return r;
        }, []);

        if (settingPreviewItems.length) {
          preview.set(group, settingPreviewItems);
        }
      });

      dispatch({
        type: TemplateActionTypeEnum.SetState,
        newState: {
          selectedTemplate: newTemplate as AOSConfigTemplate,
          templatePreview: preview,
          allModifiedSettings: modifiedSettingNames,
          selectedSettings: modifiedSettingNames,
        },
      });
    },
    [currentSettings, settingsDefinitions],
  );

  const close = () => {
    // reset form to initial state
    setSelectedTemplate(defaultTemplate);
    hideModal();
  };

  useEffect(() => {
    // load once
    setSettingsDefinitions(
      getSettingsByGroup(
        getFieldsByInputObjectName(
          allAOSSettings as unknown as IProperty[],
          "AosConfigInput",
        ),
      ),
    );
  }, []);

  useEffect(() => {
    async function loadDefaultTemplate() {
      if (!environment || !businessId) {
        return;
      }

      const result = await AOSTemplateService.getTemplates(
        environment,
        businessId,
        {
          isDefault: true,
        },
      );

      const defaultAOSTemplate = result?.[0] ?? null;
      setDefaultTemplate(defaultAOSTemplate);
      setSelectedTemplate(defaultAOSTemplate);
      setLoaded(true);
    }

    loadDefaultTemplate();
  }, [businessId, environment, setSelectedTemplate]);

  const masterCheckboxState = getIndeterminateCheckboxState(
    selectedSettings,
    allModifiedSettings,
  );

  const validate = () => {
    if (!selectedTemplate) {
      dispatch({
        type: TemplateActionTypeEnum.SetError,
        error: "Template is required",
      });

      return false;
    }

    if (!selectedSettings.length) {
      dispatch({
        type: TemplateActionTypeEnum.SetError,
        error: "One or more setting must be selected",
      });

      return false;
    }

    dispatch({
      type: TemplateActionTypeEnum.SetError,
      error: undefined,
    });
    return true;
  };

  const onApplyTemplateClicked = () => {
    if (validate()) {
      onOk(pick(selectedTemplate, selectedSettings) as WithAosConfig);
      setSelectedTemplate(defaultTemplate);
    }
  };

  const getPreview = () => {
    if (!templatePreview) {
      return null;
    }

    if (allModifiedSettings.length === 0) {
      return (
        <Form.Label>
          {t("applyTemplateDialog.noModifiedSettingsLabel")}
        </Form.Label>
      );
    }

    return (
      <>
        <Form.Label>{t("applyTemplateDialog.selectSettingsLabel")}</Form.Label>
        <StyledMasterCheckboxContainer className="mb-1">
          <IndeterminateCheckbox
            value={masterCheckboxState}
            label={t("applyTemplateDialog.selectAll")}
            onStateChange={(value: IndeterminateCheckboxState) => {
              switch (value) {
                case IndeterminateCheckboxState.CHECKED:
                  return dispatch({
                    type: TemplateActionTypeEnum.SetSelectedSettings,
                    selectedSettings: allModifiedSettings,
                  });
                case IndeterminateCheckboxState.UNCHECKED:
                  return dispatch({
                    type: TemplateActionTypeEnum.SetSelectedSettings,
                    selectedSettings: [],
                  });
                default:
                  return null;
              }
            }}
          />
        </StyledMasterCheckboxContainer>

        <Accordion defaultActiveKey="0">
          {Array.from(templatePreview.keys()).map((group, groupIndex) => {
            const previewItems = templatePreview.get(group) || [];
            const previewItemKeys = previewItems.map((i) => i.key);
            const selectedPreviewItems = intersection(
              selectedSettings,
              previewItemKeys,
            );

            const checkboxState = getIndeterminateCheckboxState(
              selectedPreviewItems,
              previewItemKeys,
            );

            return (
              // eslint-disable-next-line react/no-array-index-key
              <Card key={`${group}-${groupIndex}`}>
                <Card.Header className="p-1">
                  <AccordionToggle eventKey={`${groupIndex}`} />

                  <IndeterminateCheckbox
                    value={checkboxState}
                    label={`${group} (${previewItems.length})`}
                    onStateChange={(value: IndeterminateCheckboxState) => {
                      switch (value) {
                        case IndeterminateCheckboxState.CHECKED:
                          return dispatch({
                            type: TemplateActionTypeEnum.SetSelectedSettings,
                            selectedSettings: union(
                              selectedSettings,
                              previewItemKeys,
                            ),
                          });
                        case IndeterminateCheckboxState.UNCHECKED:
                          return dispatch({
                            type: TemplateActionTypeEnum.SetSelectedSettings,
                            selectedSettings: without(
                              selectedSettings,
                              ...previewItemKeys,
                            ),
                          });
                        default:
                          return null;
                      }
                    }}
                  />
                </Card.Header>

                <Accordion.Collapse eventKey={`${groupIndex}`}>
                  <Card.Body className="p-1">
                    <Table hover size="sm" className="mb-1">
                      <thead>
                        <tr>
                          <th className="w-50">&nbsp;</th>
                          <th className="w-25">
                            {t("applyTemplateDialog.preview.currentValue")}
                          </th>
                          <th className="w-25">
                            {t("applyTemplateDialog.preview.templateValue")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {previewItems.map((previewItem) => {
                          const {
                            newValue,
                            currentValue,
                            canShowPreview,
                            label,
                            key,
                          } = previewItem;

                          const selected =
                            selectedSettings.find((i) => i === key) != null;
                          return (
                            <tr className="visible" key={key}>
                              <td>
                                <StyledGroupCheckboxContainer>
                                  <Form.Check
                                    type="checkbox"
                                    label={label}
                                    checked={selected}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      const { currentTarget } = e;
                                      const { checked } = currentTarget;
                                      return dispatch({
                                        type: TemplateActionTypeEnum.SetSelectedSettings,
                                        selectedSettings: checked
                                          ? union(selectedSettings, [key])
                                          : without(selectedSettings, key),
                                      });
                                    }}
                                  />
                                </StyledGroupCheckboxContainer>
                              </td>
                              <td colSpan={canShowPreview ? 1 : 2}>
                                {canShowPreview
                                  ? currentValue
                                  : t(
                                      "applyTemplateDialog.preview.previewNotAvailable",
                                    )}
                              </td>
                              {canShowPreview ? <td>{newValue}</td> : null}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
        <Form.Text>{t("applyTemplateDialog.preview.reviewChanges")}</Form.Text>
      </>
    );
  };

  return (
    <Modal onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{t("applyTemplateDialog.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loaded ? (
          <>
            <Form.Group>
              <Form.Label>{t("applyTemplateDialog.templateLabel")}</Form.Label>
              <AsyncSelect<AOSConfigTemplate>
                autoFocus
                cacheOptions
                defaultOptions
                isSearchable
                formatOptionLabel={(i) => (
                  <>
                    <span>{i.templateName}</span>
                    {i.isDefault ? (
                      <Badge variant="secondary" className="ml-2">
                        {t("aosTemplate.default")}
                      </Badge>
                    ) : null}
                  </>
                )}
                getOptionValue={(i) => i.id}
                loadOptions={getTemplates}
                backspaceRemovesValue
                value={selectedTemplate}
                placeholder={t("applyTemplateDialog.searchPlaceholder")}
                onChange={(
                  newTemplate: TodoSelectValueType<AOSConfigTemplate, false>,
                ) => {
                  setSelectedTemplate(newTemplate as AOSConfigTemplate);
                }}
              />
            </Form.Group>

            {getPreview()}

            {error ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                {error}
              </Form.Control.Feedback>
            ) : null}
          </>
        ) : (
          <Loader />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          {t("applyTemplateDialog.cancel")}
        </Button>
        <Button variant="primary" onClick={onApplyTemplateClicked}>
          {t("applyTemplateDialog.apply")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withTranslation("aos")(ApplyTemplateModal);
