import React, { useState } from "react";
import isFunction from "lodash/isFunction";
import { useFormikContext } from "formik";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import OkCancelModal from "../../common/OkCancelModal";
import DeleteAosConfigTemplateMutation from "../mutations/DeleteAosConfigTemplateMutation";
import { Id } from "../../../data/models/common";
import { getHandleServerValidationErrorFn } from "../../../utils/utility";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import UpdateAosConfigTemplateMutation from "../mutations/UpdateAosConfigTemplateMutation";
import ServerError from "../../../utils/server-error";
import { useModal } from "../../../contexts/ModalContext";

export enum AOSModalEnum {
  ComfirmDelete,
  SetAsDefault,
  UnsetAsDefault,
}

type AOSTemplate = {
  templateName: string;
  id: Id;
  isDefault: boolean;
};

type Props = {
  modal: AOSModalEnum | null;
  businessId: Id;
  onDeletedCallback: () => any;
  onToggleDefaultCallback?: () => any;
  template: AOSTemplate | null;
};

/** @deprecated TODO - split this up into separate modals */
const AOSTemplateModal: React.FunctionComponent<Props> = ({
  modal,
  template,
  businessId,
  onDeletedCallback,
  onToggleDefaultCallback: onSetAsDefaultCallback,
}) => {
  const { t } = useTranslation("aos");
  const formikContext = useFormikContext();
  const businessContext = useBusinessContext();
  const modalContext = useModal();

  const [error, setError] = useState<string>();
  if (!template) {
    return null;
  }

  const { isDefault, templateName, id: templateId } = template;

  const handleError = (e: Error) => {
    const { source } = (e as any) || {};
    if (!source) {
      return;
    }
    const serverError = new ServerError(source);
    setError(serverError.getErrorDetails());
  };

  const handleDelete = () => {
    if (!businessContext.environment) {
      return;
    }
    DeleteAosConfigTemplateMutation(
      businessContext.environment,
      businessId,
      templateId,
      () => {
        modalContext.hideModal();
        onDeletedCallback();
      },
      formikContext
        ? getHandleServerValidationErrorFn(formikContext)
        : handleError,
    );
  };

  const handleToggleDefault = () => {
    if (!businessContext.environment) {
      return;
    }
    UpdateAosConfigTemplateMutation(
      businessContext.environment,
      businessId,
      templateId,
      {
        isDefault: !isDefault,
      },
      () => {
        modalContext.hideModal();
        if (isFunction(onSetAsDefaultCallback)) {
          onSetAsDefaultCallback();
        }
      },
      formikContext
        ? getHandleServerValidationErrorFn(formikContext)
        : handleError,
    );
  };

  const renderError = () => {
    return error ? (
      <Form.Control.Feedback type="invalid" className="d-block">
        {error}
      </Form.Control.Feedback>
    ) : null;
  };

  switch (modal) {
    case AOSModalEnum.ComfirmDelete:
      return (
        <OkCancelModal
          title={t("aos:aosTemplate.deleteConfirmation.title")}
          onOk={handleDelete}
        >
          <p>
            {t("aos:aosTemplate.deleteConfirmation.body", {
              name: templateName,
            })}
            {renderError()}
          </p>
        </OkCancelModal>
      );
    case AOSModalEnum.SetAsDefault:
      return (
        <OkCancelModal
          title={
            isDefault
              ? t("aos:aosTemplate.removeDefaultConfirmation.title")
              : t("aos:aosTemplate.setAsDefaultConfirmation.title")
          }
          onOk={handleToggleDefault}
        >
          <p>
            {isDefault
              ? t("aos:aosTemplate.removeDefaultConfirmation.body", {
                  name: templateName,
                })
              : t("aos:aosTemplate.setAsDefaultConfirmation.body", {
                  name: templateName,
                })}
            {renderError()}
          </p>
        </OkCancelModal>
      );

    default:
      return null;
  }
};

export default AOSTemplateModal;
