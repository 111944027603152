import { FormikHelpers } from "formik";
import React, { useEffect } from "react";
import { Card, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Loader from "../../../../common/Loader";
import FormLayout from "../../../../common/Form/FormLayout";
import FormLayoutFooter from "../../../../common/Form/FormLayoutFooter";
import { useAppRouter } from "../../../../../hooks/useAppRouter";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../../common/Portal/HeaderPortal";
import {
  GetKpiScreenConfigs_Node,
  useKpiScreenConfig,
} from "../KpiComponentsQueries";
import { ScreenCodeEnum } from "../__generated__/KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery.graphql";
import { KpiMetricsTable } from "./KpiMetricsTable";

type MatchParams = {
  business_id: string;
  screen_code: ScreenCodeEnum;
};

export default function KpiComponentsProfile() {
  const { t } = useTranslation("kpi-components");
  const router = useAppRouter<MatchParams>();

  const validationRules = yup.object({
    kpiDisplayConfig: yup.array().of(
      yup.object().shape({
        displayLabel: yup
          .string()
          .required()
          .max(20)
          .label(t("profile.table.headers.displayLabel")),
      }),
    ),
  });

  const [kpiScreen, updateKpiScreenMutation] = useKpiScreenConfig({
    businessId: router.params.business_id,
    screenCode: router.params.screen_code as any,
  });

  const onSave = (
    data: Partial<GetKpiScreenConfigs_Node>,
    errorHandler: (error: any) => void,
    event: any,
    values: GetKpiScreenConfigs_Node | undefined,
    helpers?: FormikHelpers<GetKpiScreenConfigs_Node>,
  ) => {
    if (values) {
      updateKpiScreenMutation({
        data: values,
        onCompleted: () => {
          toast(t("translation:form.notifications.saved_successfully"));
          router.replace(router.getGoBackUrl("/kpi_components"));
        },
        onError: errorHandler,
      });
    }

    helpers?.setSubmitting(false);
  };

  useEffect(() => {
    // Ensure that the screen doesn't break if the kpi screen config is somehow invalid
    if (kpiScreen == null) {
      router.replace(router.getGoBackUrl("/kpi_components"));
    }
  }, [kpiScreen, router]);

  const screenName = kpiScreen?.screenCode
    ? kpiScreen.screenCode.charAt(0).toUpperCase() +
      kpiScreen.screenCode.slice(1)
    : "";

  return (
    <>
      <HeaderPortal as="span">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <Link to={router.getGoBackUrl("/kpi_components")}>
              {t("businesses:layout.nav.kpiComponents")}
            </Link>,
            <span>{t(`screen.${router.params.screen_code}`)}</span>,
          ]}
        />
      </HeaderPortal>

      <Card body style={{ minHeight: 400 }}>
        <React.Suspense fallback={<Loader />}>
          {kpiScreen && (
            <FormLayout<GetKpiScreenConfigs_Node>
              isCreate={false}
              base={kpiScreen}
              onSave={onSave}
              validationRules={validationRules}
            >
              <Col md={3} className="ml-2">
                <Form.Group controlId="screenCode">
                  <Form.Label>{t("profile.fields.screen")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="screenCode"
                    value={screenName}
                    disabled
                  />
                </Form.Group>
              </Col>

              <KpiMetricsTable />

              <FormLayoutFooter />
            </FormLayout>
          )}
        </React.Suspense>
      </Card>
    </>
  );
}
