import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import AppContext, {
  AppContextValue,
} from "../../IDM/external/Context/AppContext";
import { IStack, Id } from "../../../data/models/common";
import { useAppRouter } from "../../../hooks/useAppRouter";

export default function StackSelector() {
  const { history } = useAppRouter();

  const getStackDropdown = (
    appContext: AppContextValue,
    dropdownProps?: Map<Id, IStack>,
  ) => {
    return (Array.from(dropdownProps?.values() ?? []) || []).map(
      (s: IStack) => (
        <NavDropdown.Item
          key={s.id}
          eventKey={s.id}
          onSelect={() => {
            appContext.setStackId(s.id);
            history.push(`/stack/${s.id}/businesses`);
          }}
        >
          {s.domainName}
        </NavDropdown.Item>
      ),
    );
  };

  return (
    <AppContext.Consumer>
      {(appContext: AppContextValue) => (
        <NavDropdown
          className="d-inline-block"
          title={appContext.stack ? appContext.stack.domainName : "All Stacks"}
          id="basic-nav-dropdown"
        >
          {getStackDropdown(appContext, appContext.myStacksById)}
        </NavDropdown>
      )}
    </AppContext.Consumer>
  );
}
