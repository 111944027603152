import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import Field from "../../common/Form/Field";
import { BooleanFieldType } from "../../common/Form/models";
import {
  BusinessEmploymentTerminationReason_StatusChangeReasons_Query,
  CurrentEmploymentStatusEnum,
} from "./__generated__/BusinessEmploymentTerminationReason_StatusChangeReasons_Query.graphql";

type Props = {
  fieldKey: string;
  label: string;
};

function BusinessEmploymentTerminationReason({ fieldKey, label }: Props) {
  const { business } = useBusinessContext();
  const { t } = useTranslation("businesses");

  const data =
    useLazyLoadQuery<BusinessEmploymentTerminationReason_StatusChangeReasons_Query>(
      graphql`
        query BusinessEmploymentTerminationReason_StatusChangeReasons_Query(
          $businessId: ID!
          $status: CurrentEmploymentStatusEnum!
          $skip: Boolean!
        ) {
          employmentStatusChangeReasons(
            businessId: $businessId
            deleted: false
            status: [$status]
          ) @skip(if: $skip) {
            nodes {
              id
              deleted
              code
              status
              displayText
            }
          }
        }
      `,
      {
        businessId: business.id ?? "",
        status: "terminated" as CurrentEmploymentStatusEnum,
        skip: business.id == null,
      },
    );

  const terminationReasons = data?.employmentStatusChangeReasons?.nodes ?? [];
  const terminationReasonsIngested = terminationReasons.length > 0;

  return (
    <Row>
      <Field
        sm={12}
        md={12}
        lg={3}
        xl={3}
        fieldKey={fieldKey}
        label={label}
        schemaFieldType={BooleanFieldType}
        description={
          !terminationReasonsIngested
            ? t(
                "profile.mandatoryReasonsForEmploymentStatus.terminated.noneIngested",
              )
            : undefined
        }
      />
      {terminationReasonsIngested && (
        <Col sm={12} md={12} lg={6} xl={4}>
          <Table size="md">
            <thead>
              <tr>
                <th>
                  {t(
                    "profile.mandatoryReasonsForEmploymentStatus.terminated.tableHeaders.code",
                  )}
                </th>
                <th>
                  {t(
                    "profile.mandatoryReasonsForEmploymentStatus.terminated.tableHeaders.reason",
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {terminationReasons.map((terminationReason) => {
                if (terminationReason) {
                  return (
                    <tr key={terminationReason.id}>
                      <td>{terminationReason.code ?? ""}</td>
                      <td>{terminationReason.displayText}</td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </Table>
        </Col>
      )}
    </Row>
  );
}

export default BusinessEmploymentTerminationReason;
