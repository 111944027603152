/**
 * @generated SignedSource<<ffcf58f7604d3c5559c136add16fe94c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TimeClockAppPreShiftMessageTypeEnum = "configurable" | "predefined" | "%future added value";
export type ScheduleUpdateInput = {
  analyticsEnabled?: boolean | null;
  aosConfig?: AosConfigInput | null;
  approvalNeededForEarlyBreakEnd?: boolean | null;
  approvalNeededForMinorViolations?: boolean | null;
  approvalNeededForNonCompliantBreak?: boolean | null;
  approvalNeededForPinLogin?: boolean | null;
  approvalNeededForRoleRateChange?: boolean | null;
  approvalNeededForShiftChange?: boolean | null;
  approvalNeededForUnplannedShift?: boolean | null;
  autoGfeConfig?: any | null;
  autoGfeEnabled?: boolean | null;
  autoPopulateScheduleAtTime?: number | null;
  autoPopulateScheduleDaysInAdvance?: number | null;
  autoPopulateScheduleEnabled?: boolean | null;
  autoPublishConfig?: any | null;
  autoPublishEnabled?: boolean | null;
  clockInThreshold?: number | null;
  clockOutThreshold?: number | null;
  clockTrackingEnabled?: boolean | null;
  code?: string | null;
  complianceStartDate?: any | null;
  dataLakeId?: string | null;
  dayEndTime?: string | null;
  dayPartInheritedScheduleId?: string | null;
  dayPartTemplateId?: string | null;
  dayStartTime?: string | null;
  employeeMultiRateEnabled?: boolean | null;
  firstDayOfWeek?: number | null;
  hrGoLiveDate?: any | null;
  idleTimeoutEnabled?: boolean | null;
  idleTimeoutLength?: number | null;
  labourCostPercentage?: ReadonlyArray<number> | null;
  labourCostPercentageEnabled?: boolean | null;
  locale?: string | null;
  monthlyCostLoading?: number | null;
  onCostDefinitionId?: string | null;
  payCycleStartDate?: any | null;
  payDates?: ReadonlyArray<number> | null;
  payFrequency?: string | null;
  payPeriodEnabled?: boolean | null;
  payPeriodRequiresApproval?: boolean | null;
  payrollCutoffTime?: string | null;
  populateScheduleConfig?: any | null;
  populateScheduleEnabled?: boolean | null;
  populateScheduleReplanningEnabled?: boolean | null;
  punchSlipMode?: string | null;
  restScreenTheme?: string | null;
  ruleSetId?: string | null;
  scheduleName?: string | null;
  scheduleStatus?: string | null;
  settings?: any | null;
  shiftCanOverlapLeaveRequest?: boolean | null;
  shiftCanOverlapUnavailability?: boolean | null;
  shiftDropEnabled?: boolean | null;
  shiftMultiRateEnabled?: boolean | null;
  shiftShowUpThreshold?: number | null;
  shiftSwapEnabled?: boolean | null;
  shiftSwapNeedsApproval?: boolean | null;
  shiftUnpublishConsentEnabled?: boolean | null;
  shiftUnpublishConsentThreshold?: number | null;
  showEarnings?: boolean | null;
  snapshotDuration?: number | null;
  snapshotStartDate?: any | null;
  timeClockAppPreShiftFormattedMessages?: TimeClockAppPreShiftFormattedShiftMessagesInput | null;
  timeClockAppRestScreenSettings?: TimeClockAppRestScreenSettingsInput | null;
  timeClockAppWorkflow?: string | null;
  timeClockRoleRateChangeAuthThreshold?: number | null;
  timekeepingGoLiveDate?: any | null;
  unplannedShiftStartThreshold?: number | null;
  usesBiometrics?: boolean | null;
  voluntaryLateClockOutEnabled?: boolean | null;
};
export type AosConfigInput = {
  addCoreBreaks?: boolean | null;
  addUnderstaffingShifts?: boolean | null;
  aosCombinedRoles?: any | null;
  aosOrchestratorConfig?: any | null;
  aosTimeout?: number | null;
  applyRule24HoursRest?: boolean | null;
  bonusRoles?: ReadonlyArray<string> | null;
  breakEndPad?: number | null;
  breakLength?: number | null;
  breakMaxStart?: number | null;
  breakMinStart?: number | null;
  breakTrigger?: number | null;
  coreObeysAosRules?: boolean | null;
  costEqualLabor?: number | null;
  daypartRanks?: any | null;
  demandOnly?: ReadonlyArray<string> | null;
  flexibleRole?: string | null;
  ignoreBreakRoles?: ReadonlyArray<string> | null;
  includeRoles?: ReadonlyArray<string> | null;
  maxRolesPerShift?: number | null;
  maxWorkdaysCalweek?: number | null;
  maxWorkdaysWorkweek?: number | null;
  minShiftPartLength?: number | null;
  optimizeCoreBreaks?: boolean | null;
  overstaffingByRole?: ReadonlyArray<string> | null;
  overstaffingPenalty?: number | null;
  penaltyShortParts?: number | null;
  penaltyShortPartsCutoff?: number | null;
  planningOrder?: ReadonlyArray<string> | null;
  replanningOrchestratorConfig?: any | null;
  replanningTimeout?: number | null;
  schedulingDayStart?: string | null;
  shiftConfig?: any | null;
  shiftGapMin?: number | null;
  shiftLengthPreference?: number | null;
  shiftMax?: number | null;
  shiftMaxStart?: string | null;
  shiftMin?: number | null;
  shiftMinAbsolute?: number | null;
  skillPreference?: number | null;
  skipSchedulingCore?: boolean | null;
  skipSchedulingManagers?: boolean | null;
  understaffingByRole?: ReadonlyArray<string> | null;
  weeklyDayparts?: any | null;
  weeklyWorkhoursMax?: number | null;
  weeklyWorkhoursMin?: number | null;
  weightEqualLaborByRating?: ReadonlyArray<number> | null;
};
export type TimeClockAppPreShiftFormattedShiftMessagesInput = {
  configurable?: ReadonlyArray<TimeClockAppPreShiftMessageConfigurableInput> | null;
  predefined?: ReadonlyArray<string> | null;
  style?: TimeClockAppPreShiftMessageTypeEnum | null;
};
export type TimeClockAppPreShiftMessageConfigurableInput = {
  buttons: ReadonlyArray<string>;
  code: string;
  message: string;
  title: string;
  version: number;
};
export type TimeClockAppRestScreenSettingsInput = {
  breakCompletedUpperBound?: number | null;
  mealBreakApproachingLowerBound?: number | null;
  mealBreakDueUpperBound?: number | null;
  mealBreakEndApproachingLowerBound?: number | null;
  mealBreakEndDueUpperBound?: number | null;
  mealBreakOverdueUpperBound?: number | null;
  onTimeThreshold?: number | null;
  shiftApproachingLowerBound?: number | null;
  shiftDueUpperBound?: number | null;
  shiftEndApproachingLowerBound?: number | null;
  shiftEndDueUpperBound?: number | null;
  shiftLateUpperBound?: number | null;
  shiftOverdueUpperBound?: number | null;
};
export type UpdateScheduleAOSAdvancedMutation$variables = {
  businessId: string;
  id: string;
  input: ScheduleUpdateInput;
};
export type UpdateScheduleAOSAdvancedMutation$data = {
  readonly updateSchedule: {
    readonly aosConfig: {
      readonly aosCombinedRoles: any | null;
      readonly aosOrchestratorConfig: any | null;
      readonly aosTimeout: number | null;
      readonly replanningOrchestratorConfig: any | null;
      readonly replanningTimeout: number | null;
    } | null;
    readonly aosConfigSchema: any;
    readonly id: string;
    readonly populateScheduleEnabled: boolean;
    readonly populateScheduleReplanningEnabled: boolean;
  };
};
export type UpdateScheduleAOSAdvancedMutation = {
  response: UpdateScheduleAOSAdvancedMutation$data;
  variables: UpdateScheduleAOSAdvancedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Schedule",
    "kind": "LinkedField",
    "name": "updateSchedule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "populateScheduleEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "aosConfigSchema",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "populateScheduleReplanningEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AosConfig",
        "kind": "LinkedField",
        "name": "aosConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosTimeout",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosCombinedRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosOrchestratorConfig",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replanningTimeout",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replanningOrchestratorConfig",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateScheduleAOSAdvancedMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateScheduleAOSAdvancedMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9ca59189f4a900e66cb7db9ea2eb14e7",
    "id": null,
    "metadata": {},
    "name": "UpdateScheduleAOSAdvancedMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateScheduleAOSAdvancedMutation(\n  $input: ScheduleUpdateInput!\n  $id: ID!\n  $businessId: ID!\n) {\n  updateSchedule(businessId: $businessId, id: $id, input: $input) {\n    id\n    populateScheduleEnabled\n    aosConfigSchema\n    populateScheduleReplanningEnabled\n    aosConfig {\n      aosTimeout\n      aosCombinedRoles\n      aosOrchestratorConfig\n      replanningTimeout\n      replanningOrchestratorConfig\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a91e814df6b17c8802d085e468f9ef05";

export default node;
