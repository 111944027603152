import React, { useState } from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import Searchable from "../../common/Searchable";
import EmploymentTable, {
  EmploymentStatus,
  getCurrentlyEmployedParam,
  getDeletedParam,
} from "./EmploymentTable";
import DynamicSelect from "../../common/Form/DynamicSelect";
import { Employments_InternalQuery } from "./__generated__/Employments_InternalQuery.graphql";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import { useAppRouter } from "../../../hooks/useAppRouter";
import { useDebouncedValue } from "../../../hooks/useDebouncedValue";

const EmploymentsQuery = graphql`
  query Employments_InternalQuery(
    $businessId: ID!
    $searchValue: String
    $deleted: Boolean
    $acceptedInvite: Boolean
    $userLinked: Boolean
    $currentlyEmployed: Boolean
    $pageSize: Int!
    $after: String
  ) {
    ...EmploymentTable_viewer
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

const defaultFilter = {
  searchValue: "",
  employmentStatus: EmploymentStatus.Any,
  unlinked: false,
};

export default function Employments(properties: Props) {
  const { environment } = useBusinessContext();
  const { t } = useTranslation("employment");
  const {
    params: { business_id: businessId, stack_id: stackId },
  } = useAppRouter<MatchParams>();

  const [searchValue, debouncedSearchValue, setSearch] = useDebouncedValue(
    defaultFilter.searchValue,
  );
  const [employmentStatus, setEmploymentStatus] = useState(
    defaultFilter.employmentStatus,
  );
  const [unlinked, setUnlinked] = useState(defaultFilter.unlinked);

  function clearFilters() {
    setSearch(defaultFilter.searchValue, false);
    setEmploymentStatus(defaultFilter.employmentStatus);
    setUnlinked(defaultFilter.unlinked);
  }

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("breadcrumb")}</span>
      </HeaderPortal>
      <Row className="my-2">
        <Col md={3}>
          <Searchable
            className="employments"
            searchValue={searchValue}
            onSearchChange={setSearch}
            placeholder={t("table.search")}
          />
        </Col>
        <Col md="auto">
          <Form inline>
            <Form.Group>
              <Form.Label className="mr-2 ml-0">{t("table.status")}</Form.Label>
              <DynamicSelect<EmploymentStatus>
                options={[
                  {
                    label: t("table.statuses.any"),
                    value: EmploymentStatus.Any,
                  },
                  {
                    label: t("table.statuses.employed"),
                    value: EmploymentStatus.Employed,
                  },
                  {
                    label: t("table.statuses.invited"),
                    value: EmploymentStatus.Invited,
                  },
                  {
                    label: t("table.statuses.terminated"),
                    value: EmploymentStatus.Terminated,
                  },
                ]}
                value={employmentStatus}
                name="employment-status"
                onChange={(newValue: EmploymentStatus | null | undefined) => {
                  setEmploymentStatus(newValue ?? EmploymentStatus.Employed);
                }}
              />
            </Form.Group>
            <Form.Group className="border-right">
              <Form.Check
                className="mr-4 ml-4"
                type="checkbox"
                id="unlinked"
                label={t("table.unlinked")}
                name="unlinked"
                checked={unlinked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { currentTarget } = e;
                  const { checked } = currentTarget;
                  setUnlinked(checked);
                }}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Button variant="outline-primary" onClick={clearFilters}>
            {t("table.clearFilters")}
          </Button>
        </Col>
      </Row>

      <Card body>
        <QueryRenderer<Employments_InternalQuery>
          environment={environment}
          query={EmploymentsQuery}
          variables={{
            businessId,
            searchValue: debouncedSearchValue,
            acceptedInvite:
              employmentStatus === EmploymentStatus.Invited ? false : undefined,
            deleted: getDeletedParam(employmentStatus),
            userLinked: unlinked ? false : undefined,
            currentlyEmployed: getCurrentlyEmployedParam(employmentStatus),
            pageSize: 50,
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>Error!</div>;
            }
            if (!props) {
              return null;
            }
            return (
              <EmploymentTable
                {...properties}
                stackId={stackId}
                businessId={businessId}
                searchValue={debouncedSearchValue}
                employmentStatus={employmentStatus}
                unlinked={unlinked}
                viewer={props as any}
              />
            );
          }}
        />
      </Card>
    </>
  );
}
