import Col from "react-bootstrap/Col";
import React from "react";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { MetadataTimeboxedField } from "../MetadataUtility";
import { EmployeeHomeStore } from "../EmploymentMetadataQueries";
import GroupHeader from "../../../../common/Form/GroupHeader";
import {
  TimeboxedDataTable,
  TimeboxedTableRowData,
} from "./TimeboxedDataTable";
import {
  DynamicFieldsLayout,
  DynamicFieldsLayoutGroup,
  DynamicFieldsLayoutView,
} from "../../../../../data/generated/stack_internal_schema";

type Props = {
  view: DynamicFieldsLayoutView | null;
  businessDynamicFieldsLayout: DynamicFieldsLayout;
  metadataTypeFields: MetadataTimeboxedField[];
  onEdit?: (data: TimeboxedTableRowData) => void;
  onAdd?: (data: TimeboxedTableRowData) => void;
  employeeHomeStore: EmployeeHomeStore;
};

export default function DynamicTimeboxedTableGroup({
  view,
  businessDynamicFieldsLayout,
  metadataTypeFields,
  onAdd,
  onEdit,
  employeeHomeStore,
}: Props) {
  const { t } = useTranslation("employment");

  // If view is null, assume it's the 'Others' category
  if (!view) {
    return (
      <>
        <GroupHeader>{t("metadata.layout.others")}</GroupHeader>
        <Row>
          {metadataTypeFields.map((metadataMapping) => (
            <Col
              key={metadataMapping.metadataType.id}
              xs={12}
              md={12}
              lg={12}
              xl={6}
            >
              <TimeboxedDataTable
                data={{
                  employmentMetadata: metadataMapping.timeboxedData,
                  metadataType: metadataMapping.metadataType,
                  employeeHomeStore,
                }}
                onEdit={onEdit}
                onAdd={onAdd}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  }

  const groupsForView = businessDynamicFieldsLayout.groups.reduce(
    (acc, curr: DynamicFieldsLayoutGroup) => {
      const newArr = acc;
      if (view.groups.includes(curr.name)) {
        const timeboxedFields = curr.fields
          .map((groupField) => {
            return metadataTypeFields.find(
              (field) =>
                field.metadataType.name === groupField.metadataTypeName,
            ) as MetadataTimeboxedField;
          })
          .filter((x) => x != null);

        if (timeboxedFields?.length) {
          newArr.push({
            ...curr,
            fieldData: timeboxedFields,
          });
        }
      }
      return newArr;
    },
    [] as { fieldData: MetadataTimeboxedField[] }[],
  );

  if (groupsForView.length === 0) {
    return null;
  }

  return (
    <>
      <GroupHeader>{view.name}</GroupHeader>
      {groupsForView.map((group) => (
        <Row>
          {group.fieldData.map((metadataMapping: any) => (
            <Col
              key={metadataMapping.metadataType.id}
              xs={12}
              md={12}
              lg={12}
              xl={6}
            >
              <TimeboxedDataTable
                data={{
                  employmentMetadata: metadataMapping.timeboxedData,
                  metadataType: metadataMapping.metadataType,
                  employeeHomeStore,
                }}
                onEdit={onEdit}
                onAdd={onAdd}
              />
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
}
