import { cloneDeep } from "lodash";
import React from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { BaseOption } from "../../../data/models/common";

const StyledRadio = styled(Form.Check).attrs({ custom: true })`
  .custom-control-input {
    z-index: 100;
  }
  &.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    border-color: transparent;
  }
  &.custom-radio .custom-control-input:checked ~ .custom-control-label {
    font-weight: bold;
  }
  :not(&.custom-control:first-child) {
    padding-top: 5px;
  }
`;

type Props<T> = {
  options: BaseOption<T, any>[];
  value: T | T[] | null;
  fieldKey: string;
  onChange: (newValue: T | T[] | null) => void;
  disabled?: boolean;
  formCheckType: "radio" | "checkbox" | "switch";
  strictNullCheck?: boolean;
  inline?: boolean;
  isMultiSelect?: boolean;
  disabledTooltipText?: string;
};

export default function SelectionGroup<T>({
  formCheckType = "radio",
  disabled = false,
  isMultiSelect = false,
  strictNullCheck = false,
  fieldKey,
  inline,
  value,
  options,
  onChange,
  disabledTooltipText,
}: Props<T>) {
  const isOptionSelected = (option: BaseOption<T, any>, val: any) => {
    // strictNullCheck is used to treat null/undefined the same
    if (!strictNullCheck && option.value == null) {
      return val == null;
    }
    return option.value === val;
  };

  return (
    <OverlayTrigger
      defaultShow={false}
      placement="top-start"
      overlay={
        <Tooltip id={`tooltip-${fieldKey}`}>
          {disabledTooltipText ?? ""}
        </Tooltip>
      }
      trigger={undefined}
      delay={undefined}
      flip={undefined}
      onHide={undefined}
      onToggle={undefined}
      popperConfig={undefined}
      show={!disabled || !disabledTooltipText ? false : undefined}
      target={undefined}
    >
      {/* Inside element needs to be either a div/span, otherwise the tooltip won't display because */}
      <span>
        {(options || []).map((option: BaseOption<T, any>, index: number) => {
          return (
            <StyledRadio
              // eslint-disable-next-line react/no-array-index-key
              key={`${fieldKey}-${index}`}
              inline={inline}
              label={option.label}
              id={`${fieldKey}-${index}`}
              type={formCheckType}
              name={fieldKey}
              disabled={disabled}
              checked={
                !isMultiSelect
                  ? isOptionSelected(option, value)
                  : ((value as T[]) || []).find((v) =>
                      isOptionSelected(option, value),
                    ) != null
              }
              value={
                (option.value as unknown as
                  | string
                  | number
                  | readonly string[]) ?? undefined
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const isChecked = e.currentTarget.checked;
                if (isMultiSelect) {
                  const temp = cloneDeep(value || []) as T[];
                  if (isChecked) {
                    onChange(temp.concat(option.value));
                  } else {
                    onChange(temp.splice(index, 1));
                  }
                } else if (isChecked) {
                  onChange(option.value);
                } else {
                  onChange(null);
                }
              }}
            />
          );
        })}
      </span>
    </OverlayTrigger>
  );
}
