/**
 * @generated SignedSource<<335ee9b36d14836259e82d53ba689ee8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TimeClockAppPreShiftMessageTypeEnum = "configurable" | "predefined" | "%future added value";
export type ScheduleUpdateInput = {
  analyticsEnabled?: boolean | null;
  aosConfig?: AosConfigInput | null;
  approvalNeededForEarlyBreakEnd?: boolean | null;
  approvalNeededForMinorViolations?: boolean | null;
  approvalNeededForNonCompliantBreak?: boolean | null;
  approvalNeededForPinLogin?: boolean | null;
  approvalNeededForRoleRateChange?: boolean | null;
  approvalNeededForShiftChange?: boolean | null;
  approvalNeededForUnplannedShift?: boolean | null;
  autoGfeConfig?: any | null;
  autoGfeEnabled?: boolean | null;
  autoPopulateScheduleAtTime?: number | null;
  autoPopulateScheduleDaysInAdvance?: number | null;
  autoPopulateScheduleEnabled?: boolean | null;
  autoPublishConfig?: any | null;
  autoPublishEnabled?: boolean | null;
  clockInThreshold?: number | null;
  clockOutThreshold?: number | null;
  clockTrackingEnabled?: boolean | null;
  code?: string | null;
  complianceStartDate?: any | null;
  dataLakeId?: string | null;
  dayEndTime?: string | null;
  dayPartInheritedScheduleId?: string | null;
  dayPartTemplateId?: string | null;
  dayStartTime?: string | null;
  employeeMultiRateEnabled?: boolean | null;
  firstDayOfWeek?: number | null;
  hrGoLiveDate?: any | null;
  idleTimeoutEnabled?: boolean | null;
  idleTimeoutLength?: number | null;
  labourCostPercentage?: ReadonlyArray<number> | null;
  labourCostPercentageEnabled?: boolean | null;
  locale?: string | null;
  monthlyCostLoading?: number | null;
  onCostDefinitionId?: string | null;
  payCycleStartDate?: any | null;
  payDates?: ReadonlyArray<number> | null;
  payFrequency?: string | null;
  payPeriodEnabled?: boolean | null;
  payPeriodRequiresApproval?: boolean | null;
  payrollCutoffTime?: string | null;
  populateScheduleConfig?: any | null;
  populateScheduleEnabled?: boolean | null;
  populateScheduleReplanningEnabled?: boolean | null;
  punchSlipMode?: string | null;
  restScreenTheme?: string | null;
  ruleSetId?: string | null;
  scheduleName?: string | null;
  scheduleStatus?: string | null;
  settings?: any | null;
  shiftCanOverlapLeaveRequest?: boolean | null;
  shiftCanOverlapUnavailability?: boolean | null;
  shiftDropEnabled?: boolean | null;
  shiftMultiRateEnabled?: boolean | null;
  shiftShowUpThreshold?: number | null;
  shiftSwapEnabled?: boolean | null;
  shiftSwapNeedsApproval?: boolean | null;
  shiftUnpublishConsentEnabled?: boolean | null;
  shiftUnpublishConsentThreshold?: number | null;
  showEarnings?: boolean | null;
  snapshotDuration?: number | null;
  snapshotStartDate?: any | null;
  timeClockAppPreShiftFormattedMessages?: TimeClockAppPreShiftFormattedShiftMessagesInput | null;
  timeClockAppRestScreenSettings?: TimeClockAppRestScreenSettingsInput | null;
  timeClockAppWorkflow?: string | null;
  timeClockRoleRateChangeAuthThreshold?: number | null;
  timekeepingGoLiveDate?: any | null;
  unplannedShiftStartThreshold?: number | null;
  usesBiometrics?: boolean | null;
  voluntaryLateClockOutEnabled?: boolean | null;
};
export type AosConfigInput = {
  addCoreBreaks?: boolean | null;
  addUnderstaffingShifts?: boolean | null;
  aosCombinedRoles?: any | null;
  aosOrchestratorConfig?: any | null;
  aosTimeout?: number | null;
  applyRule24HoursRest?: boolean | null;
  bonusRoles?: ReadonlyArray<string> | null;
  breakEndPad?: number | null;
  breakLength?: number | null;
  breakMaxStart?: number | null;
  breakMinStart?: number | null;
  breakTrigger?: number | null;
  coreObeysAosRules?: boolean | null;
  costEqualLabor?: number | null;
  daypartRanks?: any | null;
  demandOnly?: ReadonlyArray<string> | null;
  flexibleRole?: string | null;
  ignoreBreakRoles?: ReadonlyArray<string> | null;
  includeRoles?: ReadonlyArray<string> | null;
  maxRolesPerShift?: number | null;
  maxWorkdaysCalweek?: number | null;
  maxWorkdaysWorkweek?: number | null;
  minShiftPartLength?: number | null;
  optimizeCoreBreaks?: boolean | null;
  overstaffingByRole?: ReadonlyArray<string> | null;
  overstaffingPenalty?: number | null;
  penaltyShortParts?: number | null;
  penaltyShortPartsCutoff?: number | null;
  planningOrder?: ReadonlyArray<string> | null;
  replanningOrchestratorConfig?: any | null;
  replanningTimeout?: number | null;
  schedulingDayStart?: string | null;
  shiftConfig?: any | null;
  shiftGapMin?: number | null;
  shiftLengthPreference?: number | null;
  shiftMax?: number | null;
  shiftMaxStart?: string | null;
  shiftMin?: number | null;
  shiftMinAbsolute?: number | null;
  skillPreference?: number | null;
  skipSchedulingCore?: boolean | null;
  skipSchedulingManagers?: boolean | null;
  understaffingByRole?: ReadonlyArray<string> | null;
  weeklyDayparts?: any | null;
  weeklyWorkhoursMax?: number | null;
  weeklyWorkhoursMin?: number | null;
  weightEqualLaborByRating?: ReadonlyArray<number> | null;
};
export type TimeClockAppPreShiftFormattedShiftMessagesInput = {
  configurable?: ReadonlyArray<TimeClockAppPreShiftMessageConfigurableInput> | null;
  predefined?: ReadonlyArray<string> | null;
  style?: TimeClockAppPreShiftMessageTypeEnum | null;
};
export type TimeClockAppPreShiftMessageConfigurableInput = {
  buttons: ReadonlyArray<string>;
  code: string;
  message: string;
  title: string;
  version: number;
};
export type TimeClockAppRestScreenSettingsInput = {
  breakCompletedUpperBound?: number | null;
  mealBreakApproachingLowerBound?: number | null;
  mealBreakDueUpperBound?: number | null;
  mealBreakEndApproachingLowerBound?: number | null;
  mealBreakEndDueUpperBound?: number | null;
  mealBreakOverdueUpperBound?: number | null;
  onTimeThreshold?: number | null;
  shiftApproachingLowerBound?: number | null;
  shiftDueUpperBound?: number | null;
  shiftEndApproachingLowerBound?: number | null;
  shiftEndDueUpperBound?: number | null;
  shiftLateUpperBound?: number | null;
  shiftOverdueUpperBound?: number | null;
};
export type UpdateScheduleAOSBasicMutation$variables = {
  businessId: string;
  id: string;
  input: ScheduleUpdateInput;
};
export type UpdateScheduleAOSBasicMutation$data = {
  readonly updateSchedule: {
    readonly aosConfig: {
      readonly addCoreBreaks: boolean;
      readonly addUnderstaffingShifts: boolean;
      readonly aosCombinedRoles: any | null;
      readonly aosOrchestratorConfig: any | null;
      readonly aosTimeout: number | null;
      readonly applyRule24HoursRest: boolean;
      readonly bonusRoles: ReadonlyArray<string> | null;
      readonly breakEndPad: number | null;
      readonly breakLength: number | null;
      readonly breakMaxStart: number | null;
      readonly breakMinStart: number | null;
      readonly breakTrigger: number | null;
      readonly coreObeysAosRules: boolean | null;
      readonly costEqualLabor: number | null;
      readonly daypartRanks: any | null;
      readonly demandOnly: ReadonlyArray<string> | null;
      readonly flexibleRole: string | null;
      readonly ignoreBreakRoles: ReadonlyArray<string> | null;
      readonly includeRoles: ReadonlyArray<string> | null;
      readonly maxRolesPerShift: number | null;
      readonly maxWorkdaysCalweek: number | null;
      readonly maxWorkdaysWorkweek: number | null;
      readonly minShiftPartLength: number | null;
      readonly optimizeCoreBreaks: boolean;
      readonly overstaffingByRole: ReadonlyArray<string> | null;
      readonly overstaffingPenalty: number | null;
      readonly penaltyShortParts: number | null;
      readonly penaltyShortPartsCutoff: number | null;
      readonly planningOrder: ReadonlyArray<string> | null;
      readonly replanningOrchestratorConfig: any | null;
      readonly replanningTimeout: number | null;
      readonly schedulingDayStart: string | null;
      readonly shiftConfig: any | null;
      readonly shiftGapMin: number | null;
      readonly shiftLengthPreference: number | null;
      readonly shiftMax: number | null;
      readonly shiftMaxStart: string | null;
      readonly shiftMin: number | null;
      readonly shiftMinAbsolute: number | null;
      readonly skillPreference: number | null;
      readonly skipSchedulingCore: boolean;
      readonly skipSchedulingManagers: boolean;
      readonly understaffingByRole: ReadonlyArray<string> | null;
      readonly weeklyDayparts: any | null;
      readonly weeklyWorkhoursMax: number | null;
      readonly weeklyWorkhoursMin: number | null;
      readonly weightEqualLaborByRating: ReadonlyArray<number> | null;
    } | null;
    readonly aosConfigSchema: any;
    readonly autoPopulateScheduleAtTime: number;
    readonly autoPopulateScheduleDaysInAdvance: number;
    readonly autoPopulateScheduleEnabled: boolean;
    readonly id: string;
    readonly labourCostPercentage: ReadonlyArray<number> | null;
    readonly labourCostPercentageEnabled: boolean;
    readonly populateScheduleEnabled: boolean;
  };
};
export type UpdateScheduleAOSBasicMutation = {
  response: UpdateScheduleAOSBasicMutation$data;
  variables: UpdateScheduleAOSBasicMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Schedule",
    "kind": "LinkedField",
    "name": "updateSchedule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "aosConfigSchema",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "populateScheduleEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "autoPopulateScheduleEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "autoPopulateScheduleDaysInAdvance",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "autoPopulateScheduleAtTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labourCostPercentageEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labourCostPercentage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AosConfig",
        "kind": "LinkedField",
        "name": "aosConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schedulingDayStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addUnderstaffingShifts",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weeklyWorkhoursMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weeklyWorkhoursMax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftMax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftMinAbsolute",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftGapMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftMaxStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxRolesPerShift",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minShiftPartLength",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakTrigger",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakLength",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakMinStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakMaxStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakEndPad",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "includeRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "demandOnly",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bonusRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ignoreBreakRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flexibleRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coreObeysAosRules",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skipSchedulingManagers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skipSchedulingCore",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addCoreBreaks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optimizeCoreBreaks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weeklyDayparts",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "daypartRanks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planningOrder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "overstaffingPenalty",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skillPreference",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftLengthPreference",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "penaltyShortParts",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "penaltyShortPartsCutoff",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "costEqualLabor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weightEqualLaborByRating",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "applyRule24HoursRest",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxWorkdaysCalweek",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxWorkdaysWorkweek",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "overstaffingByRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "understaffingByRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftConfig",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosTimeout",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosCombinedRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosOrchestratorConfig",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replanningTimeout",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replanningOrchestratorConfig",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateScheduleAOSBasicMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateScheduleAOSBasicMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "57893e34d9217aa8041ede7ade2804ee",
    "id": null,
    "metadata": {},
    "name": "UpdateScheduleAOSBasicMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateScheduleAOSBasicMutation(\n  $input: ScheduleUpdateInput!\n  $id: ID!\n  $businessId: ID!\n) {\n  updateSchedule(businessId: $businessId, id: $id, input: $input) {\n    id\n    aosConfigSchema\n    populateScheduleEnabled\n    autoPopulateScheduleEnabled\n    autoPopulateScheduleDaysInAdvance\n    autoPopulateScheduleAtTime\n    labourCostPercentageEnabled\n    labourCostPercentage\n    aosConfig {\n      schedulingDayStart\n      addUnderstaffingShifts\n      weeklyWorkhoursMin\n      weeklyWorkhoursMax\n      shiftMin\n      shiftMax\n      shiftMinAbsolute\n      shiftGapMin\n      shiftMaxStart\n      maxRolesPerShift\n      minShiftPartLength\n      breakTrigger\n      breakLength\n      breakMinStart\n      breakMaxStart\n      breakEndPad\n      includeRoles\n      demandOnly\n      bonusRoles\n      ignoreBreakRoles\n      flexibleRole\n      coreObeysAosRules\n      skipSchedulingManagers\n      skipSchedulingCore\n      addCoreBreaks\n      optimizeCoreBreaks\n      weeklyDayparts\n      daypartRanks\n      planningOrder\n      overstaffingPenalty\n      skillPreference\n      shiftLengthPreference\n      penaltyShortParts\n      penaltyShortPartsCutoff\n      costEqualLabor\n      weightEqualLaborByRating\n      applyRule24HoursRest\n      maxWorkdaysCalweek\n      maxWorkdaysWorkweek\n      overstaffingByRole\n      understaffingByRole\n      shiftConfig\n      aosTimeout\n      aosCombinedRoles\n      aosOrchestratorConfig\n      replanningTimeout\n      replanningOrchestratorConfig\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bacaf3f1a670eb431fc62937eaec52b7";

export default node;
