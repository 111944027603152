import React from "react";
import { ConnectionHandler } from "react-relay";
import { useEmployeeMetadataQueries } from "../EmploymentMetadataQueries";
import { TimeboxedTableRowData } from "./TimeboxedDataTable";
import {
  DynamicFieldsLayoutView,
  EmploymentMetadata,
} from "../../../../../data/generated/stack_internal_schema";
import { useModal } from "../../../../../contexts/ModalContext";
import CreateEditMetadataModal from "./CreateEditMetadataModal";
import RemoveMetadataConfirmModal from "./RemoveMetadataConfirmModal";
import { useDynamicFieldsLayout } from "../../../Business/MetadataLayout/MetadataLayoutQueries";
import { MetadataTimeboxedField, MetadataUtility } from "../MetadataUtility";
import {
  useBusinessContext,
  BusinessInContext,
} from "../../../../../contexts/BusinessContext";
import DynamicTimeboxedTableGroup from "./DynamicTimeboxedTableGroup";
import { useAppRouter } from "../../../../../hooks/useAppRouter";

type CreateEditForm = Partial<
  Pick<EmploymentMetadata, "details" | "startTime" | "endTime">
>;

export default function EmploymentMetadataTimeboxedProfile() {
  const { showModal, hideModal } = useModal();
  const { business } = useBusinessContext();
  const {
    params: { business_id: businessId, employment_id: employmentId },
  } = useAppRouter<{
    business_id: string;
    employment_id: string;
  }>();
  const [
    { groupFields, otherFields },
    {
      createEmploymentMetadata,
      deleteEmploymentMetadata,
      updateEmploymentMetadata,
    },
    employmentMetadataConnectionId,
    employeeHomeStore,
  ] = useMetadataTypeData();
  const [businessDynamicFieldsLayout] = useDynamicFieldsLayout();

  const onDeleteClicked = (tableRowData: TimeboxedTableRowData) => {
    const onDelete = () => {
      if (tableRowData.employmentMetadata) {
        deleteEmploymentMetadata({
          variables: {
            businessId,
            id: tableRowData.employmentMetadata.id,
          },
          onCompleted() {
            hideModal();
          },
          onError(error: Error) {
            alert(error);
          },

          // Delete the edge from the query cache
          updater(store) {
            const connectionReference = store.get(
              employmentMetadataConnectionId,
            );
            if (connectionReference && tableRowData?.employmentMetadata) {
              ConnectionHandler.deleteNode(
                connectionReference,
                tableRowData.employmentMetadata.id,
              );
            }
          },
        });
      }
    };

    showModal(
      <RemoveMetadataConfirmModal
        onClose={hideModal}
        onOk={onDelete}
        tableRowData={tableRowData}
      />,
    );
  };

  const onEditEmploymentMetadata = async (
    tableRowData: TimeboxedTableRowData,
  ) => {
    const onOk = (
      payload: CreateEditForm,
      errorHandler: (error: Error) => void,
    ) => {
      if (tableRowData.employmentMetadata) {
        updateEmploymentMetadata({
          variables: {
            businessId,
            id: tableRowData.employmentMetadata.id,
            input: {
              metadataTypeId: tableRowData.metadataType.id,
              employmentId,
              ...payload,
            },
          },
          onCompleted() {
            hideModal();
          },
          onError(error: Error) {
            errorHandler(error);
          },
          // updater function not needed for an existing key :)
        });
      }
    };

    showModal(
      <CreateEditMetadataModal
        onClose={hideModal}
        onOk={onOk}
        tableRowData={tableRowData}
        onDelete={() => onDeleteClicked(tableRowData)}
        isCreate={false}
        business={business as BusinessInContext}
      />,
    );
  };

  const onAddEmploymentMetadata = async (
    tableRowData: TimeboxedTableRowData,
  ) => {
    const onOk = (
      payload: CreateEditForm,
      errorHandler: (error: Error) => void,
    ) => {
      createEmploymentMetadata({
        variables: {
          businessId,
          input: {
            metadataTypeId: tableRowData.metadataType.id,
            employmentId,
            ...payload,
          },
        },
        onCompleted() {
          hideModal();
        },
        onError(error: Error) {
          errorHandler(error);
        },

        // Add a new edge into the query cache
        updater: (store) => {
          const newRecord = store.getRootField("createEmploymentMetadata");
          const connectionReference = store.get(employmentMetadataConnectionId);
          if (!newRecord || !connectionReference) {
            return;
          }
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionReference,
            newRecord,
            "EmploymentMetadataEdge",
          );
          ConnectionHandler.insertEdgeAfter(connectionReference, newEdge);
        },
      });
    };

    showModal(
      <CreateEditMetadataModal
        onClose={hideModal}
        onOk={onOk}
        tableRowData={tableRowData}
        isCreate
        business={business as BusinessInContext}
      />,
    );
  };

  return (
    <>
      {businessDynamicFieldsLayout.views.map(
        (view: DynamicFieldsLayoutView) => (
          <DynamicTimeboxedTableGroup
            key={view.name}
            view={view}
            metadataTypeFields={groupFields}
            businessDynamicFieldsLayout={businessDynamicFieldsLayout}
            onEdit={onEditEmploymentMetadata}
            onAdd={onAddEmploymentMetadata}
            employeeHomeStore={employeeHomeStore}
          />
        ),
      )}

      {otherFields.length > 0 && (
        <DynamicTimeboxedTableGroup
          view={null}
          metadataTypeFields={otherFields}
          businessDynamicFieldsLayout={businessDynamicFieldsLayout}
          onEdit={onEditEmploymentMetadata}
          onAdd={onAddEmploymentMetadata}
          employeeHomeStore={employeeHomeStore}
        />
      )}
    </>
  );
}

function useMetadataTypeData() {
  const [
    {
      businessMetadataTypes,
      employmentMetadata,
      employmentMetadataConnectionId,
      employeeHomeStore,
    },
    mutations,
  ] = useEmployeeMetadataQueries(true);
  const [businessDynamicFieldsLayout] = useDynamicFieldsLayout();

  const data = businessMetadataTypes.map((metadataType) =>
    MetadataUtility.getTimeboxedMetadataMappedData(
      metadataType,
      employmentMetadata,
    ),
  );

  const sortFn = (
    metadataFieldA: MetadataTimeboxedField,
    metadataFieldB: MetadataTimeboxedField,
  ) => {
    // sort alphabetically by field label
    return MetadataUtility.getDisplayName(
      metadataFieldA.metadataType,
    ).localeCompare(
      MetadataUtility.getDisplayName(metadataFieldB.metadataType),
    );
  };

  // Filter the timeboxed table fields into groups and split out those that aren't a part of a group
  const otherFields = data
    .filter((field) => {
      return !MetadataUtility.getMetadataTypesInAnyGroup(
        businessDynamicFieldsLayout,
      ).includes(field.metadataType.name);
    })
    .sort(sortFn);

  const groupFields = data
    .filter((field) => {
      return MetadataUtility.getMetadataTypesInAnyGroup(
        businessDynamicFieldsLayout,
      ).includes(field.metadataType.name);
    })
    .sort(sortFn);

  return [
    { otherFields, groupFields },
    mutations,
    employmentMetadataConnectionId,
    employeeHomeStore,
  ] as const;
}
