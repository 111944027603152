import React, { FormEventHandler } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import {
  DynamicFieldsLayoutViewInput,
  DynamicFieldsLayoutGroup,
  MetadataType,
} from "../../../../../data/generated/stack_internal_schema";
import FormGroup from "../../../../common/Form/FormGroup";
import MultiSelectList, {
  MultiSelectType,
} from "../../../../common/Form/MultiSelectList";
import { MetadataUtility } from "../../../Employment/EmploymentMetadata/MetadataUtility";
import { BaseOption } from "../../../../../data/models/common";

type Props = {
  onClose: () => void;
  metadataType: MetadataType;
  onOk: (groups: DynamicFieldsLayoutGroup[]) => void;
  groups: DynamicFieldsLayoutGroup[];
  views: DynamicFieldsLayoutViewInput[];
};

type FormValues = {
  groups: DynamicFieldsLayoutGroup[];
};

const StyledModalTitle = styled(Modal.Title)`
  font-size: 20px;
`;

export function RequiredFieldConfirmationModal({
  onClose,
  metadataType,
  groups,
  views,
  onOk,
}: Props) {
  const { t } = useTranslation("metadata-types");
  const cancelModal = () => {
    onClose();
  };

  const visibleGroups = groups.reduce(
    (list: BaseOption<DynamicFieldsLayoutGroup, string>[], i) => {
      const viewsUsingGroup = MetadataUtility.getViewsForGroup(i, views);
      if (viewsUsingGroup.length) {
        list.push({
          label: i.name,
          value: i,
        });
      }
      return list;
    },
    [],
  );

  return (
    <Formik<FormValues>
      onSubmit={(v) => {
        onOk(v.groups);
      }}
      initialValues={{
        groups: [],
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Modal centered onHide={cancelModal} show>
          <Modal.Header closeButton>
            <StyledModalTitle>
              {t("requiredFieldConfirmationModal.title")}
            </StyledModalTitle>
          </Modal.Header>
          <Form onSubmit={handleSubmit as unknown as FormEventHandler}>
            <Modal.Body>
              <Trans
                i18nKey="metadata-types:requiredFieldConfirmationModal.body"
                values={{ name: metadataType.name }}
                components={{ bold: <strong /> }}
              />

              <Row>
                <FormGroup
                  md={12}
                  lg={12}
                  fieldKey="groups"
                  label={t("requiredFieldConfirmationModal.selectGroups")}
                >
                  <MultiSelectList<DynamicFieldsLayoutGroup>
                    allOptions={visibleGroups}
                    selectableOptions={visibleGroups}
                    multiStyle={MultiSelectType.Pill}
                    value={values.groups}
                    fieldKey="groups"
                    onChange={(
                      newValue: DynamicFieldsLayoutGroup[] | null | undefined,
                    ) => {
                      setFieldValue("groups", newValue || []);
                    }}
                  />
                </FormGroup>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="link" className="mr-4" onClick={cancelModal}>
                {t("requiredFieldConfirmationModal.actions.cancel")}
              </Button>
              <Button variant="primary" type="submit">
                {values.groups.length
                  ? t(
                      "requiredFieldConfirmationModal.actions.saveAndAddGroup",
                      {
                        count: values.groups.length,
                      },
                    )
                  : t("requiredFieldConfirmationModal.actions.saveAnyway")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
