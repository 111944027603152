import { graphql } from "react-relay";
import { JobTitlesQueries_ListFragment$data } from "./__generated__/JobTitlesQueries_ListFragment.graphql";

export const GetJobTitlesQuery = graphql`
  query JobTitlesQueries_InternalQuery(
    $businessId: ID!
    $pageSize: Int!
    $after: String
    $sort: [JobTitleSort!]
  ) {
    ...JobTitlesQueries_ListFragment
  }
`;

export const JobTitleFragment = graphql`
  fragment JobTitlesQueries_ListFragment on InternalQuery
  @refetchable(queryName: "JobTitleTableQuery") {
    jobTitles(
      businessId: $businessId
      first: $pageSize
      after: $after
      sort: $sort
    ) @connection(key: "JobTitlesTable_jobTitles") {
      edges {
        node {
          code
          createdAt
          deleted
          description
          id
          isManager
          level
          maxPayRate
          minPayRate
          name
          salaried
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

type EdgeType = {} & JobTitlesQueries_ListFragment$data["jobTitles"]["edges"];
type Edge = {} & EdgeType[number];
export type JobTitleListItem = {} & Edge["node"];
