import { graphql } from "react-relay";
import { BusinessQueries_ListFragment$data } from "./__generated__/BusinessQueries_ListFragment.graphql";

export const BusinessTablePageQuery = graphql`
  query BusinessQueries_InternalQuery(
    $searchValue: String!
    $pageSize: Int!
    $after: String
  ) {
    ...BusinessQueries_ListFragment
  }
`;

export const BusinessTableFragment = graphql`
  fragment BusinessQueries_ListFragment on InternalQuery
  @refetchable(queryName: "BusinessTableQuery") {
    businesses(search: $searchValue, first: $pageSize, after: $after)
      @connection(key: "BusinessTable_businesses") {
      edges {
        node {
          id
          businessName
          shortDescription
          globalBusinessId
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

type EdgeType = {} & BusinessQueries_ListFragment$data["businesses"]["edges"];
type Edge = {} & EdgeType[number];
export type BusinessListItem = {} & Edge["node"];
