import { commitMutation, graphql, Environment } from "react-relay";

const mutation = graphql`
  mutation UpdateEmploymentMutation(
    $input: EmploymentInput!
    $id: ID!
    $businessId: ID!
  ) {
    updateEmployment(businessId: $businessId, id: $id, input: $input) {
      id
      userId
      createdAt
      acceptedInvite
      during
      updatedAt
      userEmail
      businessInvite {
        id
        activationCode
        attemptsRemaining
        expiryTime
        inviteToken
      }
      ### Replaceable content start
      email
      firstName
      lastName
      nickname
      dateOfBirth
      imageId
      schoolId
      external
      rating
      employmentRate
      securityRole
      code
      employmentTypeCode
      payrollCode
      mutedFeatures
      ### Replaceable content finish
    }
  }
`;

export default (
  environment: Environment,
  input: {},
  id: string,
  businessId: string,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    input,
    id,
    businessId,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
