import React from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "react-relay";
import { Pillbox, PillboxVariantEnum } from "../../../common/Pillbox";
import { useModal } from "../../../../contexts/ModalContext";
import { ConfirmationModal } from "../../../common/ConfirmationModal";
import {
  EmploymentFeedbackReason,
  EmploymentFeedbackTypeEnum,
} from "../../../../data/generated/stack_internal_schema";
import { useBusinessContext } from "../../../../contexts/BusinessContext";
import {
  FeedbackReasonsModalAction,
  useFeedbackReasonsCreateEditModal,
} from "./FeedbackReasonsModal";
import {
  DeactivateEmploymentFeedbackReasonMutation,
  ReactivateEmploymentFeedbackReasonMutation,
} from "./FeedbackReasonsQueries";

type Props = {
  feedbackType: EmploymentFeedbackTypeEnum;
  feedbackReasons: EmploymentFeedbackReason[];
  refreshQuery: any;
};

type Header = {
  name: string;
};

export default function FeedbackReasonsTable({
  feedbackType,
  feedbackReasons,
  refreshQuery,
}: Props) {
  const { t } = useTranslation("feedback-reasons");
  const header = (name: string) => {
    return {
      name: name ? t(`table.headers.${name}`) : "",
    };
  };

  const headers: Header[] = [
    header("reason"),
    header("predefinedComment"),
    header("status"),
    header(""), // "edit/deactivate"
  ];

  const onDeactivate = useFeedbackReasonsToggleActivationModal(refreshQuery);

  const onEdit = useFeedbackReasonsCreateEditModal(
    FeedbackReasonsModalAction.Edit,
    feedbackType,
    refreshQuery,
    t,
  );

  return (
    <Table hover size="sm" className="mt-3">
      <thead>
        <tr>
          {headers.map((h) => (
            <th key={h.name}>{h.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {feedbackReasons.map((feedbackReason: EmploymentFeedbackReason) => (
          <tr key={feedbackReason.id} className="hoverable">
            <td>{feedbackReason.description}</td>
            <td>{feedbackReason.defaultMessage}</td>
            <td>
              <Pillbox
                variant={
                  feedbackReason.deleted
                    ? PillboxVariantEnum.Removed
                    : PillboxVariantEnum.Active
                }
                text={t(
                  `table.status.${
                    feedbackReason.deleted ? "deactivated" : "active"
                  }`,
                )}
              />
            </td>
            <td>
              <span className="border-right visible-on-hover">
                <Button
                  variant="link"
                  className="ml-1 mr-2"
                  onClick={onEdit(feedbackReason)}
                >
                  {t("translation:form.actions.edit")}
                </Button>
              </span>
              <span className="visible-on-hover">
                <Button
                  variant="link"
                  className="ml-2 mr-2"
                  onClick={onDeactivate(feedbackReason)}
                >
                  {t(
                    `table.action.${
                      feedbackReason.deleted ? "activate" : "deactivate"
                    }`,
                  )}
                </Button>
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function useFeedbackReasonsToggleActivationModal(
  onFeedbackReasonUpdate: (feedbackReason: EmploymentFeedbackReason) => void,
) {
  const { showModal, hideModal } = useModal();
  const { business } = useBusinessContext();
  const { t } = useTranslation("feedback-reasons");

  const [reactivateFeedbackReason] = useMutation(
    ReactivateEmploymentFeedbackReasonMutation,
  );
  const [deactivateFeedbackReason] = useMutation(
    DeactivateEmploymentFeedbackReasonMutation,
  );

  return (feedbackReason: EmploymentFeedbackReason) => () => {
    if (!business) {
      alert("Business required");
      return;
    }

    const toggle = feedbackReason.deleted
      ? reactivateFeedbackReason
      : deactivateFeedbackReason;

    const successI18nKey = `translation:form.notifications.${
      feedbackReason.deleted
        ? "activated_successfully"
        : "deactivated_successfully"
    }`;

    const showDeactivate = !feedbackReason?.deleted;

    showModal(
      <ConfirmationModal
        onClose={hideModal}
        okClicked={() => {
          toggle({
            variables: {
              id: feedbackReason.id,
              businessId: business.id,
            },
            onCompleted(response: any) {
              const updatedFeedbackReason: EmploymentFeedbackReason =
                response.deactivateEmploymentFeedbackReason ??
                response.activateEmploymentFeedbackReason;
              onFeedbackReasonUpdate(updatedFeedbackReason);

              toast(t(successI18nKey));
              hideModal();
            },
            onError(error: Error) {
              alert(error);
              hideModal();
            },
          });
        }}
        variant={showDeactivate ? "danger" : "primary"}
        title={t(
          `${feedbackReason.feedbackType}.modal.title.${
            feedbackReason.deleted ? "activate" : "deactivate"
          }`,
        )}
        okText={t(
          `modal.ok_button.${
            feedbackReason.deleted ? "activate" : "deactivate"
          }`,
        )}
      >
        {t(
          `modal.description.${
            feedbackReason.deleted ? "activate" : "deactivate"
          }`,
          {
            reason: feedbackReason.description,
          },
        )}
      </ConfirmationModal>,
    );
  };
}
