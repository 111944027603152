import React, { useState } from "react";
import {
  Environment,
  useLazyLoadQuery,
  graphql,
  useMutation,
} from "react-relay";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Trans, useTranslation } from "react-i18next";
import { DeleteModal_DeleteSkillLevelMutation } from "./__generated__/DeleteModal_DeleteSkillLevelMutation.graphql";
import ServerError from "../../../../utils/server-error";
import { Profile_skillLevel$data } from "../__generated__/Profile_skillLevel.graphql";
import { DeleteModal_EmploymentRolesQuery } from "./__generated__/DeleteModal_EmploymentRolesQuery.graphql";

const EmploymentRolesCountQuery = graphql`
  query DeleteModal_EmploymentRolesQuery($businessId: ID!, $skillLevelId: ID!) {
    employmentRoles(businessId: $businessId, skillLevelId: $skillLevelId) {
      totalCount
    }
  }
`;

type SkillLevel = Omit<Profile_skillLevel$data, " $refType">;

type Props = {
  businessId: string;
  id: string;
  skillLevel: SkillLevel;
  onClose: Function;
  onSuccess: Function;
  environment: Environment;
};

function DeleteModal({
  businessId,
  id,
  skillLevel,
  onClose,
  onSuccess,
  environment,
}: Props) {
  const [commit, isInFlight] =
    useMutation<DeleteModal_DeleteSkillLevelMutation>(graphql`
      mutation DeleteModal_DeleteSkillLevelMutation(
        $businessId: ID!
        $id: ID!
      ) {
        deleteSkillLevel(businessId: $businessId, id: $id)
      }
    `);

  const data = useLazyLoadQuery<DeleteModal_EmploymentRolesQuery>(
    EmploymentRolesCountQuery,
    {
      skillLevelId: id,
      businessId,
    },
    { fetchPolicy: "store-or-network" },
  );

  const { t } = useTranslation("skill-levels");
  const [error, setError] = useState<string>();

  const cancelModal = () => {
    setError(undefined);
    onClose();
  };

  const { totalCount: employmentRolesCount } = data.employmentRoles;

  if (employmentRolesCount > 0) {
    return (
      <Modal show onHide={cancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("deleteConfirmation.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <Trans
              t={t}
              i18nKey="deleteConfirmation.error"
              values={{
                name: skillLevel.name,
                level: skillLevel.level,
                count: employmentRolesCount,
              }}
              components={{ italic: <i />, bold: <strong /> }}
            />
          </p>

          <p>{t("deleteConfirmation.errorHelpText")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={cancelModal}>
            {t("deleteConfirmation.ok")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleDelete = () => {
    if (!environment) {
      return;
    }

    commit({
      variables: {
        businessId,
        id,
      },
      onCompleted() {
        onClose();
        onSuccess();
      },
      onError(e) {
        const { source } = (e as any) || {};
        if (!source) {
          return;
        }
        const serverError = new ServerError(source);
        setError(serverError.getErrorDetails());
      },
    });
  };

  const renderError = () => {
    return error ? (
      <Form.Control.Feedback type="invalid" className="d-block">
        {error}
      </Form.Control.Feedback>
    ) : null;
  };

  return (
    <Modal show onHide={cancelModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("deleteConfirmation.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Trans
            t={t}
            i18nKey="deleteConfirmation.body"
            values={{ name: skillLevel.name, level: skillLevel.level }}
            components={{ italic: <i />, bold: <strong /> }}
          />
          {renderError()}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelModal}>
          {t("deleteConfirmation.cancel")}
        </Button>
        <Button variant="danger" onClick={handleDelete} disabled={isInFlight}>
          {t("deleteConfirmation.remove")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
