import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import * as yup from "yup";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { DateTime } from "luxon";
import { Pillbox } from "../../../../common/Pillbox";
import FormLayout from "../../../../common/Form/FormLayout";
import Field from "../../../../common/Form/Field";
import { StringFieldType } from "../../../../common/Form/models";
import {
  EmploymentMetadata,
  MetadataTypeDataTypeEnum,
} from "../../../../../data/generated/stack_internal_schema";
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import DateTimePicker from "../../../../common/Form/DateTimePicker";
import { MetadataUtility } from "../MetadataUtility";
import { pillMixin } from "../../../../../styles/mixins";
import { EmployeeHomeStore } from "../EmploymentMetadataQueries";
import { TimeboxedTableRowData } from "./TimeboxedDataTable";
import { getDateBasedOnDayStart } from "../../../../../utils/utility";
import { BusinessInContext } from "../../../../../contexts/BusinessContext";
import { GraphQLDateFormat } from "../../../../../data/models/common";

const StyledExternalWarningMessage = styled.span`
  ${(props) => pillMixin(props.theme.generic.yellow)}

  margin: 0px 12px 16px 12px;
  padding: 7px 0px 7px 16px;
  width: 100%;
  font-weight: bold;
  border-radius: 7px;
`;

type CreateEditForm = Partial<
  Pick<EmploymentMetadata, "details" | "startTime" | "endTime">
>;

type Props = {
  onClose: () => void;
  onOk: (payload: CreateEditForm, errorHandler: (error: Error) => void) => void;
  isCreate: boolean;
  onDelete?: () => void;
  tableRowData: TimeboxedTableRowData;
  business: BusinessInContext;
};

export default function CreateEditMetadataModal(props: Props) {
  const { onClose, onOk, isCreate, tableRowData, business } = props;

  const {
    metadataType,
    employmentMetadata = {},
    employeeHomeStore,
  } = tableRowData;

  const { t } = useTranslation("employment");

  const validationRules = yup.object().shape(
    {
      startTime: yup
        .date()
        .when("endTime", {
          is: (value?: string) => !!value,
          then: yup
            .date()
            .max(
              yup.ref("endTime"),
              t("metadata.timeboxed.editModal.errors.startDateMax"),
            ),
        })
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .label(t("metadata.timeboxed.editModal.labels.startDate")),
      endTime: yup
        .date()
        .when("startTime", {
          is: (value?: string) => !!value,
          then: yup
            .date()
            .min(
              yup.ref("startTime"),
              t("metadata.timeboxed.editModal.errors.endDateMin"),
            ),
        })
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .label(t("metadata.timeboxed.editModal.labels.endDate")),
      details: MetadataUtility.applyYupRulesToMetadataType(
        metadataType,
        true,
        // LK-9035: Note: this won't stop the required logic from functioning, but it will stop weird validation messages from appearing
      ).nullable(),
    },
    [["startTime", "endTime"]],
  );

  const onSave = (
    changes: Partial<CreateEditForm>,
    errorHandler: (error: Error) => void,
  ) => {
    const timeZone = employeeHomeStore?.schedule?.timeZone;
    const dayStartTime =
      employeeHomeStore?.schedule?.dayStartTime ??
      (business.defaultScheduleDayStartTime as string);

    const data = isCreate ? validationRules.cast(changes) : changes;

    const startTime = data.startTime
      ? getDateBasedOnDayStart(dayStartTime, timeZone, data.startTime)
      : null;

    const endTime = data.endTime
      ? getDateBasedOnDayStart(dayStartTime, timeZone, data.endTime)
      : null;

    onOk(
      {
        ...data,
        ...(startTime && {
          startTime,
        }),
        ...(endTime && {
          endTime,
        }),
      },
      errorHandler,
    );
  };

  return (
    <Modal backdrop="static" centered onHide={onClose} show>
      <Modal.Header className="d-flex">
        <Modal.Title as="h3">
          {t(
            `metadata.timeboxed.editModal.title_${
              isCreate ? "create" : "edit"
            }`,
            {
              name: MetadataUtility.getDisplayName(metadataType),
            },
          )}
        </Modal.Title>
        {!isCreate && (
          <span className="justify-content-end">
            <Pillbox
              {...MetadataUtility.getBadgeStatusForEmploymentMetadata(
                employmentMetadata,
              )}
              saturated={false}
            />
          </span>
        )}
      </Modal.Header>
      <Modal.Body>
        {metadataType?.external && (
          <Row>
            <StyledExternalWarningMessage>
              <span className="pill-text">
                {t("metadata.timeboxed.editModal.externalWarning", {
                  metadataTypeName:
                    MetadataUtility.getDisplayName(metadataType),
                })}
              </span>
            </StyledExternalWarningMessage>
          </Row>
        )}
        <FormLayout<Partial<CreateEditForm>>
          formId="edit-metadata-form"
          hidePrompt
          onSave={onSave}
          isCreate={isCreate}
          propertyList={[]}
          base={employmentMetadata}
          validationRules={validationRules}
        >
          <ModalForm {...props} />
        </FormLayout>
      </Modal.Body>
    </Modal>
  );
}

function ModalForm({
  onClose,
  onOk,
  onDelete,
  isCreate,
  tableRowData,
  business,
}: Props) {
  const { metadataType, employeeHomeStore } = tableRowData;
  const { t } = useTranslation("employment");
  const { dirty, isSubmitting } = useFormikContext<CreateEditForm>();

  const timeZoneHelpText = useTimestampHelpText(employeeHomeStore, business);

  const fieldWidth = calculateFieldWidth(metadataType.dataType);

  // The default value of a boolean is false when creating - allow the user to submit in this case
  const isDirty =
    dirty ||
    (isCreate && metadataType.dataType === MetadataTypeDataTypeEnum.Boolean);

  return (
    <>
      <Row>
        <Field
          {...MetadataUtility.getFieldPropsForMetadataType(metadataType).field}
          lg={fieldWidth}
          md={fieldWidth}
          xs={fieldWidth}
          fieldKey="details"
        />
      </Row>
      <Row>
        <Field
          lg={6}
          md={6}
          xs={6}
          fieldKey="startTime"
          description={timeZoneHelpText}
          label={t("metadata.timeboxed.editModal.labels.startDate")}
          schemaFieldType={StringFieldType}
          component={DateTimePicker}
          componentProps={{
            isClearable: true,
            displayFormat: "dd/MM/yyyy",
            timezone: employeeHomeStore?.schedule?.timeZone,
          }}
        />
        <Field
          lg={6}
          md={6}
          xs={6}
          fieldKey="endTime"
          description={timeZoneHelpText}
          label={t("metadata.timeboxed.editModal.labels.endDate")}
          schemaFieldType={StringFieldType}
          component={DateTimePicker}
          componentProps={{
            isClearable: true,
            displayFormat: "dd/MM/yyyy",
            timezone: employeeHomeStore?.schedule?.timeZone,
          }}
        />
      </Row>
      <Modal.Footer
        className={onDelete ? "d-flex justify-content-between" : undefined}
      >
        {onDelete && (
          <Button variant="link" className="mr-4" onClick={onDelete}>
            {t("metadata.timeboxed.editModal.buttons.remove")}
          </Button>
        )}
        <div>
          <Button variant="link" className="mr-4" onClick={onClose}>
            {t("metadata.timeboxed.editModal.buttons.cancel")}
          </Button>
          <Button
            disabled={!isDirty || isSubmitting}
            type="submit"
            form="edit-metadata-form"
            variant="primary"
          >
            {t("metadata.timeboxed.editModal.buttons.ok")}
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
}

function useTimestampHelpText(
  employeeHomeStore: EmployeeHomeStore | null,
  business: BusinessInContext,
) {
  const timeZone = employeeHomeStore?.schedule?.timeZone;

  const dayStartTime = DateTime.fromFormat(
    employeeHomeStore?.schedule?.dayStartTime ??
      business.defaultScheduleDayStartTime,
    GraphQLDateFormat,
  ).toUTC();

  return `${dayStartTime.toFormat("hh:mm a")} ${dayStartTime
    .setZone(timeZone ?? "utc")
    .toFormat("ZZZZ")}`;
}

function calculateFieldWidth(dataType: MetadataTypeDataTypeEnum) {
  switch (dataType) {
    case MetadataTypeDataTypeEnum.Date:
    case MetadataTypeDataTypeEnum.Object:
    case MetadataTypeDataTypeEnum.Timestamp:
      return 12;
    default:
      return 6;
  }
}
