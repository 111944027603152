/**
 * @generated SignedSource<<1edd55897abaff0f8b5bd6455b122322>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TimeClockAppPreShiftMessageTypeEnum = "configurable" | "predefined" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScheduleProfileForm_schedule$data = {
  readonly analyticsEnabled: boolean;
  readonly approvalNeededForEarlyBreakEnd: boolean;
  readonly approvalNeededForMinorViolations: boolean;
  readonly approvalNeededForNonCompliantBreak: boolean;
  readonly approvalNeededForPinLogin: boolean;
  readonly approvalNeededForRoleRateChange: boolean;
  readonly approvalNeededForShiftChange: boolean;
  readonly approvalNeededForUnplannedShift: boolean;
  readonly autoGfeConfig: any | null;
  readonly autoGfeEnabled: boolean;
  readonly autoPublishConfig: any | null;
  readonly autoPublishEnabled: boolean;
  readonly clockInThreshold: number | null;
  readonly clockOutThreshold: number | null;
  readonly clockTrackingEnabled: boolean;
  readonly code: string | null;
  readonly complianceStartDate: any | null;
  readonly dataLakeId: string | null;
  readonly dayEndTime: string | null;
  readonly dayPartInheritedScheduleId: string | null;
  readonly dayPartTemplateId: string | null;
  readonly dayStartTime: string | null;
  readonly employeeMultiRateEnabled: boolean;
  readonly firstDayOfWeek: number | null;
  readonly hrGoLiveDate: any | null;
  readonly id: string;
  readonly idleTimeoutEnabled: boolean;
  readonly idleTimeoutLength: number;
  readonly locale: string | null;
  readonly monthlyCostLoading: number;
  readonly payCycleStartDate: any | null;
  readonly payDates: ReadonlyArray<number> | null;
  readonly payFrequency: string;
  readonly payPeriodEnabled: boolean;
  readonly payPeriodRequiresApproval: boolean;
  readonly payrollCutoffTime: string | null;
  readonly populateScheduleConfig: any;
  readonly punchSlipMode: string;
  readonly restScreenTheme: string;
  readonly ruleSetId: string | null;
  readonly scheduleName: string;
  readonly scheduleStatus: string;
  readonly settings: any | null;
  readonly shiftCanOverlapLeaveRequest: boolean;
  readonly shiftCanOverlapUnavailability: boolean;
  readonly shiftDropEnabled: boolean;
  readonly shiftMultiRateEnabled: boolean;
  readonly shiftShowUpThreshold: number;
  readonly shiftSwapEnabled: boolean;
  readonly shiftSwapNeedsApproval: boolean;
  readonly shiftUnpublishConsentEnabled: boolean;
  readonly shiftUnpublishConsentThreshold: number;
  readonly showEarnings: boolean;
  readonly snapshotDuration: number | null;
  readonly snapshotStartDate: any | null;
  readonly timeClockAppPreShiftFormattedMessages: {
    readonly configurable: ReadonlyArray<{
      readonly buttons: ReadonlyArray<string>;
      readonly code: string;
      readonly message: string;
      readonly title: string;
      readonly version: number;
    }> | null;
    readonly predefined: ReadonlyArray<string> | null;
    readonly style: TimeClockAppPreShiftMessageTypeEnum | null;
  };
  readonly timeClockAppRestScreenSettings: {
    readonly breakCompletedUpperBound: number;
    readonly mealBreakApproachingLowerBound: number;
    readonly mealBreakDueUpperBound: number;
    readonly mealBreakEndApproachingLowerBound: number;
    readonly mealBreakEndDueUpperBound: number;
    readonly mealBreakOverdueUpperBound: number;
    readonly onTimeThreshold: number;
    readonly shiftApproachingLowerBound: number;
    readonly shiftDueUpperBound: number;
    readonly shiftEndApproachingLowerBound: number;
    readonly shiftEndDueUpperBound: number;
    readonly shiftLateUpperBound: number;
    readonly shiftOverdueUpperBound: number;
  };
  readonly timeClockAppWorkflow: string | null;
  readonly timeClockRoleRateChangeAuthThreshold: number;
  readonly timeZone: string | null;
  readonly timekeepingGoLiveDate: any | null;
  readonly unplannedShiftStartThreshold: number;
  readonly usesBiometrics: boolean;
  readonly voluntaryLateClockOutEnabled: boolean;
  readonly " $fragmentType": "ScheduleProfileForm_schedule";
};
export type ScheduleProfileForm_schedule$key = {
  readonly " $data"?: ScheduleProfileForm_schedule$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleProfileForm_schedule">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleProfileForm_schedule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeZone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduleName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduleStatus",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locale",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hrGoLiveDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timekeepingGoLiveDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstDayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayStartTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayEndTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayPartTemplateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayPartInheritedScheduleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "analyticsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "snapshotStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "snapshotDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataLakeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoPublishEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoPublishConfig",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ruleSetId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "complianceStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoGfeEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoGfeConfig",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payPeriodEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payPeriodRequiresApproval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employeeMultiRateEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payrollCutoffTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payCycleStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payFrequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payDates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clockTrackingEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clockInThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clockOutThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftUnpublishConsentEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftUnpublishConsentThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftShowUpThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftCanOverlapLeaveRequest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftCanOverlapUnavailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftMultiRateEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftSwapEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftSwapNeedsApproval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDropEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showEarnings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "idleTimeoutEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "idleTimeoutLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voluntaryLateClockOutEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usesBiometrics",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unplannedShiftStartThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "punchSlipMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "restScreenTheme",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeClockAppWorkflow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeClockAppPreShiftFormattedShiftMessages",
      "kind": "LinkedField",
      "name": "timeClockAppPreShiftFormattedMessages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "style",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TimeClockAppPreShiftMessageConfigurable",
          "kind": "LinkedField",
          "name": "configurable",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "version",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "buttons",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "predefined",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalNeededForShiftChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalNeededForUnplannedShift",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalNeededForEarlyBreakEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalNeededForNonCompliantBreak",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalNeededForPinLogin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalNeededForMinorViolations",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalNeededForRoleRateChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeClockRoleRateChangeAuthThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeClockAppRestScreenSettings",
      "kind": "LinkedField",
      "name": "timeClockAppRestScreenSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftApproachingLowerBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftDueUpperBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftOverdueUpperBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftLateUpperBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftEndApproachingLowerBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftEndDueUpperBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mealBreakApproachingLowerBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mealBreakDueUpperBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mealBreakOverdueUpperBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mealBreakEndApproachingLowerBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mealBreakEndDueUpperBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "breakCompletedUpperBound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onTimeThreshold",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyCostLoading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "populateScheduleConfig",
      "storageKey": null
    }
  ],
  "type": "Schedule",
  "abstractKey": null
};
})();

(node as any).hash = "d62ff006e841e7a896dd1125b1ff7131";

export default node;
