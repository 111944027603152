import React from "react";
import { Link } from "react-router-dom";
import {
  graphql,
  createPaginationContainer,
  RelayPaginationProp,
} from "react-relay";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { AOSTemplateTable_viewer$data } from "./__generated__/AOSTemplateTable_viewer.graphql";
import RelayLazyLoader from "../../common/RelayLazyLoader";
import { toRelative } from "../../../utils/utility";
import AOSTemplateModal, { AOSModalEnum } from "./AOSTemplateModal";
import { useModal } from "../../../contexts/ModalContext";

type EdgeType =
  {} & AOSTemplateTable_viewer$data["aosConfigTemplates"]["edges"];
export type AOSTemplate = EdgeType[number]["node"];

const AOSTemplateTablePageQuery = graphql`
  query AOSTemplateTable_InternalQuery(
    $businessId: ID!
    $search: String
    $pageSize: Int!
    $after: String
  ) {
    ...AOSTemplateTable_viewer
      @arguments(
        businessId: $businessId
        search: $search
        pageSize: $pageSize
        after: $after
      )
  }
`;

type Props = {
  stackId: string;
  businessId: string;
  searchValue: string;
  viewer: AOSTemplateTable_viewer$data;
  relay: RelayPaginationProp;
};

function AOSTemplateTableBase(props: Props) {
  const { t } = useTranslation("aos");

  const { showModal } = useModal();

  const { stackId, businessId, viewer, relay, ...rest } = props;
  const aosConfigTemplates = (viewer.aosConfigTemplates.edges || []).map(
    (x) => x.node,
  );

  const showConfirmDeleteModal = (aosConfigTemplate: AOSTemplate) => {
    showModal(
      <AOSTemplateModal
        modal={AOSModalEnum.ComfirmDelete}
        businessId={businessId}
        onDeletedCallback={() => relay?.refetchConnection(30)}
        onToggleDefaultCallback={() => relay?.refetchConnection(30)}
        template={aosConfigTemplate || null}
        {...rest}
      />,
    );
  };

  const showSetAsDefaultModal = (aosConfigTemplate: AOSTemplate) => {
    showModal(
      <AOSTemplateModal
        modal={AOSModalEnum.SetAsDefault}
        businessId={businessId}
        onDeletedCallback={() => relay?.refetchConnection(30)}
        onToggleDefaultCallback={() => relay?.refetchConnection(30)}
        template={aosConfigTemplate || null}
        {...rest}
      />,
    );
  };

  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("aosTemplatesTable.headers.templateName")}</th>
            <th>{t("aosTemplatesTable.headers.updatedBy")}</th>
            <th>{t("aosTemplatesTable.headers.lastUpdate")}</th>
            <th className="w-25">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {aosConfigTemplates.map((aosConfigTemplate) => (
            <tr key={aosConfigTemplate.id} className="hoverable">
              <td>
                <Link
                  to={`/stack/${stackId}/business/${businessId}/aos_template/edit/${aosConfigTemplate.id}`}
                >
                  {aosConfigTemplate.templateName}
                  {aosConfigTemplate.isDefault ? (
                    <Badge variant="secondary" className="ml-2">
                      {t("aosTemplate.default")}
                    </Badge>
                  ) : null}
                </Link>
              </td>
              <td>
                <Link
                  to={`/stack/${stackId}/business/${businessId}/employee/${
                    aosConfigTemplate.updatedById ??
                    aosConfigTemplate.createdById
                  }`}
                >
                  {aosConfigTemplate.updatedByName ??
                    aosConfigTemplate.createdByName}
                </Link>
              </td>
              <td>
                {toRelative(aosConfigTemplate.updatedAt as string, {
                  defaultValue: "-",
                })}
              </td>
              <td>
                <span className="border-right show-inline-on-hover">
                  <Link
                    to={`/stack/${stackId}/business/${businessId}/aos_template/clone/${aosConfigTemplate.id}`}
                  >
                    <Button variant="link" className="ml-1 mr-2">
                      Clone
                    </Button>
                  </Link>
                </span>
                <span className="border-right show-inline-on-hover">
                  <Button
                    variant="link"
                    className="ml-1 mr-2"
                    onClick={() => showSetAsDefaultModal(aosConfigTemplate)}
                  >
                    {aosConfigTemplate.isDefault
                      ? t("actions.removeAsDefaultTemplate")
                      : t("actions.setAsDefaultTemplate")}
                  </Button>
                </span>
                <Button
                  variant="link"
                  className="ml-1 mr-2 show-inline-on-hover"
                  onClick={() => showConfirmDeleteModal(aosConfigTemplate)}
                >
                  {t("actions.delete")}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <RelayLazyLoader relay={relay} />
    </>
  );
}

export default createPaginationContainer(
  AOSTemplateTableBase,
  {
    viewer: graphql`
      fragment AOSTemplateTable_viewer on InternalQuery
      @argumentDefinitions(
        businessId: { type: "ID!" }
        search: { type: "String" }
        pageSize: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        sort: {
          type: "[AosConfigTemplateSort!]"
          defaultValue: [{ field: templateName, order: asc }]
        }
      ) {
        aosConfigTemplates(
          businessId: $businessId
          search: $search
          first: $pageSize
          after: $after
          sort: $sort
        ) @connection(key: "AOSTemplateTable_aosConfigTemplates") {
          edges {
            node {
              id
              templateName
              isDefault
              updatedAt
              updatedByName
              updatedById
              createdByName
              createdById
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: AOSTemplateTablePageQuery,
    getConnectionFromProps: (props) => props.viewer.aosConfigTemplates,
    getFragmentVariables: (previousVars, pageSize) => ({
      ...previousVars,
      pageSize,
    }),
    getVariables: (props, paginationInfo) => ({
      businessId: props.businessId,
      search: props.searchValue,
      pageSize: paginationInfo.count,
      after: paginationInfo.cursor,
    }),
  },
);
