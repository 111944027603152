import React, { FunctionComponent, PropsWithChildren } from "react";
import { FormikContext } from "formik";
import Form from "react-bootstrap/Form";
import { FormGroupProperties } from "./models";

type Props = {
  value: string;
  fieldKey: string;
  onChange: (v: any) => void;
} & FormGroupProperties;

const Textarea: FunctionComponent<PropsWithChildren<Props>> = (
  p: PropsWithChildren<Props>,
) => {
  const { value, fieldKey, onChange, xs, lg, md, ...rest } = p;
  return (
    <FormikContext.Consumer>
      {(formikContext) => {
        const meta = formikContext.getFieldMeta(fieldKey);
        const { error } = meta;
        return (
          <Form.Control
            key="text-area-control"
            as="textarea"
            isInvalid={error != null}
            value={value || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.currentTarget.value);
            }}
            {...rest}
          />
        );
      }}
    </FormikContext.Consumer>
  );
};

export default Textarea;
