/**
 * @generated SignedSource<<52701b122f6fb4895fd515180c175a49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AvailabilityTypeEnum = "Availability_NoPermanentWorkSchedule" | "Availability_TemporaryAvailability" | "%future added value";
export type CurrencyEnum = "aud" | "cad" | "eur" | "gbp" | "nzd" | "usd" | "%future added value";
export type EmploymentRateEditPastEnum = "disabled" | "enabled" | "%future added value";
export type EmploymentTypeFixedShiftRestrictionOptionEnum = "not_applicable" | "optional" | "required" | "%future added value";
export type SwapDropFilteringEnum = "default" | "jtc" | "%future added value";
export type SwapSortingEnum = "default" | "skill_level" | "%future added value";
export type BusinessInput = {
  accentColor1?: string | null;
  aggregateToStartDay?: boolean | null;
  allowManagerToApproveOwnPunch?: boolean | null;
  allowOnlyBusinessOwnersInWebui?: boolean | null;
  analyticsEnabled?: boolean | null;
  analyticsServerUrl?: string | null;
  anonymousShiftDropToUser?: boolean | null;
  anonymousShiftSwapToUser?: boolean | null;
  approvalNeededForCommendation?: boolean | null;
  approvalNeededForDisciplinaryAction?: boolean | null;
  approvalNeededForRoleRateChange?: boolean | null;
  approvalRequests?: ApprovalRequestsConfigInput | null;
  assignEmployeeConfirmationText?: string | null;
  autoAcceptRosteredShifts?: boolean | null;
  autoRejectTimeFromUnavailabilityStart?: AutoRejectTimeInput | null;
  autoRejectUnavailabilityEvents?: boolean | null;
  availabilityChangeDuringPublishedScheduleEnabled?: boolean | null;
  availabilityConsentRequired?: boolean | null;
  availabilityCoverageHorizonDays?: number | null;
  availabilityEmployeeComments?: boolean | null;
  availabilityErrorOnAnyShiftConflict?: boolean | null;
  availabilityErrorOnMinNoticeDays?: number | null;
  availabilityErrorOnRosteredShiftConflict?: boolean | null;
  availabilityManagerComments?: boolean | null;
  availabilityMaxCycleWeeks?: number | null;
  availabilityTypesEnabled?: ReadonlyArray<AvailabilityTypeEnum> | null;
  availabilityUseConsecutiveDaysOffWaived?: boolean | null;
  availabilityUseMaxDurationPerWeek?: boolean | null;
  availabilityWarnOnMinNoticeDays?: number | null;
  biometricDisclaimer?: string | null;
  biometricExpirationExemptJtcCodes?: ReadonlyArray<string> | null;
  biometricsExpirationDays?: any | null;
  breakTypeManagementEnabled?: boolean | null;
  businessName?: string | null;
  businessTerms?: any | null;
  canAdjustForecast?: boolean | null;
  canOverrideForecast?: boolean | null;
  chatEnabled?: boolean | null;
  clockInThreshold?: number | null;
  clockOutThreshold?: number | null;
  clockTrackingEnabled?: boolean | null;
  coBrandingEnabled?: boolean | null;
  commendationEmploymentNotifications?: boolean | null;
  commendationEnabled?: boolean | null;
  contractsConsentEnabled?: boolean | null;
  contractsUseEmploymentType?: boolean | null;
  contractsUseMinDurationPerWeek?: boolean | null;
  crossScheduleComplianceChecksEnabled?: boolean | null;
  csvReportDisclaimerEnabled?: boolean | null;
  currencyType?: CurrencyEnum | null;
  darAnalyticsSyncThreshold?: number | null;
  darDataOnlyPastSlicesEnabled?: boolean | null;
  darEnabled?: boolean | null;
  darFields?: ReadonlyArray<BusinessDarFieldInput> | null;
  dataLakeGlobalId?: string | null;
  dataLakeId?: string | null;
  datapassAffectingMetadata?: ReadonlyArray<string> | null;
  datapassTrackingEnabled?: boolean | null;
  dateFormat?: string | null;
  defaultPayrollCutoffTime?: string | null;
  defaultScheduleDayEndTime?: string | null;
  defaultScheduleDayStartTime?: string | null;
  defaultScheduleSnapshotDuration?: number | null;
  defaultScheduleSnapshotStartDate?: string | null;
  deletePrimaryJobTitlesEnabled?: boolean | null;
  disableEmployeeLeaveRequest?: boolean | null;
  disciplinaryActionEmploymentNotifications?: boolean | null;
  disciplinaryActionEnabled?: boolean | null;
  displayWarningUnavailabilityEvents?: boolean | null;
  dropMobileDisclaimer?: string | null;
  dropMobileDisclaimerEnabled?: boolean | null;
  durationFormat?: string | null;
  dynamicFieldsLayout?: DynamicFieldsLayoutInput | null;
  earlyShiftStartThreshold?: number | null;
  earningsEstimator?: string | null;
  emplTypeGmhSettings?: EmplTypeGmhSettingsUpdateInput | null;
  emplTypeTimeOffComplianceEnabled?: EmplTypeTimeOffComplianceEnabledInput | null;
  employeeAvailabilityEditableByManager?: boolean | null;
  employeeDraftsEnabled?: boolean | null;
  employeeMultiRateEnabled?: boolean | null;
  employmentCodePreprocessor?: any | null;
  employmentExternalManagedFlags?: EmploymentExternalManagedFlagsUpdateInput | null;
  employmentPerformanceReviewDetail?: EmploymentPerformanceReviewDetailInput | null;
  employmentPerformanceReviewEnabled?: boolean | null;
  employmentRateEditEnabled?: boolean | null;
  employmentRateEditPast?: EmploymentRateEditPastEnum | null;
  employmentTypeFixedShiftRestriction?: EmploymentTypeFixedShiftRestrictionUpdateInput | null;
  enableMiscPay?: boolean | null;
  enablePastShiftOperation?: boolean | null;
  enableShiftDifferentials?: boolean | null;
  feedbackCommentEnabled?: boolean | null;
  feedbackReasonEnabled?: boolean | null;
  firstDayOfWeek?: number | null;
  firstNameCharCount?: number | null;
  firstNameSuffix?: string | null;
  fixedShiftsBreakDurationEnabled?: boolean | null;
  fixedShiftsEnabled?: boolean | null;
  fixedShiftsRoleId?: string | null;
  footerDisclaimer?: string | null;
  forecastRequiresApproval?: boolean | null;
  globalBusinessId?: string | null;
  globalImageId?: string | null;
  graphqlQueryBatchingEnabled?: boolean | null;
  highlightOverstaffing?: boolean | null;
  homeStoreTransferManagerCommentsEnabled?: boolean | null;
  idleTimeoutEnabled?: boolean | null;
  idleTimeoutLength?: number | null;
  image?: string | null;
  inviteFooter?: string | null;
  inviteHeader?: string | null;
  inviteMessage?: string | null;
  inviteUserConfirmationText?: string | null;
  isFake?: boolean | null;
  jobTitlesEnabled?: boolean | null;
  kpiAvgHourlyRateEnabled?: boolean | null;
  kpiSalesPerEmployeeHourEnabled?: boolean | null;
  lastNameCharCount?: number | null;
  lastNameSuffix?: string | null;
  liveTimeClockViewEnabled?: boolean | null;
  localeSuffix?: string | null;
  mailerLocales?: any | null;
  managerAppEnabled?: boolean | null;
  managerAssignUsersToSchedulesEnabled?: boolean | null;
  managerInviteUsersEnabled?: boolean | null;
  managerTerminateUsersEnabled?: boolean | null;
  mandatoryReasonsForEmploymentStatus?: MandatoryReasonsForEmploymentStatusInput | null;
  markAsAbsentEnabled?: boolean | null;
  markAsAbsentEnabledToUser?: boolean | null;
  maxShiftDuration?: number | null;
  meetingMaxPerYear?: number | null;
  meetingMinimumDuration?: any | null;
  meetingTitles?: ReadonlyArray<string> | null;
  meetingsEnabled?: boolean | null;
  militaryTime?: boolean | null;
  minSharingAge?: number | null;
  monthlyCostLoadingEnabled?: boolean | null;
  nicknameReplacesOnlyFirstName?: boolean | null;
  notificationsOnTimeClockAppEnabled?: boolean | null;
  payCycleStartDate?: string | null;
  payDates?: ReadonlyArray<number> | null;
  payDisclaimer?: string | null;
  payFrequency?: string | null;
  payPeriodDailyReportSplitNames?: boolean | null;
  payPeriodReviewEnabled?: boolean | null;
  payPeriodSelfApprovalEnabled?: boolean | null;
  populateScheduleReplanningEnabled?: boolean | null;
  punchAdjustmentConsentEnabled?: boolean | null;
  punchEditEmployeeCommentsEnabled?: boolean | null;
  punchEditForceChangesLegalDisclaimerEnabled?: boolean | null;
  punchEditLegalDisclaimer?: string | null;
  punchEditManagerCommentsEnabled?: boolean | null;
  punchSlipMode?: string | null;
  punchTrackingEnabled?: boolean | null;
  quarterlyAggregationEnabled?: boolean | null;
  rateType?: any | null;
  registrationNumber?: string | null;
  regularBusinessInviteEnabled?: boolean | null;
  reportColumnsConfigs?: ReadonlyArray<ReportColumnsConfigInput> | null;
  reportDisclaimerEnabled?: boolean | null;
  reportDisclaimerText?: string | null;
  reportSettings?: any | null;
  restScreenTheme?: string | null;
  roleJobTitles?: any | null;
  rostered?: boolean | null;
  roundingStrategy?: string | null;
  scheduleWarningsFilterEnabled?: boolean | null;
  schoolCalendarsEnabled?: boolean | null;
  shareRequiresApproval?: boolean | null;
  sharedStoreShiftsEnabled?: boolean | null;
  shiftAutoAcceptNotificationEnabled?: boolean | null;
  shiftCanOverlapLeaveRequest?: boolean | null;
  shiftCanOverlapUnavailability?: boolean | null;
  shiftCostBreakdownEnabled?: boolean | null;
  shiftDropEnabled?: boolean | null;
  shiftDropThreshold?: number | null;
  shiftMultiRateEnabled?: boolean | null;
  shiftSwapEnabled?: boolean | null;
  shiftSwapNeedsApproval?: boolean | null;
  shortDescription?: string | null;
  showAvatarIcon?: boolean | null;
  showBreaksToUser?: boolean | null;
  showColleaguesToUser?: boolean | null;
  showEarnings?: boolean | null;
  showEarningsToUser?: boolean | null;
  showNickname?: boolean | null;
  showShiftRolesToUser?: boolean | null;
  showSystemRolesAndPermissions?: boolean | null;
  ssoBusinessInviteEnabled?: boolean | null;
  storeHoursEnabled?: boolean | null;
  storeTransferOnHireDateEnabled?: boolean | null;
  swapDropFiltering?: SwapDropFilteringEnum | null;
  swapMobileDisclaimer?: string | null;
  swapMobileDisclaimerEnabled?: boolean | null;
  swapSorting?: SwapSortingEnum | null;
  targetsScreenEnabled?: boolean | null;
  terminateUserConfirmationText?: string | null;
  timeClockAppBusinessImageUri?: string | null;
  timeClockAppFetchScheduleJobTitles?: boolean | null;
  timeClockAppMinTimeBetweenBreaks?: any | null;
  timeClockAppPinConfig?: TimeClockAppPinConfigInput | null;
  timeClockAppSyncIntervalInSeconds?: number | null;
  timeClockAppWorkflowConfig?: any | null;
  timeClockRoleRateChangeAuthThreshold?: number | null;
  timeOffDuringPublishedScheduleEnabled?: boolean | null;
  timeOffEmployeeCommentsEnabled?: boolean | null;
  timeOffManagerCommentsEnabled?: boolean | null;
  timeOffRequestDuringBlackoutEnabled?: boolean | null;
  timeOffShiftNotificationsEnabled?: boolean | null;
  timeOffShiftsEnabled?: boolean | null;
  timekeepingDefaultToWeekView?: boolean | null;
  timekeepingPrepopulateNewEntry?: boolean | null;
  timekeepingReadonly?: boolean | null;
  timekeepingRolesVisible?: boolean | null;
  timeoutThreshold?: number | null;
  topLevelForecastMeasurements?: ReadonlyArray<AnalyticsTopLevelMeasurementInput> | null;
  unassignEmployeeConfirmationText?: string | null;
  unassignedShiftDefaultRateEnabled?: boolean | null;
  unshareOnTermination?: boolean | null;
  useLegacyActuals?: boolean | null;
  voluntaryLateClockOutEnabled?: boolean | null;
  warningCodeMap?: any | null;
  warningTimeFromUnavailabilityStart?: AutoWarnTimeInput | null;
  webuiViewOptions?: any | null;
  workStartDateEnabled?: boolean | null;
};
export type ApprovalRequestsConfigInput = {
  employmentPerformanceReview?: EmploymentPerformanceReviewRequestConfigInput | null;
  employmentRateChange?: EmploymentRateChangeRequestConfigInput | null;
};
export type EmploymentPerformanceReviewRequestConfigInput = {
  autoApprovalEnabled?: boolean | null;
};
export type EmploymentRateChangeRequestConfigInput = {
  autoApprovalEnabled?: boolean | null;
};
export type AutoRejectTimeInput = {
  unit?: string | null;
  value?: number | null;
};
export type BusinessDarFieldInput = {
  description?: string | null;
  fieldCode: string;
  name: string;
  valueType: string;
};
export type DynamicFieldsLayoutInput = {
  groups: ReadonlyArray<DynamicFieldsLayoutGroupInput>;
  views: ReadonlyArray<DynamicFieldsLayoutViewInput>;
};
export type DynamicFieldsLayoutGroupInput = {
  fields: ReadonlyArray<DynamicFieldsLayoutGroupFieldInput>;
  label: string;
  name: string;
};
export type DynamicFieldsLayoutGroupFieldInput = {
  metadataTypeName: string;
  width: number;
};
export type DynamicFieldsLayoutViewInput = {
  groups: ReadonlyArray<string>;
  name: string;
};
export type EmplTypeGmhSettingsUpdateInput = {
  casual?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null;
  fullTime?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null;
  partTime?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null;
  permanent?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null;
};
export type EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput = {
  enabled?: boolean | null;
  minimumDurationError?: any | null;
  minimumDurationWarning?: any | null;
};
export type EmplTypeTimeOffComplianceEnabledInput = {
  casual: boolean;
  fullTime: boolean;
  partTime: boolean;
  permanent: boolean;
};
export type EmploymentExternalManagedFlagsUpdateInput = {
  availability?: boolean | null;
  contract?: boolean | null;
  hireHistory?: boolean | null;
  homeStore?: boolean | null;
  jtc?: boolean | null;
  nickname?: boolean | null;
  personalDetails?: boolean | null;
  roleAndRate?: boolean | null;
  school?: boolean | null;
  sharedStore?: boolean | null;
  skillLevel?: boolean | null;
  timeOff?: boolean | null;
};
export type EmploymentPerformanceReviewDetailInput = {
  adhocReviewEnabled?: boolean | null;
  adjustJtc?: boolean | null;
  autoPublish?: boolean | null;
  initialReviewEnabled?: boolean | null;
  initialReviewPeriodMonths?: any | null;
  regularReviewPeriodMonths?: any | null;
  regularReviewStartDate?: any | null;
  reviewDueReminderDays?: any | null;
};
export type EmploymentTypeFixedShiftRestrictionUpdateInput = {
  casual?: EmploymentTypeFixedShiftRestrictionOptionEnum | null;
  fullTime?: EmploymentTypeFixedShiftRestrictionOptionEnum | null;
  partTime?: EmploymentTypeFixedShiftRestrictionOptionEnum | null;
  permanent?: EmploymentTypeFixedShiftRestrictionOptionEnum | null;
};
export type MandatoryReasonsForEmploymentStatusInput = {
  active?: boolean | null;
  leaveOfAbsence?: boolean | null;
  suspended?: boolean | null;
  terminated?: boolean | null;
};
export type ReportColumnsConfigInput = {
  categories: ReadonlyArray<ReportCategoryInput>;
  reportName: string;
};
export type ReportCategoryInput = {
  columns: ReadonlyArray<ReportColumnInput>;
  name: string;
};
export type ReportColumnInput = {
  displayName: string;
  name: string;
};
export type TimeClockAppPinConfigInput = {
  max: number;
  min: number;
};
export type AnalyticsTopLevelMeasurementInput = {
  isCurrency: boolean;
  isPrimary: boolean;
  label: string;
  name: string;
};
export type AutoWarnTimeInput = {
  unit?: string | null;
  value?: number | null;
};
export type PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation$variables = {
  id: string;
  input: BusinessInput;
};
export type PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation$data = {
  readonly updateBusiness: {
    readonly approvalNeededForCommendation: boolean;
    readonly approvalNeededForDisciplinaryAction: boolean;
    readonly approvalRequests: {
      readonly employmentPerformanceReview: {
        readonly autoApprovalEnabled: boolean;
      };
    };
    readonly commendationEmploymentNotifications: boolean;
    readonly commendationEnabled: boolean;
    readonly disciplinaryActionEmploymentNotifications: boolean;
    readonly disciplinaryActionEnabled: boolean;
    readonly employmentPerformanceReviewDetail: {
      readonly adhocReviewEnabled: boolean;
      readonly adjustJtc: boolean;
      readonly autoPublish: boolean;
      readonly initialReviewEnabled: boolean;
      readonly initialReviewPeriodMonths: number;
      readonly regularReviewPeriodMonths: number;
      readonly regularReviewStartDate: any;
      readonly reviewDueReminderDays: any;
    };
    readonly employmentPerformanceReviewEnabled: boolean;
    readonly feedbackCommentEnabled: boolean;
    readonly feedbackReasonEnabled: boolean;
    readonly id: string;
  };
};
export type PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation = {
  response: PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation$data;
  variables: PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Business",
    "kind": "LinkedField",
    "name": "updateBusiness",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentPerformanceReviewEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentPerformanceReviewDetail",
        "kind": "LinkedField",
        "name": "employmentPerformanceReviewDetail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "autoPublish",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adjustJtc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reviewDueReminderDays",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regularReviewStartDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regularReviewPeriodMonths",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "initialReviewEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "initialReviewPeriodMonths",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adhocReviewEnabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApprovalRequestsConfig",
        "kind": "LinkedField",
        "name": "approvalRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentPerformanceReviewRequestConfig",
            "kind": "LinkedField",
            "name": "employmentPerformanceReview",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "autoApprovalEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feedbackReasonEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feedbackCommentEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commendationEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "approvalNeededForCommendation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commendationEmploymentNotifications",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disciplinaryActionEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "approvalNeededForDisciplinaryAction",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disciplinaryActionEmploymentNotifications",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "da4958c20144a15e4f984a85183dc9a0",
    "id": null,
    "metadata": {},
    "name": "PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation(\n  $input: BusinessInput!\n  $id: ID!\n) {\n  updateBusiness(id: $id, input: $input) {\n    id\n    employmentPerformanceReviewEnabled\n    employmentPerformanceReviewDetail {\n      autoPublish\n      adjustJtc\n      reviewDueReminderDays\n      regularReviewStartDate\n      regularReviewPeriodMonths\n      initialReviewEnabled\n      initialReviewPeriodMonths\n      adhocReviewEnabled\n    }\n    approvalRequests {\n      employmentPerformanceReview {\n        autoApprovalEnabled\n      }\n    }\n    feedbackReasonEnabled\n    feedbackCommentEnabled\n    commendationEnabled\n    approvalNeededForCommendation\n    commendationEmploymentNotifications\n    disciplinaryActionEnabled\n    approvalNeededForDisciplinaryAction\n    disciplinaryActionEmploymentNotifications\n  }\n}\n"
  }
};
})();

(node as any).hash = "2892a46580af37d56869ed47cf9e7493";

export default node;
