/**
 * @generated SignedSource<<401f14ff5bc599238627c240485376e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SSOAuthMethodUpdateInput = {
  authType?: string | null;
  code?: string | null;
  corporateId?: string | null;
  customConfigMeta?: any | null;
  displayName?: string | null;
  employmentAttrToSearch?: string | null;
  employmentIdAttributes?: ReadonlyArray<string> | null;
  employmentScheduleAttributes?: ReadonlyArray<string> | null;
  metadataConfig?: string | null;
  metadataIssuer?: string | null;
  metadataOverrides?: any | null;
  metadataUrl?: string | null;
  permissionMapping?: any | null;
  schedulePermissionAttributes?: ReadonlyArray<string> | null;
};
export type CorporatesAuthMethodsQueries_UpdateAuthMethod_Mutation$variables = {
  id: string;
  input: SSOAuthMethodUpdateInput;
};
export type CorporatesAuthMethodsQueries_UpdateAuthMethod_Mutation$data = {
  readonly updateSsoAuthMethod: {
    readonly authType: string;
    readonly code: string;
    readonly corporateId: string;
    readonly customConfigMeta: any;
    readonly displayName: string;
    readonly employmentAttrToSearch: string;
    readonly employmentIdAttributes: ReadonlyArray<string>;
    readonly employmentScheduleAttributes: ReadonlyArray<string> | null;
    readonly id: string;
    readonly metadataConfig: string | null;
    readonly metadataIssuer: string | null;
    readonly metadataOverrides: any | null;
    readonly metadataUrl: string | null;
    readonly permissionMapping: any | null;
    readonly schedulePermissionAttributes: ReadonlyArray<string> | null;
    readonly updatedAt: string;
  };
};
export type CorporatesAuthMethodsQueries_UpdateAuthMethod_Mutation = {
  response: CorporatesAuthMethodsQueries_UpdateAuthMethod_Mutation$data;
  variables: CorporatesAuthMethodsQueries_UpdateAuthMethod_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SSOAuthMethod",
    "kind": "LinkedField",
    "name": "updateSsoAuthMethod",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataIssuer",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataConfig",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataOverrides",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentAttrToSearch",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentIdAttributes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentScheduleAttributes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schedulePermissionAttributes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissionMapping",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "corporateId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customConfigMeta",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesAuthMethodsQueries_UpdateAuthMethod_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CorporatesAuthMethodsQueries_UpdateAuthMethod_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e8f08095c50c715970e602bb99f952b5",
    "id": null,
    "metadata": {},
    "name": "CorporatesAuthMethodsQueries_UpdateAuthMethod_Mutation",
    "operationKind": "mutation",
    "text": "mutation CorporatesAuthMethodsQueries_UpdateAuthMethod_Mutation(\n  $id: ID!\n  $input: SSOAuthMethodUpdateInput!\n) {\n  updateSsoAuthMethod(id: $id, input: $input) {\n    id\n    displayName\n    code\n    authType\n    metadataIssuer\n    metadataUrl\n    metadataConfig\n    metadataOverrides\n    employmentAttrToSearch\n    employmentIdAttributes\n    employmentScheduleAttributes\n    schedulePermissionAttributes\n    permissionMapping\n    corporateId\n    customConfigMeta\n    updatedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "bacc36a62c276452e2b3d3f101399a30";

export default node;
