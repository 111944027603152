import { graphql } from "react-relay";

export const GetBusinessPeopleSettingsQuery = graphql`
  query PeopleSettingsQueries_GetBusinessPeopleSettings_Query($ids: [ID!]) {
    businesses(ids: $ids) {
      nodes {
        ### Replaceable content start
        employmentExternalManagedFlags {
          availability
          contract
          hireHistory
          homeStore
          sharedStore
          jtc
          nickname
          personalDetails
          roleAndRate
          school
          skillLevel
          timeOff
        }
        employeeDraftsEnabled
        workStartDateEnabled
        contractsUseEmploymentType
        contractsConsentEnabled
        contractsUseMinDurationPerWeek
        disableEmployeeLeaveRequest
        timeOffManagerCommentsEnabled
        timeOffEmployeeCommentsEnabled
        displayWarningUnavailabilityEvents
        warningTimeFromUnavailabilityStart {
          value
          unit
        }
        autoRejectUnavailabilityEvents
        autoRejectTimeFromUnavailabilityStart {
          value
          unit
        }
        timeOffShiftsEnabled
        timeOffShiftNotificationsEnabled
        timeOffRequestDuringBlackoutEnabled
        timeOffDuringPublishedScheduleEnabled
        employeeAvailabilityEditableByManager
        availabilityWarnOnMinNoticeDays
        availabilityErrorOnMinNoticeDays
        availabilityEmployeeComments
        availabilityManagerComments
        availabilityTypesEnabled
        availabilityConsentRequired
        availabilityUseMaxDurationPerWeek
        availabilityMaxCycleWeeks
        availabilityErrorOnRosteredShiftConflict
        availabilityErrorOnAnyShiftConflict
        availabilityCoverageHorizonDays
        availabilityUseConsecutiveDaysOffWaived
        availabilityChangeDuringPublishedScheduleEnabled
        fixedShiftsEnabled
        fixedShiftsRoleId
        fixedShiftsBreakDurationEnabled
        managerTerminateUsersEnabled
        unshareOnTermination
        mandatoryReasonsForEmploymentStatus {
          terminated
        }
        datapassTrackingEnabled
        datapassAffectingMetadata
        homeStoreTransferManagerCommentsEnabled
        storeTransferOnHireDateEnabled
        shareRequiresApproval
        minSharingAge
        ### Replaceable content finish
      }
    }
  }
`;

export const UpdateBusinessPeopleSettingsMutation = graphql`
  mutation PeopleSettingsQueries_UpdateBusinessPeopleSettings_Mutation(
    $input: BusinessInput!
    $id: ID!
  ) {
    updateBusiness(id: $id, input: $input) {
      ### Replaceable content start
      employmentExternalManagedFlags {
        availability
        contract
        hireHistory
        homeStore
        sharedStore
        jtc
        nickname
        personalDetails
        roleAndRate
        school
        skillLevel
        timeOff
      }
      employeeDraftsEnabled
      workStartDateEnabled
      contractsUseEmploymentType
      contractsConsentEnabled
      contractsUseMinDurationPerWeek
      disableEmployeeLeaveRequest
      timeOffManagerCommentsEnabled
      timeOffEmployeeCommentsEnabled
      displayWarningUnavailabilityEvents
      warningTimeFromUnavailabilityStart {
        value
        unit
      }
      autoRejectUnavailabilityEvents
      autoRejectTimeFromUnavailabilityStart {
        value
        unit
      }
      timeOffShiftsEnabled
      timeOffShiftNotificationsEnabled
      timeOffRequestDuringBlackoutEnabled
      timeOffDuringPublishedScheduleEnabled
      employeeAvailabilityEditableByManager
      availabilityWarnOnMinNoticeDays
      availabilityErrorOnMinNoticeDays
      availabilityEmployeeComments
      availabilityManagerComments
      availabilityTypesEnabled
      availabilityConsentRequired
      availabilityUseMaxDurationPerWeek
      availabilityMaxCycleWeeks
      availabilityErrorOnRosteredShiftConflict
      availabilityErrorOnAnyShiftConflict
      availabilityCoverageHorizonDays
      availabilityUseConsecutiveDaysOffWaived
      availabilityChangeDuringPublishedScheduleEnabled
      fixedShiftsEnabled
      fixedShiftsRoleId
      fixedShiftsBreakDurationEnabled
      managerTerminateUsersEnabled
      unshareOnTermination
      mandatoryReasonsForEmploymentStatus {
        terminated
      }
      datapassTrackingEnabled
      datapassAffectingMetadata
      homeStoreTransferManagerCommentsEnabled
      storeTransferOnHireDateEnabled
      shareRequiresApproval
      minSharingAge
      ### Replaceable content finish
    }
  }
`;

export const GetBusinessRolesQuery = graphql`
  query PeopleSettingsQueries_GetBusinessRoles_Query($businessId: ID!) {
    businessRoles(businessId: $businessId) {
      nodes {
        id
        businessRoleName
        replaceByAos
      }
    }
  }
`;

export const GetBusinessMetadataTypesQuery = graphql`
  query PeopleSettingsQueries_GetBusinessMetadataTypes_Query($businessId: ID!) {
    metadataTypes(businessId: $businessId) {
      nodes {
        id
        displayName
        name
        dataType
        encrypted
        external
        internalAccess
      }
    }
  }
`;
