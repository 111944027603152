import React, { useEffect, useState } from "react";
import { fetchQuery, graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import { DateTime } from "luxon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import keyBy from "lodash/keyBy";
import PayPeriodTable, {
  Employee,
  PayPeriodApprovalStatus,
  payPeriodDefaultSort,
} from "./PayPeriodTable";
import DateTimePicker from "../../common/Form/DateTimePicker";
import DynamicSelect from "../../common/Form/DynamicSelect";
import {
  ScheduleContextValue,
  useScheduleContext,
} from "../../../contexts/ScheduleContext";
import { PayPeriods_Employees_Query } from "./__generated__/PayPeriods_Employees_Query.graphql";
import { Id } from "../../../data/models/common";
import Loader from "../../common/Loader";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import { useBusinessContext } from "../../../contexts/BusinessContext";

const PayPeriodsQuery = graphql`
  query PayPeriods_InternalQuery(
    $pageSize: Int!
    $after: String
    $businessId: ID!
    $scheduleId: ID!
    $payPeriodStatus: PayPeriodApprovalStatus
    $startDateTime: ISO8601DateTime!
    $endDateTime: ISO8601DateTime!
    $sort: [PayPeriodSort!]
  ) {
    ...PayPeriodTable_viewer
  }
`;

const EmployeesQuery = graphql`
  query PayPeriods_Employees_Query($businessId: ID!, $ids: [ID!]) {
    employments(businessId: $businessId, ids: $ids) {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

interface MatchParams {
  schedule_id: string;
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

type ISO8601DateTime = string;

export default function PayPeriods(props: Props) {
  const scheduleContext = useScheduleContext();
  const businessContext = useBusinessContext();

  const [payPeriodStatus, setPayPeriodStatus] =
    useState<PayPeriodApprovalStatus | null>(null);
  const [startDateTime, setStartDateTime] = useState<ISO8601DateTime>(
    new Date().toISOString(),
  );
  const [endDateTime, setEndDateTime] = useState<ISO8601DateTime>(
    new Date().toISOString(),
  );
  const [employments, setEmployments] = useState<
    Map<Id, Employee | null | undefined>
  >(new Map());

  const { schedule, getDayStart, getDayEnd } =
    scheduleContext as ScheduleContextValue;
  const { environment } = businessContext;
  const { business_id: businessId, schedule_id: scheduleId } =
    props.match.params;

  const fetchEmployees = async (employmentIds: Id[]) => {
    const result = await fetchQuery<PayPeriods_Employees_Query>(
      environment,
      EmployeesQuery,
      {
        businessId,
        ids: employmentIds,
      },
    ).toPromise();

    const employmentsById = keyBy(result?.employments.edges || [], (i) =>
      i && i.node ? i.node.id : "",
    );

    setEmployments(
      employmentIds.reduce((r, i) => {
        const employment = employmentsById[i];
        r.set(i, employment ? employment.node : null);
        return r;
      }, new Map(employments)),
    );
  };

  useEffect(() => {
    const startDate = getDayStart(DateTime.local());
    const endDate = startDate ? getDayEnd(startDate) : DateTime.local();

    setStartDateTime(startDate.minus({ month: 2 }).toISO());
    setEndDateTime(endDate.toISO());
  }, [getDayStart, getDayEnd]);

  const properties = props;

  if (!environment) {
    return null;
  }

  const startJSDate = DateTime.fromISO(startDateTime).toJSDate();
  const endJSDate = DateTime.fromISO(endDateTime).toJSDate();

  return (
    <Card body>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <span className="ml-2 mr-2">&gt;</span>
        {/* TODO: i18n */}
        <span>Pay Periods</span>
      </HeaderPortal>
      <Row className="mb-1">
        <Col lg={2}>
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <DateTimePicker
              timezone={schedule?.timeZone || undefined}
              fieldKey="startTime"
              displayFormat="dd MMM yyyy"
              value={startDateTime}
              onChange={(newValue: any) => {
                setStartDateTime((newValue || "") as string);
              }}
              selectsStart
              startDate={startJSDate}
              endDate={endJSDate}
              maxDate={endJSDate}
            />
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <DateTimePicker
              fieldKey="endTime"
              displayFormat="dd MMM yyyy"
              value={endDateTime}
              onChange={(newValue: any) => {
                setEndDateTime((newValue || "") as string);
              }}
              selectsEnd
              startDate={startJSDate}
              endDate={endJSDate}
              minDate={startJSDate}
              timezone={schedule?.timeZone || undefined}
            />
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <DynamicSelect<PayPeriodApprovalStatus>
              options={[
                { label: "All", value: PayPeriodApprovalStatus.ALL },
                { label: "Open", value: PayPeriodApprovalStatus.OPEN },
                {
                  label: "Approved",
                  value: PayPeriodApprovalStatus.APPROVED,
                },
                { label: "Posted", value: PayPeriodApprovalStatus.POSTED },
                {
                  label: "Processed",
                  value: PayPeriodApprovalStatus.PROCESSED,
                },
              ]}
              value={
                payPeriodStatus != null
                  ? payPeriodStatus
                  : PayPeriodApprovalStatus.ALL
              }
              name="pay-period-status"
              onChange={(
                newValue: PayPeriodApprovalStatus | null | undefined,
              ) => {
                if (
                  newValue === PayPeriodApprovalStatus.ALL ||
                  newValue == null
                ) {
                  newValue = null;
                }
                setPayPeriodStatus(newValue);
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <QueryRenderer
        environment={environment}
        query={PayPeriodsQuery}
        variables={{
          businessId,
          scheduleId,
          payPeriodStatus,
          startDateTime,
          endDateTime,
          pageSize: 10,
          sort: payPeriodDefaultSort,
        }}
        render={({ error, props: queryProps }) => {
          if (error) {
            return <div>Error!</div>;
          }
          if (!queryProps) {
            return <Loader />;
          }

          return (
            <PayPeriodTable
              {...properties}
              payPeriodStatus={payPeriodStatus}
              startDateTime={startDateTime}
              endDateTime={endDateTime}
              viewer={queryProps as any}
              employments={employments}
              onNewEmploymentsFound={(ids) => {
                fetchEmployees(ids);
              }}
            />
          );
        }}
      />
    </Card>
  );
}
