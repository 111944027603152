import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PreloadedQuery } from "react-relay";
import Card from "react-bootstrap/Card";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../common/Portal/HeaderPortal";
import BreakTypesProfileForm from "./BreakTypesProfileForm/BreakTypesProfileForm";
import { useAppRouter } from "../../../../hooks/useAppRouter";

type MatchParams = {
  break_type_id: string;
  schedule_break_type_id?: string;
};

type Props = {
  initialQueryRef: PreloadedQuery<any>;
};

export default function BreakTypesProfile({ initialQueryRef }: Props) {
  const { t } = useTranslation("break-types");

  const {
    params: {
      break_type_id: breakTypeId,
      schedule_break_type_id: scheduleBreakTypeId,
    },
  } = useAppRouter<MatchParams>();

  return (
    <Suspense
      fallback={
        <HeaderPortal as="span" elementId="sub-header-portal">
          <HeaderPortalBreadcrumbs
            breadcrumbs={[
              <Link to="#">
                <span>{t("nav.breakTypes")}</span>
              </Link>,
            ]}
          />
        </HeaderPortal>
      }
    >
      <Card body>
        <BreakTypesProfileForm
          breakTypeId={breakTypeId}
          scheduleBreakTypeId={scheduleBreakTypeId}
        />
      </Card>
    </Suspense>
  );
}
