import React, { FunctionComponent } from "react";
import { Environment, graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Profile from "./Profile";
import Loader from "../../common/Loader";
import {
  GlobalBusinessProfile_Query,
  GlobalBusinessProfile_Query$data,
} from "./__generated__/GlobalBusinessProfile_Query.graphql";

const GlobalBusinessProfileQuery = graphql`
  query GlobalBusinessProfile_Query($globalBusinessId: ID) {
    globalBusinesses(globalBusinessId: $globalBusinessId) {
      edges {
        node {
          ...Profile_globalBusiness
        }
      }
    }
  }
`;

export type GlobalBusiness =
  GlobalBusinessProfile_Query$data["globalBusinesses"]["edges"][number]["node"];

interface MatchParams {
  stack_id: string;
  global_business_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {
  environment: Environment;
};

const GlobalBusinessProfile: FunctionComponent<Props> = (props: Props) => {
  const { match, environment, ...rest } = props;
  const {
    params: { global_business_id: corporateId },
  } = match;

  const getProfile = (item: GlobalBusiness | null) => {
    return (
      <Card body>
        <Profile
          {...rest}
          match={match}
          globalBusiness={item}
          environment={environment}
        />
      </Card>
    );
  };

  if (corporateId == null) {
    // create new
    return getProfile(null);
  }

  return (
    <QueryRenderer<GlobalBusinessProfile_Query>
      environment={environment}
      query={GlobalBusinessProfileQuery}
      variables={{
        globalBusinessId: corporateId,
      }}
      render={({ error, props: queryProps }) => {
        if (error) {
          return <div>Error!</div>;
        }
        if (!queryProps) {
          return <Loader />;
        }

        return getProfile(queryProps.globalBusinesses.edges[0]?.node ?? null);
      }}
    />
  );
};

export default GlobalBusinessProfile;
