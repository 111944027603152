import React, { useEffect } from "react";
import { PreloadedQuery, useQueryLoader } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Advanced from "./Advanced";
import { useBusinessContext } from "../../../../../contexts/BusinessContext";
import Loader from "../../../../common/Loader";
import { AdvancedProfileQuery } from "../AosQueries";

interface MatchParams {
  business_id: string;
  schedule_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  initialQueryRef: PreloadedQuery<any>;
};

export default function AdvancedProfile(props: Props) {
  const { environment } = useBusinessContext();

  const { match } = props;
  const { params } = match;
  const { schedule_id: scheduleId, business_id: businessId } = params;

  const [queryReference, loadScheduleQuery, disposeScheduleQuery] =
    useQueryLoader(AdvancedProfileQuery, props.initialQueryRef);

  useEffect(() => {
    loadScheduleQuery({
      scheduleId,
      businessId,
    });
    return () => {
      disposeScheduleQuery();
    };
  }, [scheduleId, businessId, disposeScheduleQuery, loadScheduleQuery]);

  return (
    <React.Suspense fallback={<Loader />}>
      {queryReference != null && (
        <Advanced
          {...props}
          match={match}
          environment={environment}
          queryReference={queryReference}
        />
      )}
    </React.Suspense>
  );
}
