/**
 * @generated SignedSource<<ab5f8aa741b7b6bc89d93b275bd67a6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SSOAuthMethodCreateInput = {
  authType?: string | null;
  code?: string | null;
  corporateId?: string | null;
  customConfigMeta?: any | null;
  displayName?: string | null;
  employmentAttrToSearch?: string | null;
  employmentIdAttributes?: ReadonlyArray<string> | null;
  employmentScheduleAttributes?: ReadonlyArray<string> | null;
  metadataConfig?: string | null;
  metadataIssuer?: string | null;
  metadataOverrides?: any | null;
  metadataUrl?: string | null;
  permissionMapping?: any | null;
  schedulePermissionAttributes?: ReadonlyArray<string> | null;
};
export type CorporatesAuthMethodsQueries_CreateAuthMethod_Mutation$variables = {
  input: SSOAuthMethodCreateInput;
};
export type CorporatesAuthMethodsQueries_CreateAuthMethod_Mutation$data = {
  readonly createSsoAuthMethod: {
    readonly authType: string;
    readonly code: string;
    readonly corporateId: string;
    readonly customConfigMeta: any;
    readonly displayName: string;
    readonly employmentAttrToSearch: string;
    readonly employmentIdAttributes: ReadonlyArray<string>;
    readonly employmentScheduleAttributes: ReadonlyArray<string> | null;
    readonly id: string;
    readonly metadataConfig: string | null;
    readonly metadataIssuer: string | null;
    readonly metadataOverrides: any | null;
    readonly metadataUrl: string | null;
    readonly permissionMapping: any | null;
    readonly schedulePermissionAttributes: ReadonlyArray<string> | null;
    readonly updatedAt: string;
  };
};
export type CorporatesAuthMethodsQueries_CreateAuthMethod_Mutation = {
  response: CorporatesAuthMethodsQueries_CreateAuthMethod_Mutation$data;
  variables: CorporatesAuthMethodsQueries_CreateAuthMethod_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SSOAuthMethod",
    "kind": "LinkedField",
    "name": "createSsoAuthMethod",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataIssuer",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataConfig",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataOverrides",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentAttrToSearch",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentIdAttributes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentScheduleAttributes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schedulePermissionAttributes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissionMapping",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "corporateId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customConfigMeta",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesAuthMethodsQueries_CreateAuthMethod_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CorporatesAuthMethodsQueries_CreateAuthMethod_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "20ae97991a98aa3b9b3eef6ac393f026",
    "id": null,
    "metadata": {},
    "name": "CorporatesAuthMethodsQueries_CreateAuthMethod_Mutation",
    "operationKind": "mutation",
    "text": "mutation CorporatesAuthMethodsQueries_CreateAuthMethod_Mutation(\n  $input: SSOAuthMethodCreateInput!\n) {\n  createSsoAuthMethod(input: $input) {\n    id\n    displayName\n    code\n    authType\n    metadataIssuer\n    metadataUrl\n    metadataConfig\n    metadataOverrides\n    employmentAttrToSearch\n    employmentIdAttributes\n    employmentScheduleAttributes\n    schedulePermissionAttributes\n    permissionMapping\n    corporateId\n    customConfigMeta\n    updatedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "b1f449209c0616f0ec4eb6c5bd6d068e";

export default node;
