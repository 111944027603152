import React, { useState } from "react";
import isFunction from "lodash/isFunction";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useTranslation } from "react-i18next";
import { Id } from "../../../data/models/common";
import DeleteGlobalBusinessMutation from "../mutations/DeleteGlobalBusinessMutation";
import ServerError from "../../../utils/server-error";
import { useAppContext } from "../../IDM/external/Context/AppContext";
import { getRegionalStackEnvironment } from "../../../environment";

type Corporate = {
  name: string;
  id: Id;
};

type Props = {
  onDeletedCallback?: () => void;
  item: Corporate | null;
  hideModal: () => void;
};

const GlobalBusinessConfirmDeleteModal: React.FunctionComponent<Props> = ({
  item,
  onDeletedCallback,
  hideModal,
}) => {
  const { t } = useTranslation("global-business");
  const { stack } = useAppContext();

  const [error, setError] = useState<string>();
  if (!item) {
    return null;
  }

  const handleDelete = () => {
    if (!stack) {
      return;
    }
    DeleteGlobalBusinessMutation(
      getRegionalStackEnvironment(stack.domainName),
      item.id,
      () => {
        hideModal();
        if (isFunction(onDeletedCallback)) {
          onDeletedCallback();
        }
      },
      (e: Error) => {
        const { source } = (e as any) || {};
        if (!source) {
          return;
        }
        const serverError = new ServerError(source);
        setError(serverError.getErrorDetails());
      },
    );
  };

  const renderError = () => {
    return error ? (
      <Form.Control.Feedback type="invalid" className="d-block">
        {error}
      </Form.Control.Feedback>
    ) : null;
  };

  const cancelModal = () => {
    setError(undefined);
    hideModal();
  };

  return (
    <Modal show onHide={cancelModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t("deleteConfirmation.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t("deleteConfirmation.body", {
            name: item.name,
          })}
          {renderError()}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelModal}>
          {t("deleteConfirmation.cancel")}
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          {t("deleteConfirmation.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GlobalBusinessConfirmDeleteModal;
