import React from "react";
import Row from "react-bootstrap/Row";
import Field from "../../../common/Form/Field";
import { DayOfWeekEnum, Option } from "../../../../data/models/common";
import WeekTable from "../../../common/Form/WeekTable";
import { FloatFieldType } from "../../../common/Form/models";

type Props = {
  value: number[];
  fieldKey: string;
  disabled?: boolean;
  onChange: (newValue: number[] | null) => void;
  hideError?: boolean;
};

export const LaborCostOptimization: React.FunctionComponent<Props> = (
  p: Props,
) => {
  const { disabled, fieldKey, hideError } = p;

  return (
    <WeekTable startDay={DayOfWeekEnum.Sunday}>
      {(day: Option<string>, index: number) => (
        <Row>
          <Field
            md={12}
            lg={12}
            key={day.value}
            disabled={disabled}
            fieldKey={`${fieldKey}[${index}]`}
            schemaFieldType={FloatFieldType}
            postfix="%"
            size="sm"
            hideError={hideError}
          />
        </Row>
      )}
    </WeekTable>
  );
};

export default LaborCostOptimization;
