import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import styled, { useTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import Form from "react-bootstrap/Form";
import Checkbox from "../../../../common/Form/Checkbox";
import {
  EmploymentMetadata,
  MetadataType,
  MetadataTypeDataTypeEnum,
} from "../../../../../data/generated/stack_internal_schema";
import { formatDate } from "../../../../../utils/utility";
import { Pillbox, PillboxVariantEnum } from "../../../../common/Pillbox";
import { MetadataUtility } from "../MetadataUtility";
import { EmployeeHomeStore } from "../EmploymentMetadataQueries";

export type TimeboxedTableRowData = {
  metadataType: MetadataType;
  employmentMetadata?: EmploymentMetadata;
  employeeHomeStore: EmployeeHomeStore;
};

type Props = {
  data: {
    employmentMetadata: EmploymentMetadata[];
    metadataType: MetadataType;
    employeeHomeStore: EmployeeHomeStore;
  };
  onEdit?: (data: TimeboxedTableRowData) => void;
  onAdd?: (data: TimeboxedTableRowData) => void;
  isReadonly?: boolean;
};

const StyledMetadataTypeTableName = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  // cannot be i18n'd because the metadata type name is dynamic
  text-transform: uppercase;
`;

export const StyledTable = styled(Table)`
  table-layout: fixed;
  word-wrap: break-word;
  border: 0;

  thead > tr {
    border: 0.03em solid ${(props) => props.theme.grey300};
    th {
      padding: 6px;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;
      border: 0;
    }
  }
  tbody > tr {
    overflow: hidden;
    border: 0.03em solid ${(props) => props.theme.grey300};
    border-top: 0;

    td {
      padding: 6px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      border: 0;
      // truncate - two lines max
      &.timeboxed-value {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        max-height: 45px;
        overflow: hidden;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
  tfoot {
    border: none;
  }
`;

export function TimeboxedDataTable({
  data: { employmentMetadata, metadataType, employeeHomeStore },
  onEdit,
  onAdd,
  isReadonly = false,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation("employment");
  const [showHistory, setShowHistory] = useState(false);

  const tableName = MetadataUtility.getDisplayName(metadataType);

  const onShowHistoryChanged = () => {
    setShowHistory(!showHistory);
  };

  const getEffectiveDateDescription = (employmentMeta: EmploymentMetadata) => {
    const zone = employeeHomeStore?.schedule?.timeZone ?? "utc";
    const endTime = employmentMeta.endTime
      ? DateTime.fromISO(employmentMeta.endTime, {
          zone,
        })
      : null;
    const startTime = employmentMeta.startTime
      ? DateTime.fromISO(employmentMeta.startTime, {
          zone,
        })
      : null;

    // Not start/end time means its ongoing
    if (!endTime && !startTime) {
      return t("metadata.timeboxed.table.ongoing");
    }

    if (startTime) {
      const startDate = startTime.toFormat("dd MMM yyyy");

      const endDate =
        endTime == null
          ? t("metadata.timeboxed.table.ongoing")
          : endTime.toFormat("dd MMM yyyy");

      return `${startDate} - ${endDate}`;
    }

    // Only an endTime, eg. Until 12 May 2019
    return t("metadata.timeboxed.table.until", {
      date: endTime?.toFormat("dd MMM yyyy"),
    });
  };

  const getTimeboxedDisplayValue = (
    employmentMetadataItem: EmploymentMetadata,
  ) => {
    switch (metadataType.dataType) {
      case MetadataTypeDataTypeEnum.Boolean:
        return employmentMetadataItem.details ? (
          <FontAwesomeIcon color={theme.bluePrimary} icon={faCheck} />
        ) : (
          <FontAwesomeIcon color={theme.grey300} icon={faTimes} />
        );
      case MetadataTypeDataTypeEnum.Date:
        return formatDate(employmentMetadataItem.details, {
          toFormat: "dd MMM yyyy",
        });
      case MetadataTypeDataTypeEnum.Timestamp:
        return formatDate(employmentMetadataItem.details, {
          toFormat: "dd MMM yyyy hh:mm a z",
        });
      case MetadataTypeDataTypeEnum.Object:
        return JSON.stringify(employmentMetadataItem.details);
      case MetadataTypeDataTypeEnum.Number:
      case MetadataTypeDataTypeEnum.String:
      default:
        return employmentMetadataItem.details;
    }
  };

  const filteredData = (
    showHistory || isReadonly
      ? employmentMetadata
      : employmentMetadata.filter(
          (employmentMetadataItem: EmploymentMetadata) => {
            const status = MetadataUtility.getBadgeStatusForEmploymentMetadata(
              employmentMetadataItem,
            );
            return status.variant !== PillboxVariantEnum.Completed;
          },
        )
  ).sort((a: EmploymentMetadata, b: EmploymentMetadata) => {
    return +new Date(b.startTime) - +new Date(a.startTime);
  });

  return (
    <>
      {!isReadonly && (
        <Col>
          <Row className="align-baseline justify-content-between mb-2">
            <div className="d-flex align-items-center pl-0">
              <StyledMetadataTypeTableName
                className={metadataType.external ? "mr-1" : "mr-4"}
              >
                {tableName}
              </StyledMetadataTypeTableName>
              {metadataType.external && (
                <Pillbox
                  className="mr-4"
                  text={t("translation:pillbox.external_short")}
                  variant={PillboxVariantEnum.External}
                />
              )}
              <Checkbox
                fieldKey={`showHistory-${tableName}`}
                value={showHistory}
                onChange={onShowHistoryChanged}
                label={t("metadata.timeboxed.table.buttons.showHistory")}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                size="sm"
                onClick={() => {
                  if (!isReadonly && onAdd) {
                    onAdd({
                      metadataType,
                      employeeHomeStore,
                    });
                  }
                }}
              >
                {t("metadata.timeboxed.table.buttons.add")}
              </Button>
            </div>
          </Row>
        </Col>
      )}

      <StyledTable bordered hover={!isReadonly}>
        <thead>
          <tr>
            <th>{t("metadata.timeboxed.table.headers.effectiveDate")}</th>
            <th>{tableName}</th>
            <th>{t("metadata.timeboxed.table.headers.status")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((employmentMetadataItem) => (
            <tr
              onClick={() => {
                if (!isReadonly && onEdit) {
                  onEdit({
                    employmentMetadata: employmentMetadataItem,
                    metadataType,
                    employeeHomeStore,
                  });
                }
              }}
              key={employmentMetadataItem.id}
            >
              <td>{getEffectiveDateDescription(employmentMetadataItem)}</td>
              <td className="timeboxed-value">
                {getTimeboxedDisplayValue(employmentMetadataItem)}
              </td>
              <td>
                <Pillbox
                  {...MetadataUtility.getBadgeStatusForEmploymentMetadata(
                    employmentMetadataItem,
                  )}
                  saturated
                />
              </td>
            </tr>
          ))}
          {filteredData.length === 0 && (
            <tr key="no-data">
              <td colSpan={3} className="p-2">
                {t("metadata.timeboxed.table.noDataMessage")}
              </td>
            </tr>
          )}
        </tbody>
        {!isReadonly && metadataType.description && (
          <tfoot>
            <Form.Text className="text-muted">
              {metadataType.description}
            </Form.Text>
          </tfoot>
        )}
      </StyledTable>
    </>
  );
}
