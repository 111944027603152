import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import isFunction from "lodash/isFunction";
import UnlinkUserFromEmploymentMutation from "../mutations/UnlinkUserFromEmploymentMutation";
import { UnlinkUserFromEmploymentMutation$data as UnlinkUserFromEmploymentMutationResponse } from "../mutations/__generated__/UnlinkUserFromEmploymentMutation.graphql";
import { Id } from "../../../data/models/common";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import { User } from "./models";
import ServerError from "../../../utils/server-error";

type Props = {
  onOk?: (e: React.MouseEvent<HTMLButtonElement>, success: boolean) => void;
  businessId?: Id;
  linkedUser: User | null;
  hideModal: () => void;
};

function UnlinkUserModal({ onOk, businessId, linkedUser, hideModal }: Props) {
  const { t } = useTranslation();
  const businessContext = useBusinessContext();
  const [error, setError] = useState<string | null>(null);

  const onSubmitOk = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { environment, business } = businessContext;

    if (!linkedUser) {
      return;
    }

    UnlinkUserFromEmploymentMutation(
      environment,
      linkedUser.id,
      business.id as Id,
      (response: UnlinkUserFromEmploymentMutationResponse) => {
        const { unlinkUserFromEmployment } = response;
        if (unlinkUserFromEmployment) {
          toast(t("employment:linkUserModal.unlinkSuccess"));
          if (isFunction(onOk)) {
            onOk(e, unlinkUserFromEmployment);
          }
          hideModal();
        }
      },
      (err: Error) => {
        const { source } = (err as any) || {};
        if (!source) {
          return;
        }
        const serverError = new ServerError(source);
        setError(serverError.getErrorDetails());
      },
    );
  };

  if (!linkedUser) {
    return null;
  }

  return (
    <Modal show onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Unlink User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {linkedUser ? (
          <span>
            {t("employment:linkUserModal.unlinkFromUser", {
              email: linkedUser.email,
            })}
          </span>
        ) : null}
        {error ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {error}
          </Form.Control.Feedback>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          {t("form.actions.cancel")}
        </Button>
        <Button variant="primary" onClick={onSubmitOk}>
          {t("form.actions.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UnlinkUserModal;
