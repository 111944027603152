/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent, Suspense } from "react";
import {
  usePreloadedQuery,
  usePaginationFragment,
  PreloadedQuery,
} from "react-relay";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import LazyLoader from "../../common/LazyLoader";
import Loader from "../../common/Loader";
import {
  GetJobTitlesQuery,
  JobTitleFragment,
  JobTitleListItem,
} from "./JobTitlesQueries";
import { JobTitlesQueries_InternalQuery } from "./__generated__/JobTitlesQueries_InternalQuery.graphql";
import { JobTitlesQueries_ListFragment$key } from "./__generated__/JobTitlesQueries_ListFragment.graphql";
import { JobTitleTableQuery } from "./__generated__/JobTitleTableQuery.graphql";
import { formatCurrency, formatDate } from "../../../utils/utility";

type Props = {
  queryReference: PreloadedQuery<JobTitlesQueries_InternalQuery>;
};

const JobTitlesTable: FunctionComponent<Props> = ({ queryReference }) => {
  const { t } = useTranslation("job-titles");
  const theme = useTheme();

  const query = usePreloadedQuery(GetJobTitlesQuery, queryReference);

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    JobTitleTableQuery,
    JobTitlesQueries_ListFragment$key
  >(JobTitleFragment, query);

  // data mapping
  const jobTitles: JobTitleListItem[] = data.jobTitles.edges.map((x) => x.node);

  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("table.headers.code")}</th>
            <th>{t("table.headers.name")}</th>
            <th>{t("table.headers.description")}</th>
            <th className="text-center">{t("table.headers.isManager")}</th>
            <th className="text-center">{t("table.headers.salaried")}</th>
            <th>{t("table.headers.minPayRate")}</th>
            <th>{t("table.headers.maxPayRate")}</th>
            <th>{t("table.headers.level")}</th>
            <th className="text-center">{t("table.headers.deleted")}</th>
            <th>{t("table.headers.updatedAt")}</th>
            <th>{t("table.headers.createdAt")}</th>
          </tr>
        </thead>
        <tbody>
          {jobTitles.map((jobTitle, index) => {
            return (
              <Suspense
                fallback={
                  <tr>
                    <td colSpan={11}>
                      <Loader />
                    </td>
                  </tr>
                }
              >
                <tr key={jobTitle.id}>
                  <td>{jobTitle.code}</td>
                  <td>{jobTitle.name}</td>
                  <td>{jobTitle.description}</td>
                  <td className="text-center">
                    {jobTitle.isManager ? (
                      <FontAwesomeIcon
                        color={theme.bluePrimary}
                        icon={faCheck}
                      />
                    ) : (
                      <FontAwesomeIcon color={theme.grey300} icon={faTimes} />
                    )}
                  </td>
                  <td className="text-center">
                    {jobTitle.salaried ? (
                      <FontAwesomeIcon
                        color={theme.bluePrimary}
                        icon={faCheck}
                      />
                    ) : (
                      <FontAwesomeIcon color={theme.grey300} icon={faTimes} />
                    )}
                  </td>
                  <td>{formatCurrency(jobTitle.minPayRate)}</td>
                  <td>{formatCurrency(jobTitle.maxPayRate)}</td>
                  <td>{jobTitle.level}</td>
                  <td className="text-center">
                    {jobTitle.deleted ? (
                      <FontAwesomeIcon
                        color={theme.generic.red}
                        icon={faCheck}
                      />
                    ) : (
                      <FontAwesomeIcon color={theme.grey300} icon={faTimes} />
                    )}
                  </td>
                  <td>
                    {formatDate(jobTitle.updatedAt as string, {
                      toFormat: "dd MMM yyyy",
                      showRelative: true,
                    })}
                  </td>
                  <td>
                    {formatDate(jobTitle.createdAt as string, {
                      toFormat: "dd MMM yyyy",
                      showRelative: true,
                    })}
                  </td>
                </tr>
              </Suspense>
            );
          })}
        </tbody>
      </Table>
      <LazyLoader
        isLoading={() => isLoadingNext}
        hasMore={() => hasNext}
        loadMore={loadNext}
      />
    </>
  );
};

export default JobTitlesTable;
