/**
 * @generated SignedSource<<c4962b80551ce6e8316f8ec476701580>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MetadataTypeDataTypeEnum = "boolean" | "date" | "number" | "object" | "string" | "timestamp" | "%future added value";
export type MetadataTypeObjectClassEnum = "Employment" | "%future added value";
export type MetadataTypeCreateInput = {
  dataType: MetadataTypeDataTypeEnum;
  description?: string | null;
  displayName?: string | null;
  encrypted?: boolean | null;
  external?: boolean | null;
  internalAccess?: boolean | null;
  isTimeboxed?: boolean | null;
  maximum?: number | null;
  minimum?: number | null;
  name: string;
  obfuscatedNumber?: number | null;
  objectClass: MetadataTypeObjectClassEnum;
  required?: boolean | null;
  validValues?: any | null;
};
export type MetadataTypesQueries_CreateBusinessMetadataType_Mutation$variables = {
  businessId: string;
  input: MetadataTypeCreateInput;
};
export type MetadataTypesQueries_CreateBusinessMetadataType_Mutation$data = {
  readonly createMetadataType: {
    readonly dataType: MetadataTypeDataTypeEnum;
    readonly description: string | null;
    readonly displayName: string | null;
    readonly encrypted: boolean;
    readonly external: boolean;
    readonly id: string;
    readonly internalAccess: boolean;
    readonly isTimeboxed: boolean;
    readonly maximum: number | null;
    readonly minimum: number | null;
    readonly name: string;
    readonly obfuscatedNumber: number | null;
    readonly objectClass: MetadataTypeObjectClassEnum;
    readonly required: boolean;
    readonly validValues: any | null;
  };
};
export type MetadataTypesQueries_CreateBusinessMetadataType_Mutation = {
  response: MetadataTypesQueries_CreateBusinessMetadataType_Mutation$data;
  variables: MetadataTypesQueries_CreateBusinessMetadataType_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MetadataType",
    "kind": "LinkedField",
    "name": "createMetadataType",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "objectClass",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "required",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maximum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validValues",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isTimeboxed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "external",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "internalAccess",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "encrypted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "obfuscatedNumber",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetadataTypesQueries_CreateBusinessMetadataType_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetadataTypesQueries_CreateBusinessMetadataType_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "529b0349f9195662900ad9436584095e",
    "id": null,
    "metadata": {},
    "name": "MetadataTypesQueries_CreateBusinessMetadataType_Mutation",
    "operationKind": "mutation",
    "text": "mutation MetadataTypesQueries_CreateBusinessMetadataType_Mutation(\n  $businessId: ID!\n  $input: MetadataTypeCreateInput!\n) {\n  createMetadataType(businessId: $businessId, input: $input) {\n    id\n    name\n    displayName\n    objectClass\n    required\n    description\n    dataType\n    minimum\n    maximum\n    validValues\n    isTimeboxed\n    external\n    internalAccess\n    encrypted\n    obfuscatedNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "f560fd0bf02b4c2f17143dff3f000107";

export default node;
