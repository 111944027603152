import React, { useEffect, useState } from "react";
import { graphql, QueryRenderer } from "react-relay";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import Table from "./AccountTable";
import { idmInternalEnvironment } from "../../../../environment";
import HeaderPortal from "../../../common/Portal/HeaderPortal";
import Searchable from "../../../common/Searchable";
import Loader from "../../../common/Loader";
import { Accounts_InternalQuery } from "./__generated__/Accounts_InternalQuery.graphql";
import { BaseOption, Id, IStack } from "../../../../data/models/common";
import StackService from "../Stack/StackService";
import DynamicSelect from "../../../common/Form/DynamicSelect";

const AccountsQuery = graphql`
  query Accounts_InternalQuery(
    $email: String!
    $isLocked: Boolean
    $isConfirmed: Boolean
    $stackId: ID
    $pageSize: Int!
    $after: String
  ) {
    ...AccountTable_viewer
  }
`;

const defaultFilter = {
  searchValue: "",
  isLocked: false,
  isUnconfirmed: false,
  stackId: "",
};

const defaultStackOption = { label: "All", value: "" };

export default function Accounts() {
  const [stacksById, setStacksById] = useState<Map<string, IStack>>(new Map());
  const [stackOptions, setStackOptions] = useState<BaseOption<Id, any>[]>([]);
  const [searchValue, setSearchValue] = useState(defaultFilter.searchValue);
  const [isLocked, setIsLocked] = useState(defaultFilter.isLocked);
  const [isUnconfirmed, setIsUnconfirmed] = useState(
    defaultFilter.isUnconfirmed,
  );
  const [stackId, setStackId] = useState(defaultFilter.stackId);

  const { t } = useTranslation("accounts");

  useEffect(() => {
    // Initial load - fetch stacks
    async function fetchStacks() {
      const stacksByIdResult = await StackService.getAllStacks();
      const stacks = Array.from(stacksByIdResult?.values() || []).map((s) => {
        return { label: s.stackCode, value: s.id };
      });

      const stacksOptions: BaseOption<Id, any>[] = [
        defaultStackOption,
        ...stacks,
      ];

      setStackOptions(stacksOptions);
      setStacksById(stacksByIdResult ?? new Map());
    }

    fetchStacks();
  }, []);

  const resetFilters = () => {
    setSearchValue(defaultFilter.searchValue);
    setIsLocked(defaultFilter.isLocked);
    setIsUnconfirmed(defaultFilter.isUnconfirmed);
    setStackId(defaultFilter.stackId);
  };

  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  if (!stacksById) {
    return null;
  }

  return (
    <>
      <Row className="my-2">
        <Col>
          <Searchable
            className="Accounts"
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            placeholder={t("table.search")}
          />
        </Col>
        <Col md="auto">
          <Form inline>
            <Form.Group>
              <Form.Check
                className="mr-4"
                type="checkbox"
                id="isLocked"
                label={t("table.locked")}
                name="isLocked"
                checked={isLocked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { currentTarget } = e;
                  const { checked } = currentTarget;
                  setIsLocked(checked);
                }}
              />
            </Form.Group>
            <Form.Group className="border-right">
              <Form.Check
                className="mr-4 ml-2"
                type="checkbox"
                id="isUnconfirmed"
                label={t("table.unconfirmed")}
                name="isUnconfirmed"
                checked={isUnconfirmed}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { currentTarget } = e;
                  const { checked } = currentTarget;
                  setIsUnconfirmed(checked);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="ml-4 mr-2">{t("table.stack")}</Form.Label>
              <DynamicSelect<Id | null>
                options={stackOptions}
                defaultValue={defaultStackOption}
                value={stackId}
                name="employed-status"
                onChange={(newValue: Id | null | undefined) => {
                  setStackId(newValue ?? "");
                }}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Button
            variant="outline-primary"
            onClick={() => {
              resetFilters();
            }}
          >
            {t("table.clearFilters")}
          </Button>
        </Col>
        <Col md="auto">
          <LinkContainer to="/account/create">
            <Button disabled>{t("table.new")}</Button>
          </LinkContainer>
        </Col>
      </Row>

      <Card body>
        <QueryRenderer<Accounts_InternalQuery>
          environment={idmInternalEnvironment}
          query={AccountsQuery}
          variables={{
            email: searchValue,
            isLocked: isLocked || undefined,
            isConfirmed: isUnconfirmed ? false : undefined,
            stackId: stackId || undefined,
            pageSize: 50,
          }}
          render={({ error, props: queryProps }) => {
            if (error) {
              return <div>Error!</div>;
            }
            if (!queryProps) {
              return <Loader />;
            }
            return (
              <Table
                {...queryProps}
                searchValue={searchValue}
                isLocked={isLocked}
                isUnconfirmed={isUnconfirmed}
                stackId={stackId}
                viewer={queryProps}
                stacksById={stacksById}
              />
            );
          }}
        />
      </Card>
      <HeaderPortal>{t("layout.title")}</HeaderPortal>
    </>
  );
}
