import { useState, useEffect, useCallback } from "react";
import {
  graphql,
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import { EmploymentFeedbackReason } from "../../../../data/generated/stack_internal_schema";
import { useDebouncedValue } from "../../../../hooks/useDebouncedValue";
import { useScheduleBusinessContextCheck } from "../../../../hooks/useScheduleBusinessContextCheck";
import {
  FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery,
  EmploymentFeedbackTypeEnum,
} from "./__generated__/FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery.graphql";

export const EmploymentFeedbackReasonFragment = graphql`
  # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
  fragment FeedbackReasonsQueries_EmploymentFeedbackReasonFragment on EmploymentFeedbackReason {
    id
    feedbackType
    description
    defaultMessage
    deleted
  }
`;

export const GetEmploymentFeedbackReasonsQuery = graphql`
  query FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery(
    $businessId: ID!
    $feedbackType: [EmploymentFeedbackTypeEnum!]
    $includeDeleted: Boolean
  ) {
    employmentFeedbackReasons(
      businessId: $businessId
      feedbackType: $feedbackType
      includeDeleted: $includeDeleted
    ) {
      nodes {
        id
        feedbackType
        description
        defaultMessage
        deleted
      }
    }
  }
`;

export const UpdateEmploymentFeedbackReasonMutation = graphql`
  mutation FeedbackReasonsQueries_UpdateEmploymentFeedbackReasonMutation(
    $id: ID!
    $businessId: ID!
    $input: EmploymentFeedbackReasonUpdateInput!
  ) {
    updateEmploymentFeedbackReason(
      id: $id
      businessId: $businessId
      input: $input
    ) {
      description
      defaultMessage
      deleted
      feedbackType
      id
    }
  }
`;

export const ReactivateEmploymentFeedbackReasonMutation = graphql`
  mutation FeedbackReasonsQueries_ReactivateEmploymentFeedbackReasonMutation(
    $id: ID!
    $businessId: ID!
  ) {
    reactivateEmploymentFeedbackReason(id: $id, businessId: $businessId) {
      description
      defaultMessage
      deleted
      feedbackType
      id
    }
  }
`;

export const DeactivateEmploymentFeedbackReasonMutation = graphql`
  mutation FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutation(
    $id: ID!
    $businessId: ID!
  ) {
    deactivateEmploymentFeedbackReason(id: $id, businessId: $businessId) {
      description
      defaultMessage
      deleted
      feedbackType
      id
    }
  }
`;

export const CreateEmploymentFeedbackReasonMutation = graphql`
  mutation FeedbackReasonsQueries_CreateEmploymentFeedbackReasonMutation(
    $businessId: ID!
    $input: EmploymentFeedbackReasonCreateInput!
  ) {
    createEmploymentFeedbackReason(businessId: $businessId, input: $input) {
      id
      feedbackType
      description
      defaultMessage
      deleted
    }
  }
`;

export type EmploymentFeedbackReasonsQuery =
  PreloadedQuery<FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery>;

export function useFeedbackDataQuery(
  feedbackType: EmploymentFeedbackTypeEnum,
  defaultFilters: any,
) {
  const [searchValue, debouncedSearchValue, setSearchValue] = useDebouncedValue(
    defaultFilters.searchValue,
  );

  const [showDeactivated, setShowDeactivated] = useState(
    defaultFilters.showDeactivated,
  );

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery>(
      GetEmploymentFeedbackReasonsQuery,
    );

  const { businessId } = useScheduleBusinessContextCheck();

  const clearFilters = () => {
    setShowDeactivated(false);
    setSearchValue("", false);
  };

  const refreshQuery = useCallback(
    () =>
      loadQuery(
        {
          businessId: String(businessId),
          feedbackType: [feedbackType],
          includeDeleted: showDeactivated,
          // search: debouncedSearchValue,
        },
        {
          fetchPolicy: "network-only",
        },
      ),
    [businessId, feedbackType, showDeactivated, loadQuery],
  );

  useEffect(() => {
    refreshQuery();
    return () => {
      disposeQuery();
    };
  }, [
    businessId,
    feedbackType,
    showDeactivated,
    debouncedSearchValue,
    loadQuery,
    disposeQuery,
    refreshQuery,
  ]);

  const filters = {
    search: {
      value: searchValue,
      setValue: setSearchValue,
    },
    showDeactivated: {
      value: showDeactivated,
      setValue: setShowDeactivated,
    },
    filtersChanged: searchValue !== defaultFilters.searchValue,
    clearFilters,
    refreshQuery,
  };

  return [queryReference as EmploymentFeedbackReasonsQuery, filters] as const;
}

export function useFeedbackReasonsData(
  queryReference: EmploymentFeedbackReasonsQuery,
) {
  const query =
    usePreloadedQuery<FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery>(
      GetEmploymentFeedbackReasonsQuery,
      queryReference,
    );
  const data = query.employmentFeedbackReasons.nodes;
  return data as unknown as EmploymentFeedbackReason[];
}
