import React, { Suspense } from "react";
import { Route, RouteComponentProps, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { graphql, QueryRenderer } from "react-relay";
import { useTranslation } from "react-i18next";
import {
  BusinessContext,
  useBusinessContext,
} from "../../../contexts/BusinessContext";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import EmploymentProfile from "./EmploymentProfile";
import { EmploymentLayout_Query } from "./__generated__/EmploymentLayout_Query.graphql";
import EmploymentActionsDropdown from "./EmploymentActionsDropdown";
import EmploymentBadge from "./EmploymentBadge";
import Loader from "../../common/Loader";
import EmploymentMetadataLayout from "./EmploymentMetadata/EmploymentMetadataLayout";
import EmploymentSchedules from "./EmploymentSchedules";
import { useAppRouter } from "../../../hooks/useAppRouter";
import { ModalProvider } from "../../../contexts/ModalContext";

const query = graphql`
  query EmploymentLayout_Query($businessId: ID!, $employmentId: ID!) {
    employments(businessId: $businessId, ids: [$employmentId]) {
      edges {
        node {
          id
          firstName
          lastName
          userId
          acceptedInvite
          deleted
        }
      }
    }
  }
`;

interface MatchParams {
  business_id: string;
  employment_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams>;

function EmploymentLayout(properties: Props) {
  const { t } = useTranslation("employment");

  const businessContext = useBusinessContext();
  const {
    match,
    params: {
      stack_id: stackId,
      employment_id: employmentId,
      business_id: businessId,
    },
  } = useAppRouter<MatchParams>();

  return (
    <QueryRenderer<EmploymentLayout_Query>
      environment={businessContext.environment}
      query={query}
      variables={{
        businessId,
        employmentId,
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>Error!</div>;
        }
        if (!props) {
          return <Loader />;
        }

        const employment = (props as any).employments.edges[0].node;
        return (
          <ModalProvider>
            <header className="sub-header-container">
              <Nav variant="tabs">
                <LinkContainer to={`${match.url}`} exact>
                  <Nav.Link>{t("tabs.profile")}</Nav.Link>
                </LinkContainer>
                <LinkContainer to={`${match.url}/metadata`}>
                  <Nav.Link>{t("tabs.metadata")}</Nav.Link>
                </LinkContainer>
                <LinkContainer to={`${match.url}/schedules`}>
                  <Nav.Link>{t("tabs.schedules")}</Nav.Link>
                </LinkContainer>
                <LinkContainer to={`${match.url}/roles`}>
                  <Nav.Link disabled>{t("tabs.roles")}</Nav.Link>
                </LinkContainer>

                <EmploymentActionsDropdown
                  employment={employment}
                  environment={businessContext.environment}
                  {...properties}
                />
              </Nav>
            </header>
            <div className="mt-3">
              <Suspense fallback={<Loader />}>
                <Route
                  exact
                  path={`${match.path}`}
                  render={() => <EmploymentProfile {...properties} />}
                />
                <Route
                  path={`${match.path}/schedules`}
                  component={EmploymentSchedules}
                />
                <Route
                  path={`${match.path}/metadata`}
                  component={EmploymentMetadataLayout}
                />
              </Suspense>
            </div>
            <footer />
            <BusinessContext.Consumer>
              {({ business }) => {
                return (
                  <HeaderPortal>
                    <Link
                      to={`/stack/${stackId}/business/${business.id}/profile`}
                    >
                      <span>{business.businessName}</span>
                    </Link>
                    <span className="ml-2 mr-2">&gt;</span>
                    <Link
                      to={`/stack/${stackId}/business/${business.id}/profile/employees`}
                    >
                      <span>{t("breadcrumb")}</span>
                    </Link>
                    <span className="ml-2 mr-2">&gt;</span>
                    <span>
                      {employment?.firstName} {employment?.lastName}
                      <EmploymentBadge
                        employment={employment}
                        className="ml-1"
                      />
                    </span>
                  </HeaderPortal>
                );
              }}
            </BusinessContext.Consumer>
          </ModalProvider>
        );
      }}
    />
  );
}

export default EmploymentLayout;
