import React from "react";
import { FormikContextType, useFormikContext } from "formik";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import cloneDeep from "lodash/cloneDeep";
import { DynamicFormContext } from "../../../../contexts/DynamicFormContext";
import {
  IProperty,
  JSONStringListType,
  StringFieldType,
  timeclockPreshiftMessageTypeOptions,
} from "../../../common/Form/models";
import SelectionGroup from "../../../common/Form/SelectionGroup";
import { ScheduleProfileForm_schedule$data } from "./__generated__/ScheduleProfileForm_schedule.graphql";
import Field from "../../../common/Form/Field";
import { TimeClockAppPreShiftMessageTypeEnum } from "../../../../data/generated/stack_internal_schema";
import Textarea from "../../../common/Form/Textarea";

type Props = {
  properties: IProperty[];
};

const validationRules = yup.object({
  // For some reason the validation rules aren't working with nested objects
  // Need to investigate this, but fortunately the backend will catch these errors for now
});

// timeClockAppPreShiftFormattedMessages has a very complicated structure and isn't 'plug and play' like all of the other settings
export default function ScheduleTimeClockPreShiftMessages({
  properties,
}: Props) {
  const { t } = useTranslation();
  const formikContext = useFormikContext<ScheduleProfileForm_schedule$data>();

  const preShiftMessageType =
    formikContext?.values?.timeClockAppPreShiftFormattedMessages?.style;

  const onSecondaryButtonChanged = (val: string) => {
    // Avoid sending a blank string or null inside of this array
    if (val.trim() === "") {
      const helpers = formikContext.getFieldHelpers(
        "timeClockAppPreShiftFormattedMessages.configurable[0].buttons",
      );
      const buttons = formikContext.getFieldProps(
        "timeClockAppPreShiftFormattedMessages.configurable[0].buttons",
      );
      if (buttons?.value) {
        const temp = cloneDeep(buttons.value) || [];
        temp.pop();
        helpers.setValue(temp);
      }
    }
  };

  const componentRules = {
    "timeClockAppPreShiftFormattedMessages.style": {
      component: SelectionGroup,
      boldLabel: true,
      label: t("property.timeClockAppPreShiftFormattedMessages.style.value"),
      componentProps: {
        options: timeclockPreshiftMessageTypeOptions,
        isClearable: true,
        defaultValue: null,
        formCheckType: "radio",
        strictNullCheck: false,
      },
      onValueChanged: (
        newValue: TimeClockAppPreShiftMessageTypeEnum | null,
        formContext: FormikContextType<ScheduleProfileForm_schedule$data>,
      ) => {
        switch (newValue) {
          case TimeClockAppPreShiftMessageTypeEnum.Configurable:
            formContext.setFieldValue(
              "timeClockAppPreShiftFormattedMessages.predefined",
              null,
            );
            formContext.setFieldValue(
              "timeClockAppPreShiftFormattedMessages.configurable",
              [
                {
                  // Set based on the initial form value (in case the user accidentally clicks a different radio button)
                  ...formikContext?.initialValues
                    ?.timeClockAppPreShiftFormattedMessages?.configurable?.[0],

                  // Hardcoded values - in future this may be expanded upon, they were added by the backend for future proofing
                  version: 1,
                  code: "COVID",
                },
              ],
            );
            break;
          case TimeClockAppPreShiftMessageTypeEnum.Predefined:
            formContext.setFieldValue(
              "timeClockAppPreShiftFormattedMessages.configurable",
              null,
            );
            formContext.setFieldValue(
              "timeClockAppPreShiftFormattedMessages.predefined",
              // Set based on the initial form value (in case the user accidentally clicks a different radio button)
              formikContext?.initialValues
                ?.timeClockAppPreShiftFormattedMessages?.predefined ?? null,
            );

            break;
          default:
            // Workaround for backend - doesn't accept null for the properties if none is selected
            formContext.setFieldValue(
              "timeClockAppPreShiftFormattedMessages",
              {},
            );

            break;
        }
      },
    },
  };

  return (
    <DynamicFormContext.Provider
      value={{ propertyList: properties, componentRules, validationRules }}
    >
      <Row>
        <Field
          key="timeClockAppPreShiftFormattedMessages.style"
          fieldKey="timeClockAppPreShiftFormattedMessages.style"
          {...componentRules["timeClockAppPreShiftFormattedMessages.style"]}
        />
      </Row>

      {preShiftMessageType ===
        TimeClockAppPreShiftMessageTypeEnum.Configurable && (
        <>
          <Row>
            <Field
              fieldKey="timeClockAppPreShiftFormattedMessages.configurable[0].title"
              label={t(
                "property.timeClockAppPreShiftFormattedMessages.configurable.title.value",
              )}
              schemaFieldType={StringFieldType}
              lg={6}
              md={6}
            />
          </Row>
          <Row>
            <Field
              fieldKey="timeClockAppPreShiftFormattedMessages.configurable[0].message"
              label={t(
                "property.timeClockAppPreShiftFormattedMessages.configurable.message.value",
              )}
              componentProps={{
                xs: 12,
                md: 12,
                lg: 12,
                rows: 4,
              }}
              lg={6}
              md={6}
              component={Textarea}
              schemaFieldType={StringFieldType}
            />
          </Row>
          <Row>
            <Field
              fieldKey="timeClockAppPreShiftFormattedMessages.configurable[0].buttons[0]"
              label={t(
                "property.timeClockAppPreShiftFormattedMessages.configurable.primaryButton.value",
              )}
              schemaFieldType={StringFieldType}
            />
          </Row>
          <Row>
            <Field
              onValueChanged={onSecondaryButtonChanged}
              fieldKey="timeClockAppPreShiftFormattedMessages.configurable[0].buttons[1]"
              label={t(
                "property.timeClockAppPreShiftFormattedMessages.configurable.secondaryButton.value",
              )}
              description={t(
                "property.timeClockAppPreShiftFormattedMessages.configurable.secondaryButton.description",
              )}
              schemaFieldType={StringFieldType}
            />
          </Row>
        </>
      )}

      {preShiftMessageType ===
        TimeClockAppPreShiftMessageTypeEnum.Predefined && (
        <Row>
          <Field
            fieldKey="timeClockAppPreShiftFormattedMessages.predefined"
            schemaFieldType={JSONStringListType}
          />
        </Row>
      )}
    </DynamicFormContext.Provider>
  );
}
