import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { useModal } from "../../../../contexts/ModalContext";
import EditViewModal from "./Modals/EditViewModal/EditViewModal";
import EditGroupsModal from "./Modals/EditGroupModal/EditGroupModal";
import { useDynamicFieldsLayout } from "./MetadataLayoutQueries";
import HeaderPortal from "../../../common/Portal/HeaderPortal";
import {
  DynamicFieldsLayoutGroup,
  DynamicFieldsLayoutView,
} from "../../../../data/generated/stack_internal_schema";

export default function MetadataGroupsProfile() {
  const { t } = useTranslation("metadata-types");
  const { showModal, hideModal } = useModal();
  const [dynamicBusinessLayout, { getViewsForGroup }] =
    useDynamicFieldsLayout();

  const onNewGroupClick = () => {
    // Open modal
    showModal(
      <EditGroupsModal
        dynamicBusinessLayout={dynamicBusinessLayout}
        onClose={hideModal}
        onOk={hideModal}
      />,
    );
  };

  const onEditView = (
    e: React.MouseEvent<HTMLButtonElement>,
    view: DynamicFieldsLayoutView,
  ) => {
    e.stopPropagation();
    showModal(
      <EditViewModal
        view={view}
        dynamicBusinessLayout={dynamicBusinessLayout}
        onClose={hideModal}
        onOk={hideModal}
      />,
    );
  };

  const onEditGroup = (group: DynamicFieldsLayoutGroup) => {
    showModal(
      <EditGroupsModal
        dynamicBusinessLayout={dynamicBusinessLayout}
        group={group}
        onClose={hideModal}
        onOk={hideModal}
      />,
    );
  };

  return (
    <div>
      <HeaderPortal elementId="metadata-layout-action">
        <Button onClick={onNewGroupClick}>
          {t("metadataLayout.groups.newGroup")}
        </Button>
      </HeaderPortal>

      <Table responsive hover size="sm">
        <thead>
          <tr>
            <th>{t("metadataLayout.groups.table.headers.group")}</th>
            <th>{t("metadataLayout.groups.table.headers.displayName")}</th>
            <th>{t("metadataLayout.groups.table.headers.metadataFields")}</th>
            <th>{t("metadataLayout.groups.table.headers.appearsOn")}</th>
          </tr>
        </thead>
        <tbody>
          {dynamicBusinessLayout.groups.map((group) => (
            <tr
              key={group.name}
              style={{ cursor: "pointer" }}
              onClick={() => onEditGroup(group)}
            >
              <td>{group.name}</td>
              <td>{group.label}</td>
              <td>{group.fields.map((x) => x.metadataTypeName).join(", ")}</td>
              <td>
                <span className="d-flex flex-column align-items-start">
                  {getViewsForGroup(group).map(
                    (view: DynamicFieldsLayoutView) => (
                      <button
                        key={view.name}
                        onClick={(e) => onEditView(e, view)}
                        type="button"
                        className="btn btn-link"
                      >
                        {view.name}
                      </button>
                    ),
                  )}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
