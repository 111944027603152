import { commitMutation, graphql, Environment } from "react-relay";

const mutation = graphql`
  mutation UpdateScheduleMutation(
    $input: ScheduleUpdateInput!
    $id: ID!
    $businessId: ID!
  ) {
    updateSchedule(businessId: $businessId, id: $id, input: $input) {
      id
      timeZone
      ### Replaceable content start
      scheduleName
      scheduleStatus
      code
      settings
      locale
      hrGoLiveDate
      timekeepingGoLiveDate
      firstDayOfWeek
      dayStartTime
      dayEndTime
      dayPartTemplateId
      dayPartInheritedScheduleId
      analyticsEnabled
      snapshotStartDate
      snapshotDuration
      dataLakeId
      autoPublishEnabled
      autoPublishConfig
      ruleSetId
      complianceStartDate
      autoGfeEnabled
      autoGfeConfig
      payPeriodEnabled
      payPeriodRequiresApproval
      employeeMultiRateEnabled
      payrollCutoffTime
      payCycleStartDate
      payFrequency
      payDates
      clockTrackingEnabled
      clockInThreshold
      clockOutThreshold
      shiftUnpublishConsentEnabled
      shiftUnpublishConsentThreshold
      shiftShowUpThreshold
      shiftCanOverlapLeaveRequest
      shiftCanOverlapUnavailability
      shiftMultiRateEnabled
      shiftSwapEnabled
      shiftSwapNeedsApproval
      shiftDropEnabled
      showEarnings
      idleTimeoutEnabled
      idleTimeoutLength
      voluntaryLateClockOutEnabled
      usesBiometrics
      unplannedShiftStartThreshold
      punchSlipMode
      restScreenTheme
      timeClockAppWorkflow
      timeClockAppPreShiftFormattedMessages {
        style
        configurable {
          version
          code
          title
          message
          buttons
        }
        predefined
      }
      approvalNeededForShiftChange
      approvalNeededForUnplannedShift
      approvalNeededForEarlyBreakEnd
      approvalNeededForNonCompliantBreak
      approvalNeededForPinLogin
      approvalNeededForMinorViolations
      approvalNeededForRoleRateChange
      timeClockRoleRateChangeAuthThreshold
      timeClockAppRestScreenSettings {
        shiftApproachingLowerBound
        shiftDueUpperBound
        shiftOverdueUpperBound
        shiftLateUpperBound
        shiftEndApproachingLowerBound
        shiftEndDueUpperBound
        mealBreakApproachingLowerBound
        mealBreakDueUpperBound
        mealBreakOverdueUpperBound
        mealBreakEndApproachingLowerBound
        mealBreakEndDueUpperBound
        breakCompletedUpperBound
        onTimeThreshold
      }
      monthlyCostLoading
      populateScheduleConfig
      ### Replaceable content finish
    }
  }
`;

export default (
  environment: Environment,
  input: {},
  id: string,
  businessId: string,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    input,
    id,
    businessId,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
