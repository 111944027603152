import React from "react";
import { FormikContextType, useFormikContext } from "formik";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import isNumber from "lodash/isNumber";
import isUndefined from "lodash/isUndefined";

import findIndex from "lodash/findIndex";
import forIn from "lodash/forIn";
import styled from "styled-components";
import { isString } from "lodash";
import { useTranslation } from "react-i18next";
import Field from "../../../common/Form/Field";
import DaysOfWeekSelector from "../../../common/Form/DaysOfWeekSelector";
import WeekTable from "../../../common/Form/WeekTable";
import RemoveIcon from "../../../common/Form/RemoveIcon";
import { FloatFieldType } from "../../../common/Form/models";
import {
  BaseOption,
  daysOfWeekAbbreviated,
  DayOfWeek,
} from "../../../../data/models/common";
import {
  TimeRangeRanksConfigs,
  DaypartTime,
  WeeklyDayparts,
  Daypart,
  DaypartRank,
} from "./models";
import { stringToNumber } from "../../../../utils/utility";
import Range from "../../../common/Form/Range";
import DateTimePicker from "../../../common/Form/DateTimePicker";

type FormData = {
  aosConfig: TimeRangeRanksConfigs;
};

export type DaypartName = string;
export type Preference = number;
type DaypartPreference = Daypart & DaypartRank;
export type WeeklyDaypartObject = Record<DaypartName, DaypartTime>;
export type DaypartRanksObject = Record<
  string,
  Record<DaypartName, Preference>
>;

const WeeklyDaypartsDataKey = "aosConfig.weeklyDaypartsData";
const defaultPreference = 5;

export type DaypartRanksMap = Map<DayOfWeek, Map<Daypart, DaypartRank>>;

type Props = {
  disabled?: boolean;
};

const StyledRow = styled(Row)`
  .form-label {
    font-size: 12px;
    line-height: 15px;
  }

  .form-group {
    margin-bottom: 4px;
  }
`;

const StyledDaypartRow = styled(Row)`
  font-size: 12px;
`;

const StyleFormControl = styled(Form.Control)`
  max-width: 80px;
`;

const StyledAddTimeRangeButton = styled(Button)`
  width: auto;
`;

export const getWeeklyDaypartsArray = (
  weeklyDaypartObject: WeeklyDaypartObject,
  daypartRanksObject: DaypartRanksObject,
) => {
  const weeklyDayparts: WeeklyDayparts[] = [];
  const daypartRanks = getDaypartRanksMap(daypartRanksObject);

  if (weeklyDaypartObject) {
    forIn(weeklyDaypartObject, (value: DaypartTime, key: DaypartName) => {
      let ranks: DaypartRank[] = [];
      ranks = daypartRanks.get(key) || [];
      weeklyDayparts.push({
        daypart: { name: key, time: value },
        ranks,
      });
    });
  }
  return weeklyDayparts;
};

export const getDaypartRanksMap = (
  daypartRanksObject: DaypartRanksObject,
): Map<DaypartName, DaypartRank[]> => {
  const daypartRanks: Map<DaypartName, DaypartRank[]> = new Map<
    DaypartName,
    DaypartRank[]
  >();
  if (daypartRanksObject) {
    forIn(daypartRanksObject, (dayparts, dayOfWeek) => {
      forIn(dayparts, (preference, name) => {
        if (!daypartRanks.has(name)) {
          daypartRanks.set(name, []);
        }

        const ranks = daypartRanks.get(name);
        if (ranks) {
          ranks.push({
            dayOfWeek: dayOfWeek as DayOfWeek,
            preference,
          });
        }
      });
    });
  }
  return daypartRanks;
};

export const toFormData = (
  weeklyDayparts: WeeklyDaypartObject,
  daypartRanks: DaypartRanksObject,
): TimeRangeRanksConfigs => {
  // transform JSON data into workable arrays
  const weeklyDaypartsData =
    getWeeklyDaypartsArray(weeklyDayparts as any, daypartRanks as any) || [];

  const uniquePreferences = new Set<number>();
  forIn(daypartRanks, (daypart) => {
    forIn(daypart, (preference) => {
      uniquePreferences.add(preference);
    });
  });

  return {
    weeklyDaypartsData,
    globalPreference:
      uniquePreferences.size === 1
        ? uniquePreferences.values().next().value
        : defaultPreference,
    useGlobalPreference: uniquePreferences.size <= 1,
  };
};

export const fromFormData = (formValues?: TimeRangeRanksConfigs) => {
  if (!formValues) {
    return {};
  }

  const { weeklyDaypartsData, globalPreference, useGlobalPreference } =
    formValues;

  const weeklyDayparts = (weeklyDaypartsData || []).reduce(
    (result: WeeklyDaypartObject, w: WeeklyDayparts) => {
      const { daypart } = w;
      const { name, time } = daypart;
      result[name] = time;
      return result;
    },
    {},
  );

  const daypartRanks = (weeklyDaypartsData || []).reduce(
    (result: DaypartRanksObject, w: WeeklyDayparts) => {
      const { daypart, ranks } = w;
      const { name } = daypart;

      ranks.forEach((r: DaypartRank) => {
        const { dayOfWeek, preference } = r;
        if (!result[dayOfWeek]) {
          result[dayOfWeek] = {};
        }

        result[dayOfWeek][name] =
          useGlobalPreference && globalPreference != null
            ? globalPreference
            : preference;
      });

      return result;
    },
    {},
  );

  return {
    weeklyDayparts,
    daypartRanks,
  };
};

/** Unless upgrading/changing this component - keep as a class component */
export default function TimeRangeRanks({ disabled }: Props) {
  const { t } = useTranslation("aos");

  const formikContext: FormikContextType<FormData> = useFormikContext();
  const { values } = formikContext;
  const meta = formikContext.getFieldMeta(WeeklyDaypartsDataKey);

  const {
    aosConfig: { weeklyDaypartsData, globalPreference, useGlobalPreference },
  } = values;

  const daypartPreferences = weeklyDaypartsData.reduce(
    (r: DaypartPreference[], w: WeeklyDayparts) => {
      const { daypart, ranks } = w;
      ranks.forEach((item) => {
        r.push({
          name: daypart.name,
          time: daypart.time,
          dayOfWeek: item.dayOfWeek,
          preference: item.preference,
        });
      });

      return r;
    },
    [],
  );

  const daypartPreferencesByDayofWeek = groupBy(
    daypartPreferences,
    "dayOfWeek",
  );

  const addTimeRange = (
    oldValue: WeeklyDayparts[],
    formContext: FormikContextType<FormData>,
  ) => {
    const temp = cloneDeep(oldValue || []);
    temp.push({
      daypart: {
        name: `Day Part ${temp.length + 1}`,
        time: ["09:00", "17:00"],
      },
      ranks: [],
    });
    const helpers = formContext.getFieldHelpers(WeeklyDaypartsDataKey);
    helpers.setValue(temp);
  };

  return (
    <fieldset>
      <Form.Text className="text-muted mb-2">
        {t("basic.sections.daypart_ranks.help_text")}
      </Form.Text>
      <Row>
        <Col xs={12} md={12} lg={8}>
          <Table hover size="sm" className="mb-1">
            <thead>
              <tr>
                <th style={{ width: "160px" }}>
                  {t("basic.sections.daypart_ranks.name")}
                </th>
                <th style={{ width: "240px" }}>
                  {t("basic.sections.daypart_ranks.time")}
                </th>
                <th style={{ width: "320px" }}>
                  {t("basic.sections.daypart_ranks.days")}
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {(weeklyDaypartsData || []).map(
                (weeklyDaypart: WeeklyDayparts, weeklyDaypartIndex: number) => {
                  const { daypart, ranks } = weeklyDaypart;
                  const daypartFieldKey = `${WeeklyDaypartsDataKey}[${weeklyDaypartIndex}].daypart`;
                  const daypartNameFieldKey = `${daypartFieldKey}.name`;
                  const daypartTimeFieldKey = `${daypartFieldKey}.time`;
                  const daysOfWeek: DayOfWeek[] = ranks.map(
                    (item) => item.dayOfWeek,
                  );

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={`${weeklyDaypartIndex}`}>
                      <td>
                        <Form.Control
                          size="sm"
                          type="text"
                          value={daypart.name}
                          isInvalid={
                            formikContext.getFieldMeta(daypartNameFieldKey)
                              .error != null
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const newName = e.target.value;
                            formikContext.setFieldValue(
                              daypartNameFieldKey,
                              newName,
                            );
                          }}
                        />
                      </td>
                      <td>
                        <Row noGutters>
                          <Col md={5}>
                            <DateTimePicker
                              fieldKey="startTime"
                              value={daypart.time[0]}
                              onChange={(newValue: any) => {
                                formikContext.setFieldValue(
                                  `${daypartTimeFieldKey}[0]`,
                                  newValue,
                                );
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              valueFormat="HH:mm"
                              displayFormat="HH:mm"
                              timeFormat="HH:mm"
                              className="form-control-sm"
                            />
                          </Col>
                          <Col md="auto" className="ml-1 mr-1">
                            -
                          </Col>
                          <Col md={5}>
                            <DateTimePicker
                              fieldKey="endTime"
                              value={daypart.time[1]}
                              onChange={(newValue: any) => {
                                formikContext.setFieldValue(
                                  `${daypartTimeFieldKey}[1]`,
                                  newValue,
                                );
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              valueFormat="HH:mm"
                              displayFormat="HH:mm"
                              timeFormat="HH:mm"
                              className="form-control-sm"
                            />
                          </Col>
                        </Row>
                      </td>
                      <td>
                        <DaysOfWeekSelector<DayOfWeek>
                          options={daysOfWeekAbbreviated}
                          disabled={disabled}
                          value={daysOfWeek}
                          onChange={(selectedDays: DayOfWeek[]) => {
                            const newValue = selectedDays.map((selectedDay) => {
                              return {
                                dayOfWeek: selectedDay,
                                preference: globalPreference,
                              } as DaypartRank;
                            });

                            const temp = cloneDeep(weeklyDaypartsData || []);
                            temp[weeklyDaypartIndex].ranks = newValue;
                            formikContext.setFieldValue(
                              WeeklyDaypartsDataKey,
                              temp,
                            );
                          }}
                        />
                      </td>
                      <td>
                        <RemoveIcon
                          formikContext={formikContext}
                          fieldKey={WeeklyDaypartsDataKey}
                          index={weeklyDaypartIndex}
                        />
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <span className="text-danger">
                    {meta && isString(meta.error) ? meta.error : ""}
                  </span>
                  <StyledAddTimeRangeButton
                    size="sm"
                    block
                    variant="link"
                    onClick={() =>
                      addTimeRange(weeklyDaypartsData, formikContext)
                    }
                    disabled={disabled}
                  >
                    {t("basic.sections.daypart_ranks.add_new")}
                  </StyledAddTimeRangeButton>
                </td>
                <td colSpan={3}>&nbsp;</td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col md={4} lg={3}>
          <Form.Check
            type="checkbox"
            label={t("basic.sections.daypart_ranks.use_same_preference")}
            checked={useGlobalPreference}
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { currentTarget } = e;
              const { checked } = currentTarget;
              formikContext.setFieldValue(
                "aosConfig.useGlobalPreference",
                checked,
              );
            }}
          />
        </Col>
        <Col md={3} lg={3}>
          {useGlobalPreference ? (
            <Row>
              <Col md="auto">
                <StyleFormControl
                  type="number"
                  value={
                    !isUndefined(globalPreference) && isNumber(globalPreference)
                      ? globalPreference
                      : undefined
                  }
                  disabled={disabled}
                  min={1}
                  max={10}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { currentTarget } = e;
                    let value: any = stringToNumber(currentTarget.value);
                    if (value == null) {
                      value = undefined;
                    }

                    formikContext.setFieldValue(
                      "aosConfig.globalPreference",
                      value,
                    );
                  }}
                />
              </Col>
              <Col md="auto">
                <Range
                  fieldKey="aosConfig.globalPreference"
                  min={1}
                  max={10}
                  value={globalPreference}
                  onChange={(newValue: number | string | null) => {
                    formikContext.setFieldValue(
                      "aosConfig.globalPreference",
                      newValue,
                    );
                  }}
                  minLabel={t(
                    "basic.sections.daypart_ranks.preference_min_label",
                  )}
                  maxLabel={t(
                    "basic.sections.daypart_ranks.preference_max_label",
                  )}
                  disabled={disabled}
                />
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
      {!useGlobalPreference ? (
        <WeekTable className="mt-2">
          {(day: BaseOption<string, number>) => {
            const dayparts = daypartPreferencesByDayofWeek[day.value];

            return (dayparts || []).map((daypart: DaypartPreference) => {
              const { name, dayOfWeek, preference, time } = daypart;
              const weeklyDayPartIndex = findIndex(
                weeklyDaypartsData,
                (i) => i.daypart.name === name,
              );
              const rankIndex = findIndex(
                weeklyDaypartsData[weeklyDayPartIndex].ranks,
                (i) => i.dayOfWeek === dayOfWeek,
              );

              const key = `${WeeklyDaypartsDataKey}[${weeklyDayPartIndex}].ranks[${rankIndex}].preference`;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`row-${weeklyDayPartIndex}`}>
                  <StyledDaypartRow noGutters className="mb-1 hoverable">
                    <Col>
                      <div>{name}</div>
                      <span>
                        {time[0]} - {time[1]}
                      </span>
                    </Col>
                  </StyledDaypartRow>
                  <StyledRow noGutters>
                    <Col md={6} lg={6}>
                      <Form.Label htmlFor={key}>
                        {t("basic.sections.daypart_ranks.avoid_understaff")}
                      </Form.Label>
                    </Col>
                    <Field
                      size="sm"
                      md={6}
                      lg={6}
                      hideLabel
                      // label="Avoid Understaff"
                      horizontal
                      disabled={disabled}
                      fieldKey={key}
                      schemaFieldType={FloatFieldType}
                      min={1}
                      max={10}
                      value={preference}
                      className="no-gutters"
                    />
                  </StyledRow>

                  <Range
                    fieldKey={key}
                    min={1}
                    max={10}
                    value={preference}
                    onChange={(newValue) =>
                      formikContext.setFieldValue(key, newValue)
                    }
                    minLabel={t(
                      "basic.sections.daypart_ranks.preference_min_label",
                    )}
                    maxLabel={t(
                      "basic.sections.daypart_ranks.preference_max_label",
                    )}
                  />
                  <hr />
                </div>
              );
            });
          }}
        </WeekTable>
      ) : null}
    </fieldset>
  );
}
