import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
// import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";

import {
  CorporatesAuthMethodsQueries_GetAuthMethodsQuery$data,
  CorporatesAuthMethodsQueries_GetAuthMethodsQuery$variables,
} from "./__generated__/CorporatesAuthMethodsQueries_GetAuthMethodsQuery.graphql";
import { useCorporateAuthMethodLazyLoad } from "./CorporatesAuthMethodsQueries";
import HeaderPortal from "../../../../../common/Portal/HeaderPortal";
import Loader from "../../../../../common/Loader";
import { formatDate } from "../../../../../../utils/utility";

type EdgeType =
  {} & CorporatesAuthMethodsQueries_GetAuthMethodsQuery$data["ssoAuthMethods"]["edges"];
type Edge = {} & EdgeType[number];
export type SSOAuthMethod = Edge["node"];

function SSOAuthMethodsTable(props: {
  variables: CorporatesAuthMethodsQueries_GetAuthMethodsQuery$variables;
}) {
  const { variables } = props;
  const { t } = useTranslation("corporates-auth-methods");

  const [authMethods] = useCorporateAuthMethodLazyLoad(variables, true);

  return (
    <Table hover size="sm">
      <thead>
        <tr>
          <th>{t("table.headers.name")}</th>
          <th>{t("table.headers.code")}</th>
          <th>{t("table.headers.authType")}</th>
          <th>{t("table.headers.metadataSyncedAt")}</th>
          <th>{t("table.headers.lastUpdate")}</th>
        </tr>
      </thead>
      <tbody>
        {authMethods.map((item, index) => {
          if (item) {
            const {
              id,
              displayName,
              code,
              authType,
              metadataSyncedAt,
              updatedAt,
            } = item;
            return (
              <tr key={id} className="hoverable">
                <td>
                  <Link to={`auth_methods/edit/${id}`}>
                    {displayName ?? ""}
                  </Link>
                </td>
                <td>{code}</td>
                <td>{authType}</td>
                <td>
                  {formatDate(metadataSyncedAt as string, {
                    toFormat: "dd MMM yyyy",
                    showRelative: true,
                  })}
                </td>
                <td>
                  {formatDate(updatedAt as string, {
                    toFormat: "dd MMM yyyy",
                    showRelative: true,
                  })}
                </td>
              </tr>
            );
          }
          return null;
        })}
      </tbody>
    </Table>
  );
}

interface MatchParams {
  corporate_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

function CorporatesAuthMethods({ match }: Props) {
  const { t } = useTranslation("corporates-auth-methods");
  const { params } = match;
  const { corporate_id: corporateId } = params;
  const queryVariables = {
    corporateIds: corporateId ? [corporateId] : null,
  };

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("breadcrumb")}</span>
      </HeaderPortal>
      <Row className="mt-2 mb-2">
        <Col>
          {/* <Searchable
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            placeholder={t("stacksTable.searchPlaceholder")}
          /> */}
        </Col>
        <Col md="auto">
          <LinkContainer
            to={`/corporates/edit/${corporateId}/auth_methods/create`}
          >
            <Button>{t("table.new")}</Button>
          </LinkContainer>
        </Col>
      </Row>
      <Card body>
        <React.Suspense fallback={<Loader />}>
          <SSOAuthMethodsTable variables={queryVariables} />
        </React.Suspense>
      </Card>
    </>
  );
}

export default CorporatesAuthMethods;
