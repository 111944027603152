import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Corporate, useCorporatesLazyLoad } from "./CorporatesQueries";
import { CorporatesQueries_GetCorporates_InternalQuery$variables } from "./__generated__/CorporatesQueries_GetCorporates_InternalQuery.graphql";
import { Pillbox, PillboxVariantEnum } from "../../../common/Pillbox";

type Props = {
  searchFilters: CorporatesQueries_GetCorporates_InternalQuery$variables;
};

export default function CorporatesTable({ searchFilters }: Props) {
  const { t } = useTranslation("corporates");
  const location = useLocation();

  const [corporates] = useCorporatesLazyLoad(searchFilters, true);

  const getMappedCorporateStacks = (corporate: Corporate) => {
    const corporateStackList = corporate.corporateStacks.map<React.ReactNode>(
      (corporateStack) =>
        corporateStack?.stack && (
          <Link to={`/stack/${corporateStack.stack.id}/businesses`}>
            {corporateStack.stack.stackCode}
          </Link>
        ),
    );
    if (corporateStackList.length) {
      return corporateStackList.reduce((prev, curr) => [prev, ", ", curr]);
    }
    return null;
  };

  return (
    <Table hover size="sm">
      <thead>
        <tr>
          <th>{t("table.headers.name")}</th>
          <th>{t("table.headers.stacks")}</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {corporates.map((corporate) => (
          <tr key={corporate.id}>
            <td>
              {!corporate.deleted ? (
                <Link to={`${location.pathname}/edit/${corporate.id}`}>
                  {corporate.name}
                </Link>
              ) : (
                <span>{corporate.name}</span>
              )}
            </td>
            <td>{getMappedCorporateStacks(corporate)}</td>
            <td>
              {corporate.deleted ? (
                <Pillbox
                  variant={PillboxVariantEnum.Declined}
                  text={t("table.pills.deleted")}
                />
              ) : (
                " "
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
