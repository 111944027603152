import React, { FunctionComponent } from "react";
import {
  graphql,
  createPaginationContainer,
  RelayPaginationProp,
} from "react-relay";
import { withTranslation, WithTranslation } from "react-i18next";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import RelayLazyLoader from "../../common/RelayLazyLoader";
import {
  EmploymentStatus,
  getCurrentlyEmployedParam,
  getDeletedParam,
} from "../Employment/EmploymentTable";
import EmploymentBadge from "../Employment/EmploymentBadge";
import { toRelative } from "../../../utils/utility";
import { Id } from "../../../data/models/common";
import { StackPeopleTable_viewer$data } from "./__generated__/StackPeopleTable_viewer.graphql";

const StackPeopleTablePageQuery = graphql`
  query StackPeopleTable_InternalQuery(
    $email: String
    $userId: ID
    $deleted: Boolean
    $acceptedInvite: Boolean
    $currentlyEmployed: Boolean
    $unlinkedUser: Boolean
    $pageSize: Int!
    $after: String
  ) {
    ...StackPeopleTable_viewer
  }
`;

export enum SearchByEnum {
  Email,
  UserId,
}

type Props = WithTranslation & {
  email: string;
  stackId: Id;
  userId: Id;
  employmentStatus: EmploymentStatus;
  viewer: StackPeopleTable_viewer$data;
  searchBy: SearchByEnum;
  unlinked: boolean;
  relay: RelayPaginationProp;
};

const StackPeopleTableBase: FunctionComponent<Props> = (props: Props) => {
  const { relay, t, stackId, viewer } = props;

  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("table.headers.employmentEmail")}</th>
            <th>{t("table.headers.accountEmail")}</th>
            <th>{t("table.headers.businessName")}</th>
            <th>{t("table.headers.employmentStatus")}</th>
            <th>{t("table.headers.employmentDuration")}</th>
            <th>{t("table.headers.lastUpdate")}</th>
          </tr>
        </thead>
        <tbody>
          {(viewer.searchStackForEmployments.edges || []).map(
            (edge: any, index) => {
              const { node } = edge;
              const {
                email,
                userId,
                userEmail,
                during,
                updatedAt,
                business: { id: businessId, businessName },
              } = node;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={`${businessId}-${index}`}>
                  <td>{email}</td>
                  <td>
                    {userId ? (
                      <Link to={`/account/${userId}`}>{userEmail}</Link>
                    ) : (
                      "unlinked"
                    )}
                  </td>
                  <td>
                    <Link
                      to={`/stack/${stackId}/business/${businessId}/profile`}
                    >
                      {businessName}
                    </Link>
                  </td>
                  <td>
                    <EmploymentBadge employment={node} />
                  </td>
                  <td>{during}</td>
                  <td>
                    {toRelative(updatedAt as string, {
                      defaultValue: "-",
                    })}
                  </td>
                </tr>
              );
            },
          )}
        </tbody>
      </Table>
      <RelayLazyLoader relay={relay} />
    </>
  );
};

export default createPaginationContainer(
  withTranslation("stacks")(StackPeopleTableBase),
  {
    viewer: graphql`
      fragment StackPeopleTable_viewer on InternalQuery {
        searchStackForEmployments(
          email: $email
          userId: $userId
          deleted: $deleted
          acceptedInvite: $acceptedInvite
          currentlyEmployed: $currentlyEmployed
          unlinkedUser: $unlinkedUser
          first: $pageSize
          after: $after
        ) @connection(key: "StackPeopleTable_searchStackForEmployments") {
          edges {
            node {
              computedName
              email
              during
              acceptedInvite
              deleted
              userId
              userEmail
              business {
                id
                businessName
              }
              updatedAt
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: StackPeopleTablePageQuery,
    getConnectionFromProps: (props) => props.viewer.searchStackForEmployments,
    getFragmentVariables: (previousVars, pageSize) => ({
      ...previousVars,
      pageSize,
    }),
    getVariables: (props, paginationInfo) => ({
      email: props.searchBy === SearchByEnum.Email ? props.email : undefined,
      userId: props.searchBy === SearchByEnum.UserId ? props.userId : undefined,
      acceptedInvite:
        props.employmentStatus === EmploymentStatus.Invited ? false : undefined,
      deleted: getDeletedParam(props.employmentStatus),
      currentlyEmployed: getCurrentlyEmployedParam(props.employmentStatus),
      pageSize: paginationInfo.count,
      after: paginationInfo.cursor,
    }),
  },
);
